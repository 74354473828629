$black: #333333;
$gray: #E6E6E6;
$gray-hover: #e9e9ea;
$gray-click: #dedee4;
$light-gray: #8C93A4;
$medium-gray: #999999;
$dark-white: #C4C7CE;
$off-white: #FAFAFA;
$eggshell: #F5F5F5;
$white: #FFFFFF;

$super-light-orange: #FEF6F2;
$very-light-orange: #FEEFE7;
$light-orange: #F45F0C;
$lighter-orange: #FDEEE6;
$med-orange: #e15608;
$dark-orange: #c14905;

$light-blue: #E9F1F8;
$med-blue: #3C88d0;
$dark-blue: #2A67A0;

$panel-blue: #d6e8fa;
$navy-blue: #3D4861;
$dark-navy-blue: #2D364A;

$light-green: #E7F7E7;
$green: #13AE13;
$med-green: #def4e3;
$dark-green: #ceefd7;
$light-red: #FFEFEF;
$med-red: #FFDBDB;
$dark-red: #FFC6C6;
$red: #DE1B1B;

$nav-text: #B1B6C0;

$page-max-width: 1842px;
$mobile-min: 320px;
$mobile-max: 1000px;

@mixin text {
  font-family: 'Heebo', sans-serif;
  font-weight: 500;
  letter-spacing: 0;
}

@mixin border {
  border: 1px solid $off-white;
  border-radius: 4px;
  background: $white;
}

@mixin mobile {
  @media (max-device-width: $mobile-max) {
    @content;
  }
}

@mixin mobile450 {
  @media (max-device-width: 450px) {
    @content;
  }
}

@mixin mobile768 {
  @media (device-width: 768px) {
    @content;
  }
}

@mixin mobileMin768Max1000 {
  @media (min-device-width: 768px) and (max-device-width: 1000px) {
    @content;
  }
}

@mixin desktop {
  @media (min-device-width: $mobile-max + 1) {
    @content;
  }
}

#chat-widget-container {
}

.App {
  .loader {
    &.fullscreen {
      top: 50vh;
      margin-top: -.5em;
      margin-left: 50vw;
    }

    color: $light-orange;
    font-size: 20px;
    position: absolute;
    margin: auto auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  .cursor{
	cursor: pointer;
  }

  .loader-section{
	min-height: 70vh;
	/*padding-top: 50px;*/
	position: relative;
	display: flex;
  }
  @-webkit-keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  @keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  min-height: 100vh;
  font-family: 'Heebo', sans-serif;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  max-width: 100vw;
  width: 100%;
  overflow-y: hidden;
  justify-content: stretch;

  .off {
    display: none;
  }

  .orange-text {
    color: $light-orange;
  }

  .active-link {
    color: $light-orange !important;
  }

  .black-text {
    color: $black;
  }

  .gray-text {
    color: $light-gray;
  }

  .green-text {
    color: $green;
  }

  .white-text {
    color: $white;
  }

  .invisible {
    display: none;
  }

  .feed {
    align-items: flex-start;
  }

  .checkbox {
    cursor: pointer;

    &.disabled {
      cursor: default;
    }
  }

  .triple-checkbox {
    cursor: pointer;

    svg {
      margin-right: 16px;
    }
  }

  .radio {
    input {
      display: none;
    }
  }

  .new-role.page, .edit-role.page {

    .create-button {
      .button {
        width: 230px;
        margin: 40px 0 0 auto;
      }
    }
    .tab-bar {
      margin-top: 60px;
    }

    .search-container {
      width: 100%;
      align-items: flex-start;
      margin-bottom: 46px;

      .search-row {

        input {
          width: 500px;
        }

        .button {
          margin-left: 40px;
          height: 50px;
          width: 230px;
        }
      }
    }

    .edit-permissions {
      background: #F9F9F9;
      border-radius: 10px;
      /*border: 1px solid $gray;*/
      padding: 30px;
      align-items: flex-start;
      width: 100%;

      h2 {
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        border-bottom: 1px solid $gray;
        padding-bottom: 24px;
        width: 100%;
        margin-bottom: 0;
      }

      .permission {
        width: 100%;
        height: 95px;
        justify-content: space-between;
        border-bottom: 1px solid $gray;

        .left {
          align-items: flex-start;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .response-box {
    visibility: hidden;
    opacity: .0;
    left: 50%;
    margin-top: 16px;
    width: 700px;
    margin-left: -350px;
    position: fixed;
    align-items: flex-start;
    background: red;
    flex-grow: 1;
    padding: 16px;
    color: $red;
    background: $light-red;
    border: 1px solid $red;
    border-radius: 8px;
    z-index: 9999;
    transition: opacity .25s ease-in-out;

    &.on {
      opacity: .8;
      visibility: visible;
    }

    &.green {
      background: $light-green;
      color: $green;
      border: 1px solid $green;
    }
  }

  .notifications.page {
    .content {
      width: 100%;

      .tabs {
        margin-bottom: 40px;
      }

      .tab-content {
        width: 100%;
      }

      .notification {
        width: 100%;
        border: 1px solid $gray;
        border-radius: 8px;
        background: $white;
        padding: 24px;
        margin-bottom: 8px;
        cursor: pointer;

        .left {
          align-items: flex-start;
        }
      }
    }
  }

  .queue.page {
    .tabs {
      margin-bottom: 40px;
    }
  }

  .settings.page {

    .header {
      h1 {
        font-weight: bold;
        font-size: 34px;
        line-height: 56px;
        margin-right: 16px;
      }

      svg {
        cursor: pointer;
      }

      .button {
        margin: 0 0 0 12px;
        height: 48px;
        width: 240px;
      }
    }

    .content {
      margin-top: 68px;
      align-items: flex-start;

      .tab-content {
        width: 100%;
        align-items: flex-start;
        margin-top: 40px;

        &.org {
          .detail-row {
            margin-bottom: 40px;
            .detail-column {
              width: 270px;
              align-items: flex-start;
              text-align: left;
	      word-wrap: anywhere;	
	      overflow-wrap: anywhere;
	      word-break: break-all;
	      padding-right: 10px;
            }
          }

	  .detail-row {
            margin-bottom: 40px;
            .min-detail-column {
              min-width: 260px;
              align-items: flex-start;
              text-align: left;
	      word-wrap: anywhere;	
	      overflow-wrap: anywhere;
	      word-break: break-all;
	      padding-right: 10px;
            }
          }

          .org-status {
            color: $white;
            border-radius: 64px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px 16px;

            &.trial {
              background-color: $light-orange;
            }
            &.normal {
              background-color: $green;
            }
            &.invalid-payment {
              background-color: $red;
            }
          }

          .rename-row {
            justify-content: flex-start;
            align-items: flex-end;

            .rename-column {
              align-items: flex-start;
            }

            .button {
              width: 230px;
              margin-left: 30px;
              margin-bottom: 0;
              height: 48px;
            }
          }
        }

        &.roles {
          border-bottom: 1px solid $gray;
          padding: 24px 24px 0 24px;
          background-color: $white;
          border: 1px solid $gray;
          border-radius: 8px;

          .header {
            margin-bottom: 24px;
            font-size: 24px;
            line-height: 32px;
            width: 100%;
            justify-content: space-between;

            .add {
              font-size: 16px;
              line-height: 24px;
              cursor: pointer;

              svg {
                margin-left: 5px;
              }
            }
          }

          .role-list {
            align-items: flex-start;
            width: 100%;

            .role {
              width: 100%;
              justify-content: space-between;
              padding: 16px 0;
              border-bottom: 1px solid $gray;

              .role-name {
                align-items: flex-start;
              }
            }
          }

          .tools {
            justify-content: flex-end;
            height: 100%;

            svg {
              cursor: pointer;

              &:first-child {
                margin-right: 20px;
              }
            }
          }
        }

        &.billing {

          .manage-billing {
            width: 100%;
            justify-content: center;
            margin-top: 24px;

            .button {
              width: 240px;
            }
          }

          h1 {
            margin: 64px 0 40px 0;
          }

          .plan {
            background: white;
            border: 1px solid $gray;
            border-radius: 8px;
            width: 100%;
            align-items: flex-start;

            .top {
              width: 100%;
              padding: 24px;
              border-bottom: 1px solid $gray;
              justify-content: space-between;

              .frequency {
                font-weight: 700;

                .checkbox {
                  margin: 0 6px;
                }

                .green-text {
                  margin-left: 6px;
                }
              }

              h2 {
                font-size: 24px;
                margin-bottom: 0;
              }
            }

            .bottom {
              width: 100%;
              padding: 24px;
              padding-right: 125px;
              justify-content: space-between;
            }

            .detail {

              .copy {
                align-items: flex-start;
                margin-left: 13px;
              }

            }
          }

          .payments {
            margin-top: 40px;
            width: 100%;
          }

          .payment-method, .payment {
            width: 100%;
            justify-content: space-between;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            padding: 25px;
            background: $white;
            border: 1px solid $gray;
            border-radius: 8px;
            margin-bottom: 8px;

            .left {
              align-items: flex-start;
              width: 30%;

              .gray-text {
                margin-left: 12px;
              }
            }

            .middle {
              justify-content: space-between;
            }

            .brand {
              margin: 0 16px 0 25px;
            }

            svg {
              cursor: pointer;
            }

            .amounts {
              align-items: flex-end;

              .captured {
                color: $red;
                margin-left: 38px;
              }
              .refunded {
                color: $green;
                margin-left: 12px;
              }
            }
          }

          .button {
            margin-left: 0;
            width: 240px;
            margin-bottom: 40px;
          }

          .stripe-row {
            width: 100%;
            margin-bottom: 64px;
            align-items: flex-start;
            position: relative;

            .response-box {
              top: 0;
            }

            form {
              align-items: stretch;
              justify-content: space-between;
              display: flex;
              box-sizing: border-box;
              flex-direction: row;
              width: 100%;

              .StripeElement {
                flex-grow: 1;
                border: 1px solid #D9D9D9;
                box-sizing: border-box;
                border-radius: 2px;
                margin-right: 40px;
                padding: 12px 16px;
              }

              button {
                width: 230px;
                outline: none;
                display: inline-block;
                border-radius: 4px;
                padding: 12px 24px;
                text-align: center;
                flex: none;
                border: none;
                font-size: 16px;
                cursor: pointer;
                user-select: none;
                margin: 0;

                background-color: $light-orange;
                color: $white;
                &:hover { background-color: $med-orange; }
                &:active { background-color: $dark-orange; }

                &.disabled {
                  color: $dark-white; 
                  background-color: $medium-gray;
                  cursor: not-allowed;
                }
              }
            }
          }
        }
      }
    }
  }

  .new-user.page {

    .tab-bar {
      margin-top: 60px;
    }

    .content {

      .invites {
        background: $white;
        border: 1px solid $gray;
        width: 100%;
        padding: 24px;
        align-items: flex-start;

        .invite {
          width: 100%;
          justify-content: space-between;
          margin-bottom: 24px;

          input[type=email] {
            flex-grow: 1;
          }

          select {
            width: 240px;
            margin-left: 24px;
          }

          svg {
            margin-left: 24px;
            cursor: pointer;
          }
        }

        .add {
          cursor: pointer;
        
          svg {
            margin-left: 6px;
          }
        }
      }

      .footer {
        width: 100%;
        align-items: flex-end;

        .button {
          width: 230px;
          margin: 40px 0 0 auto;
        }
      }
    }
  }

  .edit-user.page {
    h1 {
      margin: 60px 0 0 0;
      font-weight: bold;
      font-size: 40px;
      line-height: 56px;
    }

    .top {
      width: 100%;
      align-items: flex-start;
      justify-content: stretch;
      margin-bottom: 0px;

      .image-control {
        margin-top: 20px;
        .huge-user-image {
          cursor: pointer;
          height: 230px;
          width: 230px;
          content: '';
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 230px;
          margin-right: 24px;
          position: relative;

          &.non-upload {
            cursor: default;
          }
        }

        svg {
          cursor: pointer;
        }
      }
    }

  }

  .user.page, .me.page {
    .top-container {
      align-items: flex-start;

      .top {
        margin-top: 0;
      }

      .button {
        width: 160px;
      }
    }

    .top {
      width: 100%;
      align-items: flex-start;
      justify-content: stretch;
      margin-bottom: 0px;
      margin-top: 40px;

      .image-control {
        margin-top: 20px;
        .huge-user-image {
          cursor: pointer;
          height: 230px;
          width: 230px;
          content: '';
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 230px;
          margin-right: 24px;
          position: relative;

          &.non-upload {
            cursor: default;
          }
        }

        svg {
          cursor: pointer;
        }
      }
    }

    .security {
      align-items: flex-start;

      .password-change {
        align-items: flex-start;
        margin-top: 24px;

        input {
          width: 286px;
          margin-bottom: 40px;
        }

        .button {
          margin: 0 0 0 0;
          width: 320px;
          height: 48px;
        }
      }
    }

    .table-heading {
      justify-content: space-between;
      width: 100%;
    }
    .name {
      width: 50%;
      justify-content: space-between;

      .button {
        width: 160px;
      }
    }

    .content {
      align-items: flex-start;
      margin-top: 30px;

      .tabs {
        margin-bottom: 40px;
        user-select: none;
      }

      .tab-content {
        align-items: flex-start;
        width: 100%;

        .basics {
          width: 50%;
        }

        .basic {
          align-items: flex-start;
          width: 50%;

          .input-row {
            margin-bottom: 40px;
          }

          input {
            outline: none;
            border: 1px solid #C6C6C6;
            border-radius: 4px;
            padding: 0 12px;
            height: 48px;
            width: 340px;
          }

          .button {
            height: 48px;
            width: 180px;
          }

          &.two-factor-wrapper {

            .button {
              margin-left: 0;
              width: 240px;
            }
          }

          &.phone-wrapper {

            .disable {

              h1 {
                margin-top: 0;

              }
            }

            h1 {
              margin-bottom: 12px;
            }

            .input-wrapper {
              width: 100%;

              .react-tel-input {

                input {
                  outline: none;
                  border: 1px solid #C6C6C6;
                  border-radius: 4px;
                  padding: 0 64px;
                  height: 48px;
                }

                .flag-dropdown {
                  padding: 0 8px;
                }
              }
              
              .button {
                margin-top: 0;
                margin-bottom: 0;
                width: 240px;
                height: 50px;
              }
            }
          }
        }
      }
    }

    .edit-permissions {
      background: $white;
      border-radius: 8px;
      border: 1px solid $gray;
      padding: 24px 24px 0 24px;
      align-items: flex-start;
      width: 100%;

      h2 {
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        border-bottom: 1px solid $gray;
        padding-bottom: 24px;
        width: 100%;
        margin-bottom: 0;
        justify-content: space-between;

        .button {
          width: 140px;
        }
      }

      .permission {
        width: 100%;
        height: 95px;
        justify-content: space-between;
        border-bottom: 1px solid $gray;

        .left {
          align-items: flex-start;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .buttons {
      margin-top: 40px;
      margin-left: auto;

      .button {
        width: 230px;
      }
    }
    
    .edit-notification-settings {
      background: $white;
      border-radius: 8px;
      border: 1px solid $gray;
      padding: 24px 24px 0 24px;
      align-items: flex-start;
      width: 100%;

      h2 {
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        border-bottom: 1px solid $gray;
        padding-bottom: 24px;
        width: 100%;
        margin-bottom: 0;
        justify-content: space-between;
      }

      .notification-setting {
        width: 100%;
        height: 95px;
        justify-content: space-between;
        border-bottom: 1px solid $gray;

        .left {
          align-items: flex-start;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .me.page {
    .user-card {
      margin-top: 0;

      .me-buttons {
        margin-left: auto;

        .button {
          width: 160px;
        }
      }
    }

    .security {
      align-items: flex-start;

      &.two-factor {
        width: 400px;
        justify-content: space-between;
        align-items: stretch;

        .two-factor-heading {
          margin-bottom: 40px;
          font-weight: bold;
          font-size: 48px;
          line-height: 64px;
        }

        input {
          margin-top: 52px;
          width: 100%;
          max-width: 366px;
        }
        .button {
          width: 100%;
          margin-top: 40px;
        }
      }

      .password-change {
        align-items: flex-start;
        margin-top: 10px;

        h1 {
          margin-bottom: 12px;
        }

        div {
          align-items: flex-start;
        }

        input {
          width: 286px;
          margin-bottom: 40px;
        }

        .button {
          margin: 0 0 0 0;
          width: 320px;
          height: 48px;
        }
      }
    &.edit {

      .content {
        
        }

        h1 {
          margin: 60px 0 40px 0;
        }

        .info-container {
          width: 100%;
          align-items: flex-start;

          .top {
            width: 100%;
            align-items: flex-start;
            justify-content: stretch;
            margin-bottom: 66px;

            .image-control {
              .huge-user-image {
                cursor: pointer;
                height: 230px;
                width: 230px;
                content: '';
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 230px;
                margin-right: 24px;

                &.non-upload {
                  cursor: default;
                }
              }

              svg {
                cursor: pointer;
              }
            }

            .general-info {
              max-width: 680px;
              margin-left: 58px;
              flex-grow: 1;
              justify-content: stretch;

              .info-column {
                align-items: flex-start;
                flex-grow: 1;

                &:first-child {
                  margin-right: 40px;
                }
                
                input {
                  display: flex;
                  box-sizing: border-box;
                  width: 100%;
                  margin-bottom: 40px;
                }
              }
            }
          }

          .bottom {
            width: 100%;
            align-items: flex-start;

            h3 {
              font-size: 24px;
              line-height: 32px;
              font-weight: normal;
            }

            .bottom-buttons {
              margin-top: 52px;
              justify-content: flex-end;
              width: 100%;

              .button {
                margin: 0;
                width: 240px;
              }
            }
          }

          .edit-notification-settings {
            background: $white;
            border-radius: 8px;
            border: 1px solid $gray;
            padding: 24px 24px 0 24px;
            align-items: flex-start;
            width: 100%;

            h2 {
              font-weight: normal;
              font-size: 24px;
              line-height: 32px;
              border-bottom: 1px solid $gray;
              padding-bottom: 24px;
              width: 100%;
              margin-bottom: 0;
            }

            .notification-setting {
              width: 100%;
              height: 95px;
              justify-content: space-between;
              border-bottom: 1px solid $gray;

              .left {
                align-items: flex-start;
              }

              &:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }

  .user-list {
    align-items: stretch;
    border: 1px solid $gray;
    border-radius: 8px;
    padding: 16px 24px 0 24px;
    background-color: $white;

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      border-bottom: 1px solid $gray;
      padding-bottom: 23px;
    }

  }

  table {

    tr {
      th {
        border-bottom: 1px solid $gray;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $medium-gray;
        padding: 0px 0 19px 0;
      }
      td {
        padding: 0 0 16px 0;

        .invited, .active {
          width: 120px;
          height: 32px;
          justify-content: center;
          align-items: center;
          border-radius: 24px;
        }

        .invited {
          background-color: $light-blue;
        }

        .active {
          background-color: $light-green;
        }

        .delete-icon {
          margin-left: 20px;
        }

        svg {
          cursor: pointer;
        }
      }
    }
  }

  .users {

  }


  .back {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $medium-gray;
    cursor: pointer;
    width: min-content;

    svg {
      margin-right: 8px;
    }
  }
  .user-card {

    cursor: pointer;

    &.invite {
      cursor: unset;
    }

    .user-image {
      margin-right: 16px;
      height: 48px;
      width: 48px;
      min-width: 48px;
    }

    &.large {
      font-size: 40px;
      line-height: 56px;
      font-weight: bold;
    }
  }

  .feed-post, .queue-post, .draft-post {
    min-width: 680px;
    max-width: 680px;
    background-color: $white;
    margin-bottom: 16px;
    border: 1px solid $gray;
    border-radius: 8px;
    padding: 16px 16px 24px 16px;

    &.assignment {
      cursor: pointer;
    }

    .header {
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 1px solid $gray;
      
      img, svg {
        height: 48px;
        width: 48px;
        border-radius: 48px;
        margin-right: 16px;
      }

      .user-text {
        align-items: flex-start;
        height: 48px;
        justify-content: space-around;

        h3 {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .gray-text {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }

      .score {
        margin-left: auto;

        svg {
          height: 24px;
          width: 24px;
          margin-left: 8px;
        }
      }

      .networks {
        margin-left: auto;

        .network-icon {
          align-items: center;
          justify-content: center;

          svg {
            border-radius: unset;
            height: 32px;
            width: 32px;

          }
        }
      }
    }

    .post-content {
      padding-top: 16px;
      width: 100%;
      align-items: flex-start;
      flex: 1 1 auto;

      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        margin-bottom: 5px;
      }

      .post-image {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 16px;
        border-radius: 4px;
        width: 100%;
        height: 0;
        padding-top: 66.64%; /* (img-height / img-width * container-width) */
                    /* (853 / 1280 * 100) */
      }
    }

    .footer {
      padding-top: 15px;
      border-top: 1px solid $gray;
      width: 100%;
      margin-top: 15px;

      .share {
        margin-left: auto;
        
        svg {
          margin-left: 10px;
        }
      }
    }
  }

  .queue-post, .draft-post {
    .footer {
      justify-content: space-between;

      .button {
        margin: 0;
        width: 31%;
      }

      .delete-button {
        width: 31%;
        .button {
          width: 100%;
        }

        .modal {
          .button {
            width: unset;
          }
        }
      }
    }
  }

  .page {
    background-color: $off-white;
    padding: 40px;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
    width: -webkit-fill-available;
  }

  .tab-bar {
    flex: 1 1 auto;
    min-width: 100%;
    padding-bottom: 40px;
    max-height: 98px;
    justify-content: space-between;

    .heading {
      align-items: flex-start;
    }

    h1 {
      font-size: 40px;
      line-height: 56px;
      font-weight: bold;
      min-width: 184px;
    }

    .gray-text {
      align-items: flex-start;
      line-height: 1.8em;

      .tooltip {
        margin-left: 8px;
      }
    }

    svg {
      margin-left: 16px;
    }

    .tabs {
      justify-content: space-around;

      a {
        margin-right: 40px;

        &.active {
          color: $light-orange;
        }
      }
    }

    .button {
      margin-left: auto;
      width: 160px;
    }
  }

  .tabs{
      justify-content: flex-start;
      width: 100%;

    .tab {
      cursor: pointer;
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
      height: 55px;
      align-items: center;
      color: $medium-gray;
      padding-right: 20px;
      margin-right: 40px;
			justify-content: space-between;

      &.active {
        border-bottom: 4px solid $light-orange;
        color: $black;
      }

      .tab-number {
        margin-left: 8px;
        border-radius: 64px;
        background: $light-orange;
        color: $white;
        font-size: 16px;
        height: 24px;
        padding-left: 12px;
        padding-right: 12px;
        line-height: 24px;
      }
    }
  }

  .modal-background {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background-color: $black;
    opacity: .5;
  }

  .modal-container {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    flex: 1 1 auto;
    justify-content: center;
  }

  .modal {
    box-sizing: border-box;
    position: fixed;
    background-color: $white;
    z-index: 9999;
    flex: 1 1 auto;
    border: 1px solid $gray;
    border-radius: 8px;
    max-height: 100vh;
    min-width: 480px;
    min-height: 200px;
  }

  .modal-header {
    padding: 24px 24px 0 24px;
    flex: 1 1 auto;
    min-width: 100%;
    justify-content: center;
    line-height: normal;

    .close {
      position: absolute;
      right: 24px;
      margin-right: 0;
      cursor: pointer;
    }

    h1 {
      font-weight: 600 !important;
      font-size: 24px;
      line-height: 32px;
      margin: 0;
    }
  }

  .modal-spinner {
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .modal-content {
    width: 100%;
    align-items: flex-start;
    padding: 24px;

    div {
      margin-bottom: 40px;
    }

    button {
      width: 200px !important;
    }
  }

  .plans-picker {

    h1 {
      font-size: 24px;
      font-weigth: 600 !important;
    }

    .plans-row {
      min-width: 620px;
      justify-content: space-between;
    }

    .plan-column {
      height: 264px;
      border-radius: 8px;
      border: 1px solid $gray;
      cursor: pointer;

    }

    .text-column {
      align-items: flex-start;
      justify-content: center;
      flex: 1 1 auto;
      padding: 24px;
    }
  }

  .sidebar {
    /*height: 100%;*/
    height: 100vh;
    width: 320px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    align-items: flex-start;
    justify-content: space-between;
    background-color: $white;
    border-right: 1px solid $gray;

    .scroll_view{
	overflow-y: auto;
	width: 100%;
	overflow-x: hidden;
	height: 76vh;
    }

    .accordian { 
      max-height: 64px;
      overflow: hidden;
      user-select: none;
      align-items: flex-start;


      &.active {
        max-height: 600px;
      }

      .accordian-links {
        align-items: flex-start;
        padding-top: 20px;
        padding-left: 96px;
        width: 100%;

        a:last-child {
          padding-bottom: 12px;
        }

        .queue-link {
          justify-content: space-between;
          margin-right: 40px;

          .queue-number {
            background: $light-orange;
            color: $white;
            padding: 0 10px;
            border-radius: 64px;
          }
        }
      }

      a {
        padding-bottom: 32px;
        width: 100%;
      }

      .accordian-button {
        width: 320px;
        min-height: 64px;
        justify-content: space-between;
        padding: 0 40px;
        cursor: pointer;
        position: relative;

        .end {
          position: absolute;
          left: 0;
          top: 0;
        }

        &.active {
          background-color: $super-light-orange;
          color: $light-orange;
          height: unset;
        }

        .left {
          font-size: 16px;
          z-index: 2;

          svg {
            margin-right: 18px;
          }
        }
      }
    }

    .top {
      align-items: flex-start;

      .logo {
        width: 186px;
        padding-bottom: 45px;
        padding-top: 45px;
        padding-left: 40px;
      }
    }

    .bottom {
      align-items: flex-start;
      padding: 40px;

      & > * {
        padding-bottom: 40px;
        
        svg, img {
          margin-right: 16px;
        }
      }
    }
  }

  .main {
    margin-left: 320px;
    min-height: 100vh;
    display: flex;
    flex: 1 1 auto;
    justify-content: stretch;
    align-items: stretch;
    width: auto;
    max-width: -webkit-fill-available;
    flex-direction: column;

    &.onboarding {
      margin-left: 0;
    }
  }

  .notification-banner {
    background: $light-orange;
    height: 120px;
    border-radius: 0 0 64px 0;
    color: $white;
    font-size: 24px;
    padding: 40px;
    justify-content: space-between;

    .dots {
      padding-left: 10%;
    }

    .button {
      width: 160px;
    }
  }

  .topbar {
    height: 80px;
    padding: 0 80px;
    justify-content: space-between;
    position: relative;

    &.off {
      display: none;
    }

    .left {
      width: 33%;
    }
    .middle {
      width: 33%;
      justify-content: space-around;
    }
    .right {
      width: 33%;
      justify-content: flex-end;

      .button {
        font-size: 22px;
      }

      a {
        font-size: 22px;
        font-weight: 600;
      }
    }

    &.mobile {
      max-height: 80px;
      overflow: hidden;
      padding: 0 10%;
      justify-content: flex-start;

      .top {
        width: 100%;
        min-height: 80px;
        justify-content: space-between;
      }

      .content {
        margin-top: 50%;
        align-items: center;
        width: 100%;

        .button {
          margin-left: 8px !important;
          margin-top: 10%;
          width: 100% !important;
        }
      }

      &.open {
        position: fixed !important;
        max-height: 100vh;
        height: 100vh;
        width: 100vw;
        z-index: 2;
        background: $white;
      }

    }
  }

  .bottombar {
    font-size: 16px;
    align-items: stretch;
    justify-content: space-between;
    background: $gray;
    padding: 40px 80px;

    &.off {
      display: none;
    }

    .left {
      align-items: flex-start;
      font-size: 16px;
      line-height: 24px;

      svg {
        margin-bottom: 18px;
      }

    }

    .legal-links {

      .legal-link {
        color: black;

        &:hover, &.active {
          color: $light-orange;
        }

        &:first-child {
          margin-right: 40px;
        }
      }
    }

    @include mobile {
      align-items: center;
      flex-direction: column;
      padding: 40px 10%;

      .legal-links {
        width: 100%;
        justify-content: space-around;

        a {
          margin: 0 !important;
        }
      }

      .left {
        align-items: center !important;
      }
    }
  }

  .black-link {
    color: $black;
    &:hover {
      color: $light-orange;
    }
  }
    
  .gray-link {
    color: $light-gray;
    &:hover {
      color: $light-orange;
    }
  }
    
  .mobile.page {
    background: $black;
    color: $white;
    font-weight: bold;
    align-items: center;

    svg {
      max-width: 100%;
    }

    .left {
      margin-top: 10%;
    }

    .couch {
      margin: 30px 0;
    }
  }

  .home-page {
    .wrapper {
      background: $white;
    }


    .last-button {
      padding: 48px;
      /* background-color: $med-orange;
      width: 90%;
      border-radius: 0px 64px 64px 0px; */

      .button {
        font-size: 22px !important;
        font-weight: 600;
      }

      h3 {
        color: white;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        margin-bottom: 40px;
      }

      .price {
        font-size: 40px;
        font-weight: 700;
        color: $white;
        margin-bottom: 40px;
      }
    }

    @include mobile {
      .last {
        text-align: left;
        border-radius: 0;
        margin-right: 0;
        padding: 40px;

        h3 {
          font-size: 5vw;
          line-height: 6vw;
          font-weight: 600;
        }
      }

    }
  }

  .hero-banner {
      background-image: url("./public/images/hero_bg.png");
      background-repeat: no-repeat;
      background-size: 84% 100%;
      min-height: 774px;
      width: 100%;
    
    .gray {
      /* background: $eggshell;
      border-radius: 0px 0px 0px 120px;     
      width: 100%; */ 
    }
    
    .hero-heading {
      flex: 50% 0 0;
      font-size: 46px;
      font-weight: 500;
      line-height: 50px;
      color: #FFF;
      padding-top: 50px;
    }
    
    .sub-hero-heading {
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      margin-top: 10px;
      color: #FFF;
    }

    .hero-price-sec{
      font-size: 18px;
      color: #FFF;
      margin-top: 15px;
    }

    .hero-price{
      font-size: 24px;
      color: #FFF;
    }

    .orange {
      color: $light-orange;
    }
    
    .banner-container {
      justify-content: space-around;
      width: 100%;
      flex: 100% 0 0;
      max-width: 100%;
      overflow: hidden;
      display: flex;
      padding: 0 0 0 80px;
    }
    
    .left-side {
      align-items: normal;
      flex: 50% 0 0;
    }
    
    .right-side {
      position: relative;
    }
    
    .hero-people {
      /*padding-left: 40px;*/
      padding-top: 30px;
    }
    
    .hero-dots {
      position: absolute;
      bottom: -29%;
      left: -31%;

      @include mobile {
        display: none;
      }
    }
    
    .video {
      padding: 24px;
      background: $white;
      border-radius: 16px;
      margin-top: 40px;
      max-width: 352px;
      cursor: pointer;
    }
    
    .play {
      margin-right: 15px;
    }
    
    .video-text {
      align-items: normal;
    }
    
    .video-header {
      font-size: 18px;
    }
    
    .video-subheader {
      font-size: 16px;
      color: $light-gray;
    }

    @include mobile768 {
      .hero-heading {
        font-size: 5vw !important;
        line-height: 6vw !important;
	padding-top: 20px;
      }
    }
    
    @include mobile {

      background-size: 100% 95%;

      .hero-banner {
        height: -webkit-fit-content;
      }
    
      .gray {
        border-radius: 0px 0px 0px 40px;
      }
    
      .banner-container {
        flex-direction: column;
        padding: 5%;
      }
    
      .hero-people {
        margin-top: 20px;
        margin-bottom: 24px;;
        padding: 0;
        position: relative;
        max-width: 100%;

        svg {
          max-width: 100%;
          max-height: 50vh;
        }
      }
    
      .hero-heading {
        font-size: 7vw;
        line-height: 8vw;
	padding-top: 20px;
      }

      .sub-hero-heading{
        color: #FFF;  
	line-height: 26px;
	padding-right: 20px;

      }

    
      .video {
        width: 100%;
        height: auto;
        border-radius: 16px;
        padding: 24px;
        margin-bottom: 48px;
      }
    
      .play {
        height: 13vw;
        width: auto;
        margin-right: 3vw;
      }
    
      .vido-text {
        //line-height
      }
    
      .video-header {
        font-size: 5.9vw;
      }
    
      .video-subheader {
        font-size: 5vw;
      }
    }
  }

  .benefits.soc-sec{
	padding: 20px 0px;
  }

  .benefits {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 0;
    align-items: center;

    .competitor-table {
      margin-bottom: 120px;
    }

    h2 {
      font-size: 48px !important;
      line-height: 64px;
      font-weight: 700;
    }
    
    .benefits {
      padding: 128px 80px;
      background-color: $white;
      align-items: flex-start;
    }
    
    .benefits-heading {
      margin-bottom: 0px;
      text-transform: capitalize;
    }
    
    .features-sec {
      margin: 30px -80px 0px -10px;
    }

    .features-svg {
      width: 100% important;
    }
    .enterprise-heading {
      margin-top: 152px;
      margin-bottom: 72px;
    }

    .benefits-bg{
	background-image: url("./public/images/benefits_bg.png");
        background-size: 100%;
        background-repeat: no-repeat;
    }
    
    .benefits-row {
      width: 100%;
      justify-content: space-around;
      margin-bottom: 40px;
      align-items: flex-start;
      min-height: 450px;
      padding-top: 50px;

      &.bottom {
        padding: 0 12% 0 12%;
      }

      .ben-col {
        justify-content: flex-start;
        height: 245px;
	background: #FFFFFF;
	border-radius: 10px;
	padding: 20px;
	margin: 10px;
	max-width: 270px;
	/*border: 1px solid #c8c8c8;*/
	box-shadow: 0px 0px 10px 4px #eee;
	-moz-box-shadow: 0px 0px 10px 4px #eee;
	-webkit-box-shadow: 0px 0px 10px 4px #eee;
 	-webkit-appearance: none;
     }
      .ben-image {
         height: 130px !important;
      }
      .ben-image img {
	    width: 120px;
	    height: 110px;
	}
    
      div {
        justify-content: space-between;
	align-items: normal !important;
      }
    }
    
    .ben-copy {
      text-align: left;
      padding-top: 0px;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    }
    
    .features-heading {
      padding-top: 128px;
    }
    
    .features-row {
      width: 100%;
      justify-content: space-between;
    }
    
    .card-container {
      height: 440px;
      justify-content: space-between;
    }
    
    .card {
      height: 200px;
      width: 290px;
      background: #FFFFFF;
      border: 1px solid $gray;
      border-radius: 16px;
      padding: 48px 30px;
    }
    
    .feature-copy {
      height: 48px;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      padding-top: 16px;
      color: $black;
      align-items: flex-start;
    }

    .socials-sec{
        background-image: url("./public/images/socials_bg.png");
	/*margin: 0px -80px 0px -80px;*/
	background-position-x: 100%;
	background-repeat: no-repeat;
	background-size: contain;
	padding-top: 100px;
	padding-bottom: 100px;

	.benefits-heading{
	  margin-top: 20px;
	}
    }
    
    .socials {
      margin-top: 0px;
      overflow: hidden;
      width: 100%;
      position: relative;
    
      .left-side {
        align-items: flex-start;
        justify-content: flex-start;
        width: 60%;
        z-index: 1;
      }
    
      .socials-heading {
        flex: 50% 0 0;
        font-weight: 500;
        font-size: 48px;
        line-height: 64px;
      }
    
      .socials-copy {
        margin: 40px 0;
        font-size: 1.3em;
        line-height: 1.3em;
      }
    
      .networks-banner {
        width: 100%;
        max-height: 107%;
      }
    
      .right-side {
        width: 40%;
        position: relative;
        justify-content: space-between;
	align-items: normal;
	padding: 20px;
	font-size: 20px;
      }

      .button {
        font-size: 22px;
      }

      @include mobile {
      .right-side {
        width: 100%;
	padding: 20px 0px;
      }
      }
    
    }

   @include mobileMin768Max1000 {

      .benefits-row{
        .ben-col{
	  width: 100%;
	  flex-direction: row;
	  height: auto !important;
	  align-items: center !important;
	  max-width: 100%;
	}

	.ben-copy{
	   max-width: 100%;
	   padding-left: 20px;
	}
	.ben-image {
	    width: 130px;
	}

      } 

   }
    
    @include mobile {
      padding: 20px 5%;

      .benefits-bg{
	background-image: url("./public/images/benefits_bg_mobile.png");
        background-size: 100%;
        background-repeat: no-repeat;
      }

      .benefits {
      }

      .features-sec {
         margin: 30px 0px 0px 0px;
       }
    
      .benefits-heading {
        font-size: 30px !important;
        font-weight: 500;
	margin-top: 0px !important;
	line-height: normal;
      }

      .heading-line-height{
	line-height: 45px;
      }
    
      .benefits-row {
        flex-direction: column;
        padding: 0 16px;
	align-items: center;
	min-height: 300px;

        &.bottom {
        }

	.ben-col{
	  width: 100%;
	  max-width: 100%;
	}
      }
      .features-row {
        flex-direction: column;
      }
    
      .ben-copy {
        /*
        font-size: 4vw !important;
        line-height: 6vw !important;
        width: 100%;
	*/
      }
    
      .ben-image {
        /*margin-top: 45px;*/
        height: 30vw;
	display: flex;
      }

      .ben-image img{
	margin: 0px auto;
      }
    
      .features-heading {
        padding-top: 56px;
        margin-bottom: 32px;
        font-size: 8vw !important;
        font-weight: 500;
      }
    
      .card-container {
        width: 100%;
        height: unset;
      }
    
      .card {
        width: 100%;
        margin-bottom: 16px;
        padding: 10% 20%;
        height: 43vw;
        
        img {
          height: 10vw;
        }
      }
    
      .feature-copy {
        font-size: 4vw !important;
        line-height: 6vw !important;
      }

      .socials-sec{
	background-size: cover;
	padding: 20px 5% 50px 5%;
	}
    
      .socials {
        margin-top: 0px !important;
        height: auto;
        flex-direction: column-reverse;

	.left-side {
	  align-items: center;
	}

        .socials-heading {
          font-size: 8vw !important;
          font-weight: 500;
          line-height: 12vw;
          margin-bottom: 32px;
        }
        .socials-copy {
	  font-size: 18px !important;
	  line-height: 26px !important;
	  margin: 10px 0px;
        }
        .left-side {
          width: 100%;
	  margin-top: -30px;
        }
    
        .mobile-container {
          width: 100%;
    
          .networks-banner {
            margin-bottom: 30px;
          }
        }

        svg {
          margin-bottom: -50px;
        }
      }

      svg {
        margin-top: 0 !important;
        max-width: 95vw;
        max-height: 50vh;
      }

      .button {
        width: 100% !important;
      }
    }
  }
  
  .side-by-side {

   .ensure{
	background-image: url("./public/images/side-by-side_bg.png");
	background-size: cover;
	background-repeat: no-repeat;
	margin-top: -50px;
    }

   .protect-brand{
	background-image: url("./public/images/protect-brand_bg.png");
	background-repeat: no-repeat;
	background-size: cover;
  	margin-top: 60px;
   }

	
    
    h2 {
      flex: 50% 0 0;
      font-size: 40px;
      font-weight: 500;
      line-height: 48px;
      text-transform: capitalize;
    }
    
    h3 {
      font-size: 24px;
      font-weight: 600;
    }
    
    small {
      font-size: 16px;
    }
    
    .gray {
      background: $eggshell;
      position: relative;
    }
    
    .orange {
      color: $light-orange;
    }
    
    .first {
      /*
      width: 100%;
      height: 785px;
      */
    
      .left {
        width: 42%;
        align-items: flex-start;
        padding-left: 80px;
	margin-top: 100px;
      }

      .right {
        /*height: 100%;*/
        width: 58%;
        position: relative;
        padding-right: 80px;
	padding-top: 10px;
      }

      .right1 {
        width: 40%;
        align-items: normal !important;
	margin-top: 100px;
      } 

      .left1 {
        /* height: 100%; */
        width: 60%;
        position: relative;
	margin-top: 20px;
	margin-left: 0px;

        .image {
	  padding-top: 220px;
	  padding-left: 1px;
        }

      }
    
      .image {
      /*
        position: absolute;
        left: 20px;
	top: 240px;
     */
	padding-top: 130px;
	padding-left: 0px;
      }

      .car {
        bottom: unset;
      }
    }
    
    .second {
      width: 100%;
      height: 600px;
      max-height: 600px;
    
      .white {
        border-radius: 120px 0px 0px 0px;
        background: $white;
        padding-left: 80px;
        width: 100%;
        height: 100%;
      }
    
      .left {
        width: 50%;
        position: relative;
        padding-left: 80px;
      }
    
      .right {
        align-items: flex-start;
        width: 50%;
        padding-right: 80px;
      }
    
      .image {
        position: absolute;
        bottom: -200px;
        right: 0px;
        margin-right: 20px; 
      }
    }

    .third {
      border-radius: 0px 120px 0px 0px;
      width: 100%;
      height: 464px;
      max-height: 464px;
    
      .left {
        width: 41%;
        align-items: flex-start;
        padding-left: 80px;
        font-size: 1.5em;
        line-height: 1.25em;
      }
    
      .right {
        height: 100%;
        width: 50%;
        position: relative;
        padding-right: 80px;
      }
    
      .image {
        position: absolute;
        bottom: 0;
        left: 0px;
        margin-left: 20px; 
      }

    }

    .last {
      height: 720px;
      max-height: 720px;
    }
    
    .plans {
      width: 100%;
      justify-content: center;
    
      img {
        margin-bottom: 24px;
      }
      
      small {
        margin-bottom: 24px;
      }
    }
    
    .plan-name {
      margin-bottom: 3px;
    }
    
    .or {
      margin: 24px 62px;
    }
    
    .dots {
      position: absolute;
      z-index: 1;

      @include mobile {
        display: none;
      }
    
      &.two {
        left: 80px;
        bottom: -67px;
      }
      &.three {
        right: 80px;
        bottom: -113px;
    }
  }

  .copy {
    font-size: 1.5em;
    line-height: 1.4em;
    text-transform: capitalize;
  }


    @include mobile450{
      .first{
        .left{
	  margin-top: 60px !important;
	}
      }
    }
   
    
    @include mobile {
      .ensure{	
	    background-image: url("./public/images/side-by-side-mobile_bg.png");
	    background-size: 100% 100%;
	    margin-top: -40px;
      }
      .protect-brand{
	background-image: url("./public/images/protect-brand-mobile_bg.png");
	background-size: 100% 100%;
	padding: 0px 5% 50px 5%;

	.first{
	  flex-direction: column-reverse;

	  .left1{
	    .image{
	      padding-top: 0px;
	    }
	  }

	  .right1{
	    margin-left: 0px;
	    width: 100% !important;
	    padding-left: 0px !important;
	    margin-top: 100px !important;
	  }
	}
      }
    
      h2 {
        font-size: 30px !important;
        font-weight: 500;
        line-height: normal;
	margin-bottom: 0px;	
      }
    
      .copy {
        font-size: 18px !important;
        line-height: 26px !important;
	padding: 30px 0px 20px 0px;
      }
    
      .first {
        flex-direction: column;
	
        .left {
          width: 100%;
	  align-items: center;
        }
    
        .right {
          width: 100%;
        }
    
        .image {
          position: relative !important;
          bottom: unset;
          left: unset;
          margin-right: 0 !important;
          margin-left: 0 !important;
	  padding-top: 0px;
        }
      }
    
      .second {
        height: auto;
    
        .white {
          padding: 48px 16px;
          border-radius: 40px 0px 0px 0px;
          flex-wrap: wrap;
        }
    
        .left {
          width: 100%;
        }
    
        .right {
          width: 100%;
        }
    
        .image {
          position: relative !important;
          bottom: unset;
          left: unset;
          margin-bottom: 26px;
          margin-right: 0 !important;
          margin-left: 0 !important;
        }
      }
    
      .plans {
        flex-direction: column;
      }
    }

    .container {
        background: $white;
        align-items: flex-start;
      }
      
    h1 {
        font-size: 48px;
        font-weight: 600;
        line-height: 64px;
        color: $black;
        text-align: left;
        margin: 0 80px;
        padding-top: 115px;
      }
      
    .orange {
        color: $light-orange;
      }
      
    #active-networks-list {
        padding-top: 43px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-flow: row wrap;
        margin: 0 10%;
      }
      
    li {
        border: 1px solid $eggshell;
        border-radius: 16px;
        padding: 40px;
        width: 452px;
        min-height: 168px;
        margin-bottom: 16px;
        background-color: $white;
        position: relative;
      }
      
    h4 {
        display: inline-block;
        vertical-align: middle;
      }
      
    h4 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        margin: 0 0 30px 0;
      }
      
    p {
        font-size: 16px;
        line-height: 24px;
      }
      
    .square {
        position: absolute;
        right: 0;
        top: 0;
        background: $eggshell;
        width: 112px;
        height: 96px;
        border-radius: 0 16px 0 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
    i {
        height: 48px !important;
        width: 48px !important;
      }
      
    @include mobile {
      .gray {
        flex-direction: column;
        height: auto;
        max-height: unset;
      }

      .left {
        padding: 0 !important;
        width: unset !important;
        margin: 18px;
      }

      .right {
        padding: 0 !important;
      }

      svg {
        max-width: 100%;
      }

      .container {
        padding: 16px;
      }
      
      h1 {
          margin: 0;
          font-size: 8vw !important;
          line-height: 12vw;
        }
      ul {
          width: 100%;
          margin: 0 !important;
        }
      
      h4 {
          max-width: 75%;
        }
      }
    }


.star {
  border-radius: 120px 0px 0px 0px;
  background-color: $black !important;
  max-width: 700px;
  position: relative;
  flex: 1 1 auto;
  min-height: 100vh;
  justify-content: flex-start;
  
  .wrapper {
    overflow: hidden;
    max-height: 100vh;
  }

  .logo {
    margin-top: 160px;
  }

  .big-star {
    max-width: 100%;
    position: absolute;
    bottom: 0;
    justify-content: stretch;
  }

  .dots {
    position: absolute;
    right: 195px;
    top: 93px;

      @include mobile {
        display: none;
      }
  }
}

.signup-page {
  align-items: stretch;
  justify-content: space-between;
  width: 100%;

  .wrapper {
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
  }

  input {
    box-sizing: border-box;
  }

  .login-container {
    justify-content: space-between;
    background: $white;
    min-width: 400px;
  }

  .sign-up {
    margin-bottom: 48px;
  }

  .top {
    width: 100%;
  }

  .heading {
    font-size: 48px;
    text-align: left;
    width: 100%;
    margin-bottom: 24px;
    font-weight: 600;
    line-height: auto;
  }

  .sign-up {
    font-size: 16px;
    width: 100%;
    text-align: left;
  }

  .input-container {
    width: 100%;
    align-items: flex-start;
  }

  .signinForm {
    width: 100%;
    align-items: flex-start;
  }

  .or {
    width: 100%;
    text-align: center;
  }

  .line {
    content: '';
    box-shadow: inset 0px -1px 0px 0px rgba(205, 210, 221, 0.65);
    width: 100%;
    height: 1px;
  }

  .spacer {
    margin: 1em 0;
    width: 100%;
  }

  .error-message {
    color: $red;
  }

  small {
    width: 100%;
    text-align: left;
  }


  .error-message {
    color: $red;
  }

  .boilerplate {
    small {
      text-align: center;
    }
  }

  .captcha {
    margin-top: 40px;

    &.error {
      border: 1px solid $red;
    }
  }

  @include mobile {

    .wrapper {
      height: 100vh;
    }

    .login-container {
      padding: 16px 24px;
      margin: 0;
      height: 100vh;
      justify-content: space-between;
      max-width: 100%;
      min-width: 100%;
    }

    .heading {
      font-size: 8vw !important;
      font-weight: 500;
      line-height: 12vw;
      margin-bottom: 16px;
    }

    .sign-up {
      font-size: 5vw !important;
      line-height: 6vw !important;
    }

    small {
      font-size: 5vw !important;
      line-height: 6vw !important;
    }

    .boilerplate {
      small {
        font-size: 3vw !important;
        line-height: 4vw !important;
      }
    }

  }
}


.firebaseui-card-content, #firebaseui-auth-container {
  margin: 20px 0;
  width: 400px;
  font-family: Helvetica, Arial, sans-serif;
  color: $black;

  h2 {
    font-size: 24px;
  }

  button {
    width: 400px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    outline: none;
    border-radius: 4px;
    font-family: Helvetica, Arial, sans-serif;
    color: $black;
    margin: 8px 0;
    text-align: center;
    border: 1px solid $gray;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    position: relative;

    .firebaseui-idp-icon-wrapper{
      position: absolute;
      left: 16px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        height: 24px;
      }
    }

    &.firebaseui-idp-password, &.firebaseui-idp-twitter, &.firebaseui-idp-facebook {
      border: none;
      color: $white;
    }

    &.firebaseui-idp-password {
      .firebaseui-idp-text-long {
        content: 'test';
      }
    }

    .firebaseui-idp-text-short {
      display: none;
    }

    &.mdl-button--colored {
      color: $white;
      background-color: $light-orange;
    }
  }

  .firebaseui-textfield {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .firebaseui-form-actions {
    display: flex;
    flex-direction: column-reverse;
  }
  
  .firebaseui-idp-list {
    display: flex;
    flex-direction: column;
  }

  .firebaseui-id-new-password-error{
    margin-top: 5px;
    color: red !important;
  }
}

.onboarding-page {
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  
  .wrapper {
    justify-content: center;
    align-items: center;
    max-width: 100%;
    flex: 1 1 auto;
    margin: 44px 80px;

    .content {
      max-width: 400px;
      width: 400px;

      .step {
        align-items: flex-start;

        h1 {
          font-size: 40px;
          line-height: 56px;
          margin-bottom: 44px;
        }

        .subheading {
          margin-bottom: 40px;
          font-size: 1.3em;
          line-height: 1.3em;
        }

        input {
          width: 300px;
          margin-bottom: 40px;
        }

        &.one {
          
          .button {
            margin: 0;
            width: 100%;
          }

          input {
            width: -webkit-fill-available;
          }
        }

        &.two {
          
          .invites {
            width: 100%;
            align-items: flex-start;

            .invite {
              width: 100%;
              align-items: center;
              margin-bottom: 24px;
              
              input {
                width: 300px;
                margin: 0 12px 0 0;
                width: -webkit-fill-available;
              }

              svg {
                cursor: pointer;
              }

              &:first-child {
                svg {
                  visibility: hidden;
                }
              }
            }

            .add {
              cursor: pointer;

              svg {
                margin-right: 8px;
              }
            }
          }

          .footer {
            width: 100%;
            justify-content: space-between;
            margin-top: 40px;

            button {
              margin: 0;
              width: 160px;
            }
          }
        }

        &.three {
          input {
            width: -webkit-fill-available;
          }

          h2 {
            font-size: 24px;
            line-height: 32px;
            font-weight: normal;
          }

          .new-team {

            .users-container {
              border: unset;
              padding: 0;
              margin: 0;

              .select:nth-child(2) {
                margin-right: 0;
              }

              .user {
                svg {
                  width: 55px;
                  height: 55px;
                }

                &.admin {
                  padding-right: 28px;

                  .select:first-child {
                    width: 100%;
                  }
                }
              }

            }

          }
        }

        &.four {
          width: 100%;

          .button {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
  }

  .explainer {
    align-items: flex-start;
    margin: 48px 24px 24px 24px;
    font-size: 14px;
  }
}

.login-page {
  align-items: stretch;
  justify-content: space-between;
  width: 100%;

  .wrapper {
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;

    .error {
      width: 400px;
    }

    .two-factor {
      width: 400px;
      justify-content: space-between;
      background: $white;
      align-items: stretch;

      .two-factor-heading {
        margin-bottom: 40px;
        font-weight: bold;
        font-size: 48px;
        line-height: 64px;
      }

      input {
        margin-top: 52px;
        width: 100%;
        max-width: 366px;
      }
      .button {
        width: 100%;
        margin-top: 40px;
      }
    }
  }

  .login-container {
    width: 400px;
    justify-content: space-between;
    background: $white;
    align-items: stretch;

    .inputs {
      width: 400px;

      input {
        box-sizing: border-box;
      }
    }

    .captcha {
      margin-bottom: 40px;

      &.error {
        border: 1px solid $red;
      }
    }

  }

  .top {
    width: 100%;
  }

  .heading {
    font-size: 48px;
    text-align: left;
    width: 100%;
    margin-bottom: 24px;
    font-weight: 600;
    line-height: auto;
  }

  .sign-up {
    font-size: 16px;
    width: 100%;
    text-align: left;
  }

  .input-container {
    width: 100%;
    align-items: flex-start;
  }

  .signinForm {
    width: 100%;
    align-items: flex-start;
  }

  small {
    width: 100%;
    text-align: left;
  }

  .or {
    width: 100%;
    text-align: center;
  }

  .line {
    content: '';
    box-shadow: inset 0px -1px 0px 0px rgba(205, 210, 221, 0.65);
    width: 100%;
    height: 1px;
  }

  .spacer {
    margin: 1em 0;
    width: 100%;
  }

  .error-message {
    color: $red;
  }

  .forgot-password {
    width: 100%;
    text-align: center;
  }

  @include mobile {

    .wrapper {
      height: 100vh;
    }

    .login-container {
      padding: 16px 24px;
      margin: 0;
      height: 100vh;
      justify-content: space-between;
      min-width: 100%;
    }

    .heading {
      font-size: 8vw !important;
      font-weight: 500;
      line-height: 12vw;
      margin-bottom: 16px;
    }

    .sign-up {
      font-size: 5vw !important;
      line-height: 6vw !important;
    }

    small {
      font-size: 5vw !important;
      line-height: 6vw !important;
    }

  }
}

.pricing-page {
  position: relative;
  background: $white;
  align-items: center;

  .heading {
    font-size: 48px;
    padding: 80px;
  }

  .toggle-row {
    font-size: 24px;
    color: $light-gray;

    .black {
      color: $black;
    }

    .toggle {
      padding: 40px;
      cursor: pointer;
    }
  }

  .square {
    position: absolute;
    right: 0;
    top: 0;
  }

  small {
    margin-bottom: 48px;
  }

  h4 {
    color: $light-gray;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin: 0;
  }

  h5 {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 16px;
    width: 100%;
    text-align: left;
  }

  h3 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
    margin: 0;
  }

  .feature {
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
    padding: 24px 0 8px 0;
    box-shadow: inset 0px -1px 0px rgba(205, 210, 221, 0.64705);

    div {
      font-weight: 600;
    }
  }

  .last {
    box-shadow: unset;
  }

  .tables {
    margin-bottom: 80px;
    justify-content: space-between;

    h5 {
      font-size: 1.25em;
    }
  }

  .table {
    border: 1px solid $gray;
    border-radius: 16px;
    align-items: flex-start;
    position: relative;
    background-color: $white;
    padding: 40px 45px 40px 45px;
    width: 510px;
  }

  .table-footer {
    width: 100%;
    margin-top: 28px;
  }
    
  .starts {
    margin-top: auto;
  }

  .learn-more {
    color: $light-blue;
  }

  .buyers-choice {
    position: absolute;
    top: -17px;
    right: -17px;
  }

  .dots {
    position: absolute;
    right: 110px;
    top: 105px;

      @include mobile {
        display: none;
      }
  }

  @include mobile {
    .content {
      padding-left: 16px;
      padding-right: 16px;
    }

    .heading {
      font-size: 8vw;
      margin-bottom: 64px;
    }
    .toggle-row {
      flex-direction: column;
    }

    .tables {
      flex-direction: column;
      width: 100%;
      margin-bottom: 0;
    }

    .table {
      padding: 40px 16px;
      overflow: hidden;
      width: 100%;
      margin: 0;
      margin-bottom: 12px;
    }
    
    .heading {
      padding: 0 20px;
    }
    
    .sub-heading {
      padding: 0 20px;
    }

    .feature {
      width: -webkit-fill-available;
    }
    
  }
  }
  .about-page {
    .wrapper {
      padding-top: 80px;
      background: $white;
    }
      
    .heading-container {
      background: $eggshell;
      width: 100%;
      height: 400px;
      align-items: flex-start;
      padding: 80px;
      border-radius: 0px 0px 0px 120px;
      position: relative;
    }
      
    .dots {
      position: absolute;
      right: 80px;
      bottom: -172px;
      margin: 0;

      @include mobile {
        display: none;
      }
    }
      
    h1 {
      font-size: 48px;
      font-weight: 600;
      line-height: 64px;
      color: $black;
      text-align: left;
      margin-bottom: 40px;
    }
      
    .orange {
      color: $light-orange;
    }
      
    .cards {
      margin-bottom: 60px;
    }
      
    .card {
      height: 144px;
      width: 400px;
      background: $eggshell;
      border-radius: 16px;
      margin: 20px;
      justify-content: space-between;
    
      .card-copy {
        font-size: 16px;
        line-height: 24px;
        margin: 48px 20px;
      }
    }
      
    .right {
      margin-top: 80px;
    }
      
    img {
      margin: 16px 16px 16px 0;
    }
      
    @include mobile {
        .heading-container {
          height: auto;
          border-radius: 0px 0px 0px 40px;
          padding: 16px 16px 48px 16px;
        }
      
      h1 {
          font-size: 8vw !important;
          line-height: 12vw;
        }
      
      .bottom {
          max-width: 100vw;
        }
      
      .cards {
          padding: 16px;
          flex-direction: column;
          max-width: 100%;
        }
      
      .card-col {
          max-width: 100%;
        }
      
      .card {
          max-width: 100%;
        }
      
      .right {
          margin: 0;
        }
      }  
  }

.contact-page {
  .wrapper {
    padding-top: 150px;
    padding-bottom: 70px;
    position: relative;
    background: $white;
  }
  
  .container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 55px 68px 68px;
    width: 556px;
    background-color: $eggshell;
    font-weight: 600;
    border-radius: 12px 0px 12px 0;
    z-index: 1;
  }
  
  .heading {
    font-size: 30px;
    margin-bottom: 25px;
    font-weight: 400;
  }
  
  @include mobile {
    .wrapper {
      padding: 0;
    }
  
    .container {
      width: 100%;
      padding: 150px 16px 16px 16px;
      margin: 0;
      background: $white;
    }
  }
}

.privacy-policy {
  padding-left: 20%;
  padding-right: 20%;
  margin-bottom: 40px;
  
  h3 {
    font-size: 32px;
    line-height: 32px;
    color: $light-orange;
    margin-bottom: 32px;
  }

  @include mobile {
    padding: 0 10%;
  }
}

  .multi-button-wrapper {
    position: relative;
    user-select: none;

    .multi-button {
      max-height: 42px;
      margin: 0;
      width: 182px;
    }

    svg {
      background-color: $med-orange;
      padding: 9px;
      border-radius: 0 4px 4px 0;
      cursor: pointer;
      z-index: 2;

      &:hover { background-color: $dark-orange; }
    }

    .multi-button {
      border-radius: 4px 0 0 4px;
      z-index: 2;

      &.disabled {
        color: $dark-white; 
        background-color: $medium-gray;
        cursor: not-allowed;
      }

    }

    .options {
      display: none;
      position: absolute;
      right: 0px;
      width: 100%;
      bottom: 40px;
      z-index: 1;

      .option {
        border: 1px solid $gray;
        border-bottom: none;
        justify-content: center;
        height: 42px;
        width: 100%;
        cursor: pointer;
        background: $white;
      }

      .option:first-child {
        border-radius: 4px 4px 0 0;
      }

      .option:last-child {
      }

      &.open {
        display: block;
      }
    }
  }

  select {
    background-color: $white;
    border-radius: 4px;
    border: 1px solid #C6C6C6;
    padding: 13.5px 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  button.button {
    outline: none;
    display: inline-block;
    border-radius: 4px;
    padding: 12px 24px;
    margin: 8px;
    text-align: center;
    flex: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    user-select: none;


    &.medium {
      height: 40px;
      line-height: 30px;
    }
    &.small {
      height: 32px;
      line-height: 22px;
    }
    &.tiny {
      height: 24px;
      line-height: 16px;
      padding: 5px 12px;
    }

    &.orange {
      background-color: $light-orange;
      color: $white;
      &:hover { background-color: $med-orange; }
      &:active { background-color: $dark-orange; }
    }

    &.light-orange {
      background-color: $lighter-orange;
      color: $light-orange;
      &:link { color: $light-orange; }
      &:hover { color: $med-orange; }
      &:active { color: $dark-orange; }
    }

    &.white {
      background-color: $white;
      &:link { background-color: $white;}
      &:hover { background-color: $off-white; }
      &:active { background-color: $off-white; }
    }

    &.blue {
      &:link { background-color: $light-blue; }
      &:hover { background-color: $med-blue; }
      &:active { background-color: $dark-blue; }
    }

    &.green {
      background-color: $light-green;
      color: $green;
      &:link { background-color: $green; }
      &:hover { background-color: $med-green; }
      &:active { background-color: $dark-green; }
    }

    &.gray {
      background-color: $gray;
      color: $black;
      &:link { background-color: $light-gray; }
      &:hover { background-color: $gray-hover; }
      &:active { background-color: $gray-click; }
    }

    &.red {
      background-color: $light-red;
      color: $red;
      &:link { background-color: $red; }
      &:hover { background-color: $med-red; }
      &:active { background-color: $dark-red; }
    }

    &.button.white {
      color: $light-orange;
    }

    &.disabled {
      color: $dark-white; 
      background-color: $medium-gray;
      cursor: not-allowed;
    }
  }

  .button.inverted-orange {
    color: $light-orange;
    border: 2px solid $light-orange;

    &.medium { line-height: 26px; }
    &.small { line-height: 20px; }
    &.tiny { line-height: 12px; }

    &.disabled { 
      color: $dark-white;
      background-color: $white;
      border: 2px solid $dark-white;
    }

    &:hover:not(.disabled) {
      color: $white;
      background-color: $light-orange;
    }
    &:active:not(.disabled) {
      color: $white;
      background-color: $dark-orange;
      border: 2px solid $dark-orange;
    }
  }

  .button.inverted-blue {
    color: $med-blue;
    border: 2px solid $light-blue;

    &.medium { line-height: 26px; }
    &.small { line-height: 20px; }
    &.tiny { line-height: 12px; }

    &.disabled { 
      color: $dark-white;
      background-color: $white;
      border: 2px solid $dark-white;
    }

    &:hover:not(.disabled) {
      color: $white;
      background-color: $light-blue;
      border: 2px solid $light-blue;
    }
    &:active:not(.disabled) {
      color: $med-blue;
      background-color: $dark-blue;
      border: 2px solid $dark-blue;
    }
  }

  .button.plain {
    color: $black;
    border: 1px solid $off-white;
    &:link {
      background-color: $white;
      color: $black;
    }
  }

  .delete {
    cursor: pointer;
  }

  .profile {
    svg {
      margin-left: 6px;
    }
  }


  .network {
    .network-icon {
      margin-right: 16px;
    }
  }

	.profile.page {
		.profile-card {
			align-items: flex-start;

			h1 {
				margin-left: 16px;
			}
		}

    .profile-status {
      margin-top: 40px;
      border-radius: 4px;
      border: 1px solid $green;
      padding: 12px 16px;
      width: fit-content;


      &.normal {
        background: $light-green;
        color: $green;
      }

      &.broken {
        color: $red;
      }
    }

    .rename-row {
      align-items: flex-end;
      margin-top: 40px;
      margin-bottom: 40px;

      .rename-column {
        align-items: flex-start;
      }

      .button {
        height: 48px;
        width: 140px;
        margin: 0 0 0 16px;
      }
    }

    .work-item {
      cursor: pointer;
    }

	}

  .expanding-profile-list, .network {
    width: 100%;
    align-items: flex-start;
    border: 1px solid $gray;
    border-radius: 8px;
    margin-bottom: 8px;
    user-select: none;
    background-color: $white;

    &.team {
      .network-icon {
        margin-left: 16px;
      }
    }

    .user-wrapper {
      height: 78px;
      border-bottom: 1px solid $gray;
      width: 100%;

      .user-card {
        margin-left: 16px;
      }
    }

    .header {
      width: 100%;
      padding: 24px;
      border-bottom: 1px solid #f2f2f2;

      .network-icon {
        height: 48px;
        width: 48px;
        align-items: center;
        justify-content: center;
      }

      .name-column {
        align-items: flex-start;
      }

      .chevron {
        margin-left: auto;
        cursor: pointer;
      }
    }

    .profiles {
      padding: 0 24px 0 24px;
      width: 100%;

      div:last-child {
        border-bottom: none;
      }

      .profile-wrapper {
        height: 78px;
        border-bottom: 1px solid #f2f2f2;
        width: 100%;
        justify-content: space-between;

        .profile {
          margin-left: 18px;
        }
      }
    }
  }

  .network {
    .header {
      .network-icon {
        margin-left: 0;
      }

      .button {
        width: 140px;
        margin-right: 16px;
      }
    }

    .profiles {
      .profile-wrapper {

        .profile {
          margin-left: 0;
          cursor: pointer;
        }

        .delete {
          cursor: pointer;
        }
      }
    }
  }

  .post-auth.page {

     padding: 32px 20px 75px;

    .controls {
      margin-left: 0px;
      margin-bottom: 18px;

      input[type=search] {
        margin-left: 0px;
        width: 100%;
      }

      .select_all{
	min-width: 80px;
      }
    }

    .heading {
      margin-top: 0px;
      margin-bottom: 50px;

      svg {
        margin-right: 15px;
      }

      h1 {
        font-weight: bold;
        font-size: 40px;
        line-height: 56px;
	margin: 0px;
      }
    }

    .triple-checkbox {
    }

    .profile {
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      height: 80px;
      margin-bottom: 8px;
      background: $white;
      padding: 10px;
      border: 1px solid $gray;
      border-radius: 4px;

      .checkbox {

        svg {
          margin-right: 18px;
        }
      }
    }

    .footer {
      width: 100%;
      justify-content: flex-end;

      .button {
        width: 230px;
        margin-right: 0;

	.post_icon{
	   display: none;
	}
      }
    }
  }

  .new-team.page {
    align-items: flex-start;

    .tab-bar {
      margin-top: 60px;
    }

    h2 {
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
    }

    .content {
      width: 100%;
    }
  }

  .new-team.content {
    width: 100%;
    align-items: flex-start;

    .search-row {
      input {
        width: 320px;
        width: 320px;
      }
    }
    
    .users-container {
      align-items: flex-start;
      width: 100%;
      margin-top: 40px;
      background: $white;
      border: 1px solid $gray;
      border-radius: 8px;
      padding: 24px;
    }

    .user {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 24px;

      &.admin {
        padding-right: 15px;
      }

      svg {
        cursor: pointer;
      }

      .select {
        margin-right: 24px;
        width: -moz-available;
      }
    }

    .button {
      width: 230px;
      margin: 40px 0 0 auto;
    }

    .add-button {
      cursor: pointer;

      svg {
        margin-right: 6px;
      }
    }
  }

  .team.page {
    .tab-bar {
      margin-top: 60px;
      margin-bottom: 28px;
    }

    .profiles {
      .gray-text {
        margin-bottom: 8px;
      }
    }

    .content {
      align-items: flex-start;

      .tabs {
        margin-bottom: 40px;
      }
    }

    .tab-content {
      align-items: flex-start;
      width: 100%;
    }
  }

  .team.edit {
    flex-direction: column;

    .name {

      .change-team-name {
        width: 300px;
      }

      .button {
        marin: 0 0 0 8px;
        height: 48px;
      }
    }

    .user-list {
      margin-top: 24px;

      .select {
        margin-right: 20px;
      }
    }

    .add-button {
      cursor: pointer;
      margin: 20px 0;

      svg {
        margin-right: 8px;
      }
    }

    .add-users {
      border: 1px solid $gray;
      border-radius: 8px;
      background-color: $white;
      padding: 24px;
      align-items: flex-start;

      .user-container {
        width: 100%;
        align-items: flex-start;
        margin-bottom: 24px;

        .user {
          width: 100%;
          flex: 1 1 100%;

          .select {
            width: -moz-available;;
            margin-right: 24px;
          }
        }

        .add-button { 
          height: 24px;
          
          svg {
            margin-right: 8px;
          }
        }
      }

      .tools {
        width: 100%;

        .button {
          margin: 0;
          margin-left: auto;
          width: 230px;
        }
      }
    }
  }

  .teams-container {
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    .team {
      height: 196px;
      cursor: pointer;
      align-items: flex-start;
      width: 430px;
      border: 1px solid $gray;
      border-radius: 8px;
      background-color: $white;
      margin-bottom: 30px;
      margin-right: 30px;

      .heading {
        padding: 15px;
        border-bottom: 1px solid $gray;
        font-size: 24px;
        line-height: 32px;
        width: 100%;
      }

      h1{
	font-size: 20px;
      }

      .info {
        padding: 28px 24px;
      }
    }
  }

  .kissing-images {
    .kissing-image {
      margin-left: -8px;

      .user-image {
        border: 2px solid #FFFFFF;
        background: #FFFFFF;
        height: 40px;
        width: 40px;
        border-radius: 40px;
      }
    }

    &.small {
      .kissing-image {
        margin-left: -4px;

        .user-image {
          border: 1px solid #FFFFFF;
          height: 24px;
          width: 24px;
        }
      }
    }

    .kissing-image:first-child {
      margin-left: 0;
    }
  }

  .share-to {
    min-width: 620px;

    .tools {
      width: 100%;
      justify-content: space-between;
      padding-left: 24px;

      svg {
        margin-right: 16px;
      }

      .filter {
        width: 70%;
      }
    }

    h1 {
      font-weight: 100;
    }

    .content {
      width: 100%;
      padding: 23px 24px 24px 24px;
      align-items: flex-start;
      overflow: hidden;
    }

    input[type=search] {
      width: 100%;
      margin-bottom: 8px;
    }

    .lists {
      align-items: flex-start;
      width: 100%;
      margin-top: 24px;
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 100%;

      .share-to-error {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding-bottom: 40px;
      }

      .profiles {
        align-items: flex-start;
        width: 100%;
      }

      .users {
        padding: 0 24px 0 24px;
        align-items: flex-start;
        flex: 1 1 auto;
        border: 1px solid $gray;
        border-radius: 8px;
        max-width: 100%;
        width: 100%;

        .user {
          height: 78px;
          border-bottom: 1px solid $gray;
          width: 100%;

          .user-image {
            margin: 0 16px 0 16px;
          }
        }

        div:last-child {
          border-bottom: none;
        }

      }
    }

    .share-to-footer {
      width: 100%;
      justify-content: space-between;
      padding: 0 24px 24px 24px;

      .number-selected {
        margin-left: 8px;
        color: $white;
        background-color: $light-orange;
        padding: 0 16px;
        border-radius: 64px;
      }

      .button {
        margin: 0;
        width: 200px;
      }
    }
  }
/*
  .date-time-picker {
    position: absolute;
    background: $white;
    width: 376px;

    .modal-header {
      padding-bottom: 16px;
      font-weight: 100;
      font-size: 24px;
      line-height: 32px;
      flex: unset;
    }
*/

  .date-time-picker {
    position: absolute;
    background: $white;
    width: 376px;
    margin-bottom: 75px;

    .modal-header {
      padding-bottom: 16px;
      font-weight: 100;
      font-size: 24px;
      line-height: 32px;
      flex: unset;
    }

    .content {
      flex-grow: 2;

      .calendar {
        flex-grow: 2;

        button {
          background: none;
          border: none;
          cursor: pointer;
        }

        .react-calendar__navigation {
          height: 64px;
          background-color: #f2f2f2;
          padding: 0 32px 0 32px;

          .react-calendar__navigation__label {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            outline: none;
            color: $black;
          }

          .react-calendar__navigation__arrow {
            font-size: 32px;
            outline: none;
          }

          .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
            display: none;
          }
        }

        .react-calendar__month-view__weekdays {
          padding: 8px 24px 0 24px;

          .react-calendar__month-view__weekdays__weekday {
            padding: 0;
            max-width: unset;
            flex-basis: unset;
            display: flex;
            height: 40px;
            width: 40px;
            align-items: center;
            justify-content: center;
            border-radius: 4px;

            abbr {
              text-decoration: unset;
            }
          }
        }

        .react-calendar__month-view__days {
          padding: 8px 24px 16px 24px;

          .react-calendar__month-view__days__day {
            padding: 0;
            max-width: 40px;
            flex-basis: unset;
            display: flex;
            height: 40px;
            width: 40px;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            font-weight: 100;
            font-size: 16px;
            line-height: 24px;
            outline: none;
          }

          .react-calendar__tile--active {
            background-color: $very-light-orange;
            color: $light-orange;
          }
        }
        .react-calendar__month-view__days__day--neighboringMonth {
          color: $light-gray;
        }
      }

      .time-picker {
        width: 100%;
        padding: 0 24px 24px 24px;
        justify-content: space-between;

        .select {
          width: 88px;
          height: 48px;
        }

        .colon {
          margin: 0 10px;
        }

        .select:last-child {
          margin-left: 24px;
        }
      }

      .buttons {
        width: 100%;
        padding: 0 24px 24px 24px;
        justify-content: space-between;

        button {
          margin: 0;
          width: 152px;
        }
      }
    }
  }

  .post.page {
    h1 {
      margin: 60px 0 0 0;
      font-size: 40px;
      line-height: 56px;
      font-weight: bold;
    }

    .feed-post {
      margin-top: 44px;
    }

    .profiles-container {
      padding: 16px 24px 0 24px;
      align-items: flex-start;
      border: 1px solid $gray;
      border-radius: 8px;
      margin-bottom: 16px;
      background-color: $white;

      .profiles-list {
        background-color: $white;
        align-items: flex-start;
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;

        tr {
          border-bottom: 1px solid $gray;
          &:last-child {
            border-bottom: unset;

            td {
              border-bottom: unset !important;

            }
          }

          th {
            color: #6F778D;
            font-weight: normal;
            border-bottom: unset;
          }

          td {
            border-bottom: 1px solid $gray !important;

            svg {
              margin-right: 16px;
            }

            .status-row {
              .status {
                padding: 8px 33px;
                margin-right: 20px;
                border-radius: 4px;
                justify-content: center;
                width: 180px;

                &.success {
                  background: $light-green;
                }

                &.bounced {
                  background: $dark-red;
                  color: $red;
                }

                &.scheduled {
                  background: $light-blue;
                  color: $dark-blue;
                }
              }

              svg {
                cursor: auto;
              }
            }
          }

          .profile {
            cursor: pointer;
          }

          .tools {
            justify-content: flex-end;
            height: 80px;

            svg {
              cursor: pointer;

              &:first-child {
                margin-right: 20px;
              }
            }
          }
        }
      }
    }
  }


  .post-preview {
    user-select: none;
    background-color: $white;
    padding: 40px 40px 0 40px;
    min-height: 100%;
    min-width: 50%;
    flex: 1 1 auto;
    align-items: flex-start;
    border-left: 1px solid $gray;
    max-height: 100%;

    h2 {
      margin: 0;
      user-select: none;
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
    }

    .preview-wrapper {
      max-height: 100%;
      background-color: $white;
      width: 100%;
    }

    .preview-container {
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .header {
      width: 100%;


      .desktop-mobile {
        margin-left: auto;

        svg {
          cursor: pointer;
        }

        svg:last-child {
          margin-left: 16px;
        }
      }
    }

    .navigation {
      width: 100%;
      justify-content: space-between;
      margin: 24px 0 40px 0;

      .nav-button {
        cursor: pointer;
      }

      .network-name {
        font-weight: 100;
        font-size: 16px;
        line-height: 24px;
        user-select: none;

        svg {
          height: 24px;
          width: 24px;
          margin-right: 8px;
        }
      }
    }
  }

  .preview {
    width: 100%;
    max-width: 500px;
    min-width: 500px;
    align-items: stretch;

    &.post_images {
      font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro",メイリオ,Meiryo,"ＭＳ Ｐゴシック",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      max-width: 508px;

        .uploaded-images {
          height: 500px;
          width: 100%;
          height: 300px;
          max-width: 100%;
          margin-top: -1px;
          overflow: hidden;
          position: relative;
          border-radius: 16px;
          border: 1px solid #c4cfd6;
          margin-top: 10px;

          .uploaded-image {
            object-fit: contain;
            max-width: unset;
            max-height: 100%;
            height: 100%;
            width: 100%;
            content: "";
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }

          .uploaded-image:nth-child(2) {
            margin-left: 2px;
          }
        }

    }


    &.pinterest {
      font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro",メイリオ,Meiryo,"ＭＳ Ｐゴシック",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      max-width: 508px;
      text-align: center;

      .uploaded-image{
	width: 50%;
	align-self: center;
      }

        .uploaded-images {
          height: 500px;
          width: 100%;
          height: 300px;
          max-width: 100%;
          margin-top: -1px;
          overflow: hidden;
          position: relative;
          border-radius: 16px;
          border: 1px solid #c4cfd6;
          margin-top: 10px;

          .uploaded-image {
            object-fit: contain;
            max-width: unset;
            max-height: 100%;
            height: 100%;
            width: 100%;
            content: "";
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }

          .uploaded-image:nth-child(2) {
            margin-left: 2px;
          }
        }

      .avatar {
        border-radius: 49px;
        height: 49px;
        width: 49px;
        margin: 12px 0 0 16px;
      }

      .bottom {
        padding: 32px;
        align-items: flex-start;
        overflow: hidden;
        min-height: fit-content;

        .title {
          font-size: 28px;
          font-weight: 600;
          line-height: 32px;
          margin-bottom: 10px;
          margin-top: 6px;
          max-width: 100%;
          overflow: hidden;
          overflow-wrap: break-word;
        }

        .description {
          max-width: 100%;
          overflow: hidden;
          overflow-wrap: break-word;
          min-height: fit-content;
        }

        .url {
          max-width: 100%;
          overflow: hidden;
          overflow-wrap: break-word;
        }

        .pinterest-profile {
          margin-top: 10px;
          font-size: 12px;
          max-width: 100%;
          overflow: hidden;
          overflow-wrap: break-word;
        }

        .bold {
          font-weight: bold;
          margin-left: 2px;
        }
      }
    }

    &.twitter {
      border: 1px solid #0001;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
      font-size: 14px;

      .avatar {
        border-radius: 49px;
        height: 49px;
        width: 49px;
        margin: 12px 0 0 16px;
      }

      .twitter-dot {
        font-size: 16px;
        font-weight: 900;
        margin: 0 6px;
      }

      .twitter-content {
        align-items: flex-start;
        padding: 12px 16px 0 0;
        position: relative;
        width: 100%;
        overflow: hidden;

        .twitter-dots {
          margin-left: auto;
          position: absolute;
          top: 8px;
          right: 14px;
          color: #696969;
        }

        .twitter-top {

          .twitter-header {
            align-items: center;
            margin-left: 10px;

            .twitter-heading {
              font-size: 15px;
              font-weight: 600;
              line-height: 20px;
            }

            .twitter-subtext {
              font-size: 15px;
              line-height: 16px;
              color: #5b7083;
              align-items: flex-start;
              margin-left: 5px;

              .twitter-subprofile {
                font-size: 15px;
                font-weight: 900;
                margin: 0 6px;
              }
            }

          }


        }

        .twitter-text {
          margin: 0 12px;
          max-width: 100%;
          overflow: hidden;
          overflow-wrap: break-word;
        }

        .twitter-controls {
          justify-content: space-between;
          width: 85%;
          
          .twitter-control {
            padding: 8px 10px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 900;

            svg {
              margin-right: 4px;
            }
          }
        }

        .link-preview {
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 16px;
          margin-top: 8px;
          max-width: 100%;

          &.small {
            margin-top: -1px;
            flex-direction: row !important;
            max-height: 156px;
            overflow: hidden;
            flex: 1 1 auto;
            align-items: center;
            height: 96px;

            .image-wrapper {
              height: 72px;
              max-height: 72px;
              min-height: 72px;
              width: 138px;
              max-width: 138px;
              min-width: 138px; 

              img {
                height: 72px;
                max-height: 72px;
                min-height: 72px;
                width: 138px;
                max-width: 138px;
                min-width: 138px; 
              }
            }

            .link-info {
              padding: 14px 12px;

              .link-description {
                overflow: hidden;
                text-overflow: ellipsis;
                min-height: fit-content;
              }
            }
          }

          .image-wrapper {
            border-radius: 16px 16px 0 0;
            max-height: 268.42px;
            width: 100%;
            max-width: 100%;
            margin-top: -1px;
            overflow: hidden;
            position: relative;

            .link-preview-image {
              object-fit: cover;
              max-width: 100%;
              max-height: 100%;
            }
          }

          .link-info {
            align-items: flex-start;
            width: 100%;
            padding: 6px 12px;

            .link-url {
              font-size: 15px;
              color: #5b7083;

              svg {
                margin-right: 4px;
                color: #5b7083;
              }
            }

            .link-title {
              font-size: 15px;
            }

            .link-description {
              min-height: fit-content;
              font-size: 15px;
              color: #5b7083;
            }
          }
        }

        .uploaded-images {
          height: 500px;
          width: 100%;
          height: 300px;
          max-width: 100%;
          margin-top: -1px;
          overflow: hidden;
          position: relative;
          border-radius: 16px;
          border: 1px solid #c4cfd6;
          margin-top: 10px;

          .uploaded-image {
            object-fit: contain;
            max-width: unset;
            max-height: 100%;
            height: 100%;
            width: 100%;
            content: "";
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }

          .uploaded-image:nth-child(2) {
            margin-left: 2px;
          }
        }
      }
    }

    &.linkedin {
      border: 1px solid #00000026;
      border-radius: 10px;
      font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;
      font-size: 14px;

      .linkedin-dot {
        font-size: 16px;
        font-weight: 900;
        margin: 0 6px;
      }

      .linkedin-top {
        position: relative;
        padding: 12px 16px 0 16px;

        .avatar {
          border-radius: 48px;
        }

        .linkedin-header {
          align-items: flex-start;
          margin-left: 8px;

          .linkedin-heading {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
          }

          .linkedin-subtext {
            font-size: 12px;
            line-height: 16px;
            color: #0009;
            align-items: flex-start;

            .linkedin-subprofile {
              font-size: 16px;
              font-weight: 900;
              margin: 0 6px;
            }
          }

        }

        .linkedin-dots {
          margin-left: auto;
          position: absolute;
          top: 8px;
          right: 14px;
          color: #696969;
        }

      }

      .linkedin-text {
        margin: 16px;
        max-width: 100%;
        overflow: hidden;
        overflow-wrap: break-word;
        min-height: fit-content;
      }

      .linkedin-controls {
        padding: 4px 8px;
        color: #696969;
        
        .linkedin-control {
          padding: 8px 10px;
          font-size: 14px;
          line-height: 21px;
          font-weight: 900;

          svg {
            margin-right: 4px;
          }
        }
      }

      .link-preview {
        background-color: #eef3f8;

        &.small {
          margin-top: -1px;
          flex-direction: row !important;
          max-height: 156px;
          overflow: hidden;
          flex: 1 1 auto;
          align-items: center;
          height: 96px;

          .image-wrapper {
            height: 72px;
            max-height: 72px;
            min-height: 72px;
            width: 138px;
            max-width: 138px;
            min-width: 138px; 

            img {
              height: 72px;
              max-height: 72px;
              min-height: 72px;
              width: 138px;
              max-width: 138px;
              min-width: 138px; 
            }
          }

          .link-info {
            padding: 14px 12px;

            .link-description {
              min-height: fit-content;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .image-wrapper {
          max-height: 268.42px;
          width: 100%;
          max-width: 100%;
          margin-top: -1px;
          overflow: hidden;
          position: relative;

          .link-preview-image {
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
          }
        }

        .link-info {
          align-items: flex-start;
          width: 100%;
          padding: 6px 12px;

          .link-url {
            font-size: 12px;
            color: #0009;
          }

          .link-title {
            font-weight: bold;
	    color: #333;
          }

          .link-description {
            min-height: fit-content;
            font-size: 14px;
          }
        }
      }

      .uploaded-images {
        height: 500px;
        width: 100%;
        max-width: 100%;
        margin-top: -1px;
        overflow: hidden;
        position: relative;

        .uploaded-image {
          object-fit: contain;
          max-width: unset;
          max-height: 100%;
          height: 100%;
          width: 100%;
          content: "";
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }

        .uploaded-image:nth-child(2) {
          margin-left: 2px;
        }
      }
    }

    &.facebook {
      padding-bottom: 12px;
      box-shadow:rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
      background-color:rgb(255, 255, 255);
      border-bottom-left-radius:8px;
      border-bottom-right-radius:8px;
      border-top-left-radius:8px;
      border-top-right-radius:8px;

      .facebook-top {
        justify-content: flex-start;
        padding: 12px 12px 6px 12px;
        position: relative;
      }

      .facebook-avatar {
        height: 40px;
        width: 40px;
        color: #0d4ea3;
        font-family: Helvetica, Arial, sans-serif;
        background-color: #63a3f2;
        border-radius: 40px;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin-right: 8px;
        border: 1px solid rgba(0, 0, 0, .1);
      }

      .facebook-header {
        align-items: flex-start;
      }

      .facebook-heading {
        color: #050505;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 15px;
        font-weight: 600;
        line-height: 19.9995px;
      }

      .facebook-dots {
        margin-left: auto;
        content: '';
        width: 20px;
        position: absolute;
        top: 14px;
        right: 8px;

        .facebook-dot {
          content: '';
          background-color: gray;
          height: 4px;
          width: 4px;
          border-radius: 4px;
          margin: 1px;
        }
      }

      .facebook-text {
        color: rgb(29, 33, 41);
        font-family: Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.32px;
        margin: 6px 12px 0 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid #ddd;
        max-width: 100%;
        overflow: hidden;
        overflow-wrap: break-word;
        min-height: fit-content;

        &.large {
          font-size: 24px;
          font-weight: 400;
          line-height:28.0008px;
        }
      }

      .fbqm {
        height: 12px;
        width: 12px;
      }

      .facebook-subtext {
        font-family: Helvetica, Arial, sans-serif;
        font-size: 13px;
        font-weight: 600;
        color: rgb(101, 103, 107);
        line-height: 16.0004px;

        svg {
          margin-left: 4px;
        }
      }

      .facebook-subprofile {
        font-family: Helvetica, Arial, sans-serif;
        font-size: 13px;
        font-weight: 400;
        color:rgb(101, 103, 107);
        line-height: 16.08px;
        margin: 0 4px 0 0;
      }

      .facebook-controls {
        justify-content: space-around;
        margin-top: 12px;
        align-items: center;

        .facebook-control {
          color: rgb(96, 103, 112);
          font-family: Helvetica, Arial, sans-serif;
          font-size:13px;
          font-weight: 600;

          svg {
            margin-right: 6px;
          }
        }
      }

      .link-preview {
        background-color:rgb(242, 243, 245);

        &.small {
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          margin-top: -1px;
          flex-direction: row !important;
          max-height: 156px;
          overflow: hidden;
          flex: 1 1 auto;
          align-items: stretch;

          .image-wrapper {
            border-right: 1px solid #ddd;
            height: 158px;
            max-height: 158px;
            min-height: 158px;
            width: 158px;
            max-width: 158px;
            min-width: 158px; 

            img {
              height: 158px;
              max-height: 158px;
              min-height: 158px;
              width: 158px;
              max-width: 158px;
              min-width: 158px; 
            }
          }

          .link-info {
            padding: 14px 12px;
            border-bottom: unset;

            .link-description {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .image-wrapper {
          border-bottom: 1px solid #ddd;
          max-height: 268.42px;
          width: 100%;
          max-width: 100%;
          border-top: 1px solid #ddd;
          margin-top: -1px;
          overflow: hidden;
          position: relative;

          .link-preview-image {
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
          }
        }

        .link-info {
          border-bottom: 1px solid #ddd;
          align-items: flex-start;
          width: 100%;
          padding: 6px 12px;

          .link-url {
            font-family: Helvetica, Arial, sans-serif;
            color: #777;
            font-size: 12px;
          }

          .link-title {
            font-family: Helvetica, Arial, sans-serif;
            font-weight: bold;
	    color: #333;
          }

          .link-description {
            font-family: Helvetica, Arial, sans-serif;
            color: #777;
            font-size: 14px;
          }
        }
      }

        .uploaded-images {
          height: 500px;
          width: 100%;
          height: 300px;
          max-width: 100%;
          margin-top: -1px;
          overflow: hidden;
          position: relative;
          border-radius: 16px;
          border: 1px solid #c4cfd6;
          margin-top: 10px;

          .uploaded-image {
            object-fit: contain;
            max-width: unset;
            max-height: 100%;
            height: 100%;
            width: 100%;
            content: "";
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }

          .uploaded-image:nth-child(2) {
            margin-left: 2px;
          }
        }

    }
  }

  .tooltip {
    height: 18px;

    .tooltip-badge {
      color: $white;
      background: $light-orange;
      height: 24px;
      width: 24px;
      font-size: 16px;
      border-radius: 60px;
      justify-content: center;
      align-items: center;
      cursor: default;
      font-weight: 700;
    }

    .tooltip-body {
      line-height: normal;
      max-width: 300px;
      background: $black;
      border-radius: 4px;
    }
  }

  .new-post-page {
    max-height: 100vh;
    flex: 1 1 auto;
    min-width: 100%;
    max-height: auto;
    align-items: stretch;

    .new-post-heading {
      font-weight: bold;
      font-size: 40px;
      line-height: 56px;
      margin-bottom: 45px;
    }

    h2 {
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
    }

    .wrapper {
      flex: 1 1 auto;
      align-items: stretch;
      max-height: 100vh;
      padding: 0 0 0 40px;
      min-width: --webit-fill-available;
      background-color: $off-white;
      overflow: hidden;

      .drafting {
        align-items: flex-start;
        min-width: 50%;
        flex: 1 1 auto;
        padding: 40px 40px 0 0;

        .text-area-container {
          position: relative;
          width: 100%;
        }
        textarea {
          border: none;
          resize: none;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          padding: 0 0 24px 0;
          min-width: 100%;
          background-color: $off-white;
          border-bottom: 1px solid $gray;
          max-height: 300px;
          overflow-y: auto;
        }

        .char-count {
          position: absolute;
          padding: 0 8px;
          bottom: 12px;
          right: 0;
          border-radius: 64px;
          background: $light-gray;
          color: $gray;
          transition: .3s all;

          &.hidden {
            opacity: 0;
          }

          &.visible {
            opacity: .7;
          }
        }

        input {
          border: none;
          resize: none;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          min-width: 100%;
          background-color: $off-white;
          padding: 0;
          margin-top: 24px;
          margin-bottom: 48px;
          outline: none;
        }

        .image-drop-box {
          cursor: pointer;

          &.small {
            margin-right: 8px;
          }
        }

        input[type='file'] {
          display: none;
        }

        .images {
          min-width: 100%;
          max-width: 100%;
          flex-wrap: wrap;
          overflow: hidden;
          flex: 1 1 auto;
        }

        .image {
          flex: 1 1 auto;
          min-width: 140px;
          height: 140px;
          background-size: contain;
          background-repeat: no-repeat;
          border-radius: 4px;
          margin-right: 8px;
          margin-bottom: 8px;
          align-items: flex-end;
          position: relative;

          .mask {
            visibility: hidden;
            background: $black;
            border-radius: 4px;
            opacity: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            transition: all .2s;
          }

          svg {
            visibility: hidden;
            opacity: 0;
            cursor: pointer;
            position: absolute;
            top: 16px;
            right: 16px;
            transition: all .2s;
          }

          &:hover {
            .mask {
              visibility: visible;
              opacity: .2;
            }

            svg {
              visibility: visible;
              opacity: 1;
            }
          }
        }

        .share-to-row {
          margin: 32px 0 40px 0;

          button {
            width: 200px;
            margin: 0 0 0 0;
          }

        }
      }

    }

    .footer {
      justify-content: flex-end;
      min-width: 100%;
      padding: 36px 40px;
      background-color: $white;
      border-top: 1px solid $gray;

      .schedule {
        cursor: pointer;
        user-select: none;

        svg {
          margin-left: 8px;
        }
      }

      .multi-button-wrapper {
        margin-left: 40px;
      }
    }

    .confirmation {
      align-items: flex-start;
      margin-bottom: 0;

      ul {
        font-weight: 700;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 1.5em;
        list-style-type: unset;
      }
    }
  }

  .new-post-confirm-page {
    max-height: 100vh;
    flex: 1 1 auto;
    min-width: 100%;
    min-height: 100vh;
    align-items: stretch;

    .wrapper {
      flex: 1 1 auto;
      align-items: stretch;
      max-height: 100vh;
      padding: 0 0 0 40px;
      min-width: --webit-fill-available;
      background-color: $off-white;

      .drafting {
        align-items: flex-start;
        min-width: 50%;
        flex: 1 1 auto;
        padding: 40px 40px 0 0;
      }
    }
      .confirmations {
        align-items: flex-start;
      }

    .footer {
      justify-content: space-between;
      min-width: 100%;
      padding: 36px 40px;
      background-color: $white;
      border-top: 1px solid $gray;

      .button {
        width: 224px;
        margin: 0;
      }

    }
  }
}

body {
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

.AppInterior {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: stretch;
  align-items: stretch;
}

.admin {

  .nav {
    .black-link {
      cursor: pointer;
      margin-right: 5px;
      color: blue;

      &.active {
        color: orange;
      }
    }
  }
  .content {
    align-items: flex-start;

    .exception {
      align-items: flex-start;
      margin-bottom: 45px;
      background: lightgray;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;

    .tiny {
      width: 3%;
    }

    .small {
      width: 5%;
    }

    .medium {
      width: 10%;
    }

    td {
      word-wrap: break-word;
      overflow: hidden;
      border: 1px solid black;
      text-align: center;
    }
  }

  .user {
    align-items: flex-start;
  }

  .organization {
    align-items: flex-start;
  }
}

.app-interior {
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
}

body#dash-page {
  background: $eggshell;
}

//applied when the modal is open
body.modal {
  overflow: hidden;
}

ul,ol {
  list-style-type: none;
  margin: 0;
  padding: 0; 
}

img, picture {
  margin: 0;
  max-width: 100%; 
}

table {
  border-collapse: collapse;
  margin: 0.75em 0;
}

th {
  border-bottom: 1px solid $medium-gray;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; 
}

td {
  border-bottom: 1px solid $off-white;
  padding: 0.75em 0; 
}

tr, td, th {
  vertical-align: middle; 
}

body, input, textarea {
  color: $black;
  font-family: 'Heebo', sans-serif;
  font-size: 1em;
  line-height: 1.5; 
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Heebo', sans-serif;
  font-size: 1.25em;
  line-height: 1.2;
  margin: 0 0 0.75em; 
}

a {
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  color: $light-orange;
}

small {
  font-size: 14px;
  color: $light-gray;
  line-height: 24px;
}

strong {
  font-weight: 700;
}

h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  color: $black;
  margin: 0px;
}

h2 {
  font-size: 48px;
  font-weight: bold;
  line-height: 24px;
  color: $black;
}

h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $black;

  margin-bottom: 0px;
}

b {
  font-weight: 600;
}

p {
  margin: 0px;
}

i {
  margin: 8px 8px;
}

::-webkit-scrollbar {
  width: 7px;
  background: $off-white;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: $dark-white;
  border-radius: 3px;
}

::-webkit-input-placeholder {
  @include text;
  font-size: 14px;
  color: $medium-gray;
}
:-moz-placeholder {
  @include text;
  font-size: 14px;
  color: $medium-gray;
}
::-moz-placeholder {
  @include text;
  font-size: 14px;
  color: $medium-gray;
}
:-ms-input-placeholder {
  @include text;
  font-size: 14px;
  color: $medium-gray;
}

.flex-column {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-row {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.pull-right {
  margin-left: auto !important;
}

.pull-left {
  margin-right: auto;
}

.pull-center {
  margin-right: auto;
  margin-left: auto;
}

.width-150 {
  width: 150px;
}

.clickable {
  cursor: pointer;
}

.stretch {
  width: 100%;
}

.transparent {
  opacity: .5;
}

.error {
  color: $red;
}

.success {
  color: $green;
}

a {
  transition: background-color 0.15 ease, color 150ms ease; 
}

.page-frame{
  animation: fadein 1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

i.icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  margin: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &.icon-small {
    width: 16px;
    height: 16px;
  }

  &.icon-large {
    width: 32px;
    height: 32px;
  }
}

.icon-label { margin-left: 10px; }

.icon-link {
  cursor: pointer;
}

.icon,
.icon-label { vertical-align: middle; }

.post-subhead {
  font-size: 18px;
  font-weight: 300;
  line-height: 40px;
  background-color: $off-white;
  border-radius: 4px;
  padding: 8px 29px;
  margin: 25px 0 24px;
}

input[type=search] {
  border: 1px solid #C6C6C6;
  border-radius: 4px;
  padding: 12px 16px;
}

input[type=checkbox] {
  display: none;
}

input[type=text], input[type=password], input[type=username], input[type=email],  {
  &.error {
    border: 1px solid $red;
  }
  outline: none;
  border: 1px solid #C6C6C6;
  border-radius: 4px;
  padding: 11px 16px;
  max-height: 48px;
}

textarea {
  outline: none;
}

@keyframes border-color {
  0% {border: 2px solid #F45F0C;}
  50% {border: 2px solid #4ca8fe;}
  100% {border: 2px solid #F45F0C;}
}

.user-image {
  height: 24px;
  width: 24px;
  border-radius: 32px;

  &.large {
    height: 48px;
    width: 48px;
  }

  &.tiny {
    height: 24px;
    width: 24px;
  }
}

.hide_section{
  display: none;
}

.text-align-center{
	text-align: center !important;
	width: 100%;
}

.text-wrap, .header, .detail-row{
  word-wrap: anywhere;	
  overflow-wrap: anywhere;
  word-break: break-all;
}


.plr-80{
  padding: 0px -80px;
}
.pl80{
  padding-left: 80px;
}

.pt10{
  padding-top: 10px !important;
}
.pt20{
  padding-top: 20px !important;
}
.pt30{
  padding-top: 30px !important;
}

.pl20{
  padding-left: 20px !important;
}
.pl10{
  padding-left: 10px !important;
}
.pl30{
  padding-left: 30px !important;
}
.pr-10{
  padding-right: 10px;
}
.pr10{
  padding-right: 10px;
}
.pr20{
  padding-right: 20px;
}

.mr10{
  margin-right: 10px !important;
}
.mr15{
  margin-right: 15px !important;
}

.mt10{
  margin-top: 10px !important;
}
.mt15{
  margin-top: 15px !important;
}
.mt20{
  margin-top: 20px !important;
}
.mt30{
  margin-top: 30px !important;
}
.mt40{
  margin-top: 40px !important;
}
.mt50{
  margin-top: 50px !important;
}
.mt60{
  margin-top: 60px !important;
}
.mb5{
  margin-bottom: 5px !important;
}
.mb10{
  margin-bottom: 10px !important;
}
.mb20{
  margin-bottom: 10px !important;
}
.mb30{
  margin-bottom: 30px !important;
}
.m10{
  margin: 10px !important;
}
.m20{
  margin: 20px !important;
}

.cursor-pointer{
 cursor: pointer;
}

.zindex99{
 z-index: 99;
}

.tbl_width75{
  width: 75px;
}

.tbl_width200{
  width: 200px;
}

.admin-page{
  padding: 20px 15px !important;
}

.btn-pullright {
    position: absolute;
    right: 5px;
}

.exceptions-row{
  padding: 10px;
  background: #FFF !important;
  width: 96% !important;
}

.user-trail-status{
  /* font-size: 20px !important; */	
}

.firebaseui-error, .firebaseui-info-bar-message {
  margin-top: 5px;
  color: red !important;	
}

.firebaseui-new-password-component ul, .passwordRules ul{
 list-style-type: disc !important;
 margin: 10px 0px 0px 0px !important;
 font-size: 12px !important;
 padding: 0px 15px !important;
}

.pullLeft{
 position: absolute !important;
 left: -1000px;
}

.width100{
  width: 100px !important;
}

.left-sec{
  width: 45%;
}

.right-sec{
  width: 55%;
}

.bigLetter{
  font-size: 65px;
}

.button.black{
  background-color: #000;
  margin: 20px 0px !important;
}

    .mtmin60{
	margin-top: -25px;    
    }

.subCopy{
font-size: 1.5em;
line-height: 1.25em;
padding: 30px 180px;
text-transform: capitalize;
}

.priced_scale_box{
  background: #FFFFFF;
  /*border: 1px solid #c8c8c8;*/
  box-shadow: 0px 0px 10px 4px #eee;
  -moz-box-shadow: 0px 0px 10px 4px #eee;
  -webkit-box-shadow: 0px 0px 10px 4px #eee;
  -webkit-appearance: none;
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
  position: relative;
  max-width: 285px;
}

.priced-scale-copy{
  font-size: 1.2em;
  line-height: 1.25em;
  text-transform: capitalize;
}

.priced-scale-image{
  margin-right: 20px;
}

.tickMark img {
    max-width: 35px;
}

.priced-scale-image img {
    width: 100px;
    max-width: 100px;
    height: 100px;
}

.socials button.button{
  border-radius: 10px !important;
}

.last-button button.button{
  box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.03);
  border-radius: 10px !important;
}

.home-headings{
  font-size: 48px !important;
  line-height: 64px;
  font-weight: 700;
}

.sub-heading-text {
    font-size: 1.5em;
    line-height: 1.4em;
    text-transform: capitalize;
    width: 85%;
    margin-top: 30px;
}

.mobile-view {
    height: 1px;
}

.tickMark{
  position: absolute;
  top: 8px;
  right: 6px;
}

.heading-border img{
  width: 270px;
}

.brand-voice-img{
  margin-top: 50px;
}

.br-vc-sec{
   margin-top: 20px;
}

.priced-scale-sec{
  margin-top: 30px;
}

.footer-sec{
	background-image: url("./public/images/footer.png");
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 435px; 	

	color: #FFF;
	padding-left: 80px;
	padding-right: 80px;
	padding-top: 130px;

	.footer-column1{
	  align-items: normal;	
	  width: 42%;
	  margin-right: 10%;
	  margin-top: 20px;

	  .footer-text{
		margin-top: 20px;
	  }
	}
	.footer-column2{
	  align-items: normal;	
	  width: 28%;
	  padding-right: 5%;
	  margin-top: 20px;
	}
	.footer-column3{
	  align-items: normal;	
	  width: 20%;
	  padding-right: 20px;
	  margin-top: 20px;
	}

	.footer-heading {
	  font-weight: 500;
	  font-size: 1.2rem;
	}

	.footer-heading-border {
	    border-top: 2px solid #FFF;
	    width: 40px;
	}

	.footer-menu{
		margin-top: 20px;
	
		a{
		    display: block;
		    color: #FFF;
		}
	}

	.flex-row{
		align-items: normal !important;
	}

	.footer-copyright {
		margin-top: 70px;
		border-top: 1px solid #696969;
		width: 100%;
		padding: 20px;
	}

	.footer-column-position{
	  width: 80%;
	  margin-left: auto;
	  margin-right: auto;
	}
}


  @media (device-width: 1024px) {

    .benefits-row {
      min-height: 300px !important;
      }

  }

@include mobileMin768Max1000{
 .buttons-direction{
   flex-direction: row !important;
   padding: 0 16px;
 }
}


@include mobile {

.ben-col, .priced_scale_box {
 border: 1px solid #c8c8c8;
 }

.footer-sec{
  background-image: url("./public/images/footer-mobile.png");
  background-size: 100% 100%;
}

.priced_scale_box{
  width: 100%;
  max-width: 100%;
}

.br-vc-sec{
   margin-top: 0px;
}

.brand-voice-img{
  margin-top: 20px;
  padding: 0px 5%;
}

.sub-heading-text {
    font-size: 18px;
    line-height: 26px;
    width: 100%;
}


.left1{
  width: 100% !important;
  margin-top: 0px !important;
}

.flex-direction-column{
  flex-direction: column;
  padding: 0 16px;
}

.footer-column-position, .footer-column1, .footer-column2, .footer-column3{
  width: 100% !important;
  background: #363636 !important;
  margin-top: 40px;
}

.footer-sec{
  padding:0px !important;
}

.mobile-view{
  height: 50px;
}



}


















.App{
font-family: 'Heebo', sans-serif;

h1, h2, h3, h4, h5, h6 {
  font-family: 'Heebo', sans-serif;
  font-size: 1.25em;
  line-height: 1.2;
  margin: 0 0 0.75em;
}

.off{
  display: none;
}

  .topbar-section{
    .topbar_row{
    height: 80px;
    align-items: center;
    width: 100%;
    }
    .header-left a{
        display: block;
        img{
            width: 240px;
        }
    }
    .header-right{
        justify-content: flex-end;
        display: flex;
  
        .topbar_btn{
            padding: 0;
            height: 48px;
            line-height: 48px;
            font-size: 22px;
            font-family: 'Heebo', sans-serif;
        }
        .login_btn{
         background: none;         
         font-weight: 600;                      
         color: $light-orange;
         text-decoration: none;
        }
        .login_btn:hover{
          color: $light-orange;
        }
        .signup_btn{
        background:$light-orange;
        color: $white;
        width: 240px;
        text-align: center;
        margin-left: 63px;
        border-radius: 4px;
        }
        .signup_btn:hover{
        background: $med-orange;
        color: $white;
        }
    }
    
  }

      .hero-banner-section{
        background: url('./public/images/hero_bg.png') no-repeat;
        background-size: 84% 100%;
        min-height: 774px;
        width: 100%;
     
            .hero-banner-heading h2{
              font-size: 46px;
              font-weight: 400;
              line-height: 50px;
              color: $white;
              padding-top: 50px;
              margin: 0 0 10px;

              .hero-capitalltr{
                font-size: 65px;
              }
          }
          .hero-banner-para{
              font-weight: 400;
              font-size: 18px;
              line-height: 32px;
              color: $white;
          }
          .hero-banner-price{
              font-size: 18px;
              color: $white;
              margin: 15px 0 20px;
          }
          .hero-price{
              font-size: 24px;
              color: $white;
          }
          .banner_get_btn .btn{
            height: 56px;
            width: auto;
            font-size: 22px;
            border-radius: 6px;
            color: $white;
            background: #000;
            border: none;
            outline: none;
            box-shadow: none;
            padding: 0 20px;
        }
          .hero-banner-right-side img{
            padding-top: 30px;
            max-width: 100%;
            }
      }


      .hero-benefits-section{

            .hero-benefits-heading h2{
              font-size: 48px;
              line-height: 64px;
              font-weight: 700;
              text-align: center;
              color: $black;
              margin-bottom: 0;
            }
            .hero-heading-border{
                width: 270px;
                margin: 0 auto;
                img{
                  display: block;
                }
            }

        .hero-benifits-icons{
          background: url('./public/images/benefits_bg.png') no-repeat;
          background-size: 100%;
          margin: 20px 0 40px;
          padding-top: 50px;
          min-height: 450px;
    
            .benifits-col{
              height: 245px;
              background: $white;
              border-radius: 10px;
              padding: 20px;
              margin: 30px 0 10px;
              box-shadow: 0 0 10px 4px #eee;
              -moz-box-shadow: 0 0 10px 4px #eee;
              -webkit-box-shadow: 0 0 10px 4px #eee;

                  .benefits-image{
                    margin: 0 0 20px;
                  }

                  .benefits-img{
                    width: 120px;
                    height: 110px;
                  }
                  .benefits-img.maintain_col{
                    width: 150px;
                  }
                  .benefits-img.empower_col{
                    width: 150px;
                  }

                  .benefits-para{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                  }
            }
        }
      } 
       
      
      .hero-fetures-section{

            .hero-fetures-heading h2{
                  line-height: 64px;
                  font-size: 48px;
                  font-weight: 700;
                text-align: center;
                margin: 0;
                  color: $black;
            }
            .hero-fetures-border{
              width: 270px;
              margin: 0 auto;            
            }
            .feature-page-details{

              .features-col{
                height: 250px;
                padding: 26px 24px 20px;
                margin: 20px 0;     
  
                .hero-feature-icon{
                  width: 70px;
                  height: 70px;
                  margin-bottom: 15px;
                }
                .feature-heading{
                  font-size: 18px;
                  font-weight: 600;
                  font-family: 'Montserrat', sans-serif;
                  color: $black;
                  margin-bottom: 5px;
                }
                .feature-para{
                  font-size: 15px;
                  font-family: 'Montserrat', sans-serif;
                }
              }
              .card-col.assign_role .features-col, .card-col.workflows .features-col{
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border-radius: 15px;
              }
              .card-col.assign_role .features-col{
                margin-top: 162px;
              }
              .card-col.workflows .features-col{
                margin-top: -162px;
              }
              .card-col.schedule .features-col{
                margin-top: -168px;
              }
            }
            .feature_img{
              padding: 135px 0 0;
            }

      }


      .hero-allsocials-section{
        background: url('./public/images/socials_bg.png') no-repeat;
        background-position-x: 100%;
        background-size: contain;
        padding: 100px 0 80px;

        .hero-allsocials-heading h2{
          line-height: 64px;
          font-size: 48px;
          font-weight: 700;
        text-align: center;
          color: $black;
          margin: 0;
        }
        .hero-allsocials-border{
          width: 270px;
          margin: 0 auto;          
        }
            .social_manage_row{
              align-items: center;

              .social_left_col{
                order:2;
              }
                .allsocial-para p{
                  font-size: 26px;
                  line-height: 1.3em;
                }
                .social_submit_btn{
                  padding: 40px 0 0;
		  text-align: center;
                  
                  .btn{
                    background: $light-orange;
                    width: 290px;
                    color: $white;
                    font-size: 22px;
                    border-radius: 10px;
                    outline: none;
                    box-shadow: none;
                    font-family: "Heebo",sans-serif;
                    border-color: transparent;
                  }
                  .btn:hover{
                    background: $med-orange;
                  }
                }
          }

      }

.hero-ensure-section{
    background: url('./public/images/side-by-side_bg.png') no-repeat left top;
    background-size: cover;
    margin-top: -20px;
    padding: 150px 0 60px;

        .ensure_row{
          align-items: center;
          .hero-ensure-left-side{
            padding-right: 10px;

            h2{      
              font-size: 40px;
              font-weight: 400;
              text-transform: capitalize;
              line-height: 48px; 
            }
            p{
              font-size: 1.5em;
              line-height: 1.4em;
              text-transform: capitalize;
            }

          }
          
        }
                 
  }


.hero-brandvoice-section{

     .hero-brandvoice-heading h2{
                line-height: 48px;
                font-size: 48px;
                font-weight: 400;
                text-align: center;
                color: $black;
                margin: 40px 0 0;
          }
          .hero-brandvoice-border{
            width: 270px;
            margin: 0 auto;
          
          }
          .hero-brandvoice-para{
            font-size: 1.5em;
            line-height: 1.4em;
            text-transform: capitalize;
            margin: 30px 0 50px;
          }

  }
     

  .hero-protect-section{
    background: url('./public/images/protect-brand_bg.png') no-repeat;
    background-size: cover;
    margin-top: 60px;
    padding: 240px 0 0;
        
    .protect_row{
      align-items: center;
         .protect_left{
           order:2;
           .protect_strength_info h2{
            font-size: 40px;
            font-weight: 400;
            line-height: 48px;
            text-transform: capitalize;  
          }
          .protect_strength_info p{
            font-size: 1.5em;
            line-height: 1.4em;
            text-transform: capitalize;
          }

         }
      

    }


}

.hero-priced-section{
padding: 60px 0 0;

      .price_scale_heading{
        text-align: center;

        h2{
          line-height: 64px;
          font-size: 48px;
          font-weight: 400;
          color: $black;
          margin: 0;
          }
          .hero-priced-border{
            width: 270px;
            margin: 0 auto;
            img{
              display: block;
            }    
          }
          h6{
            font-size: 1.5em;
            line-height: 1.4em;
            text-transform: capitalize;
            margin:30px 0;
          }
      }
  
    .price_col_list{
      max-width: 930px;
      margin: auto;

        .price_info_box{
        background: $white;
        box-shadow: 0 0 10px 4px #eee;
        -moz-box-shadow: 0 0 10px 4px #eee;
        -webkit-box-shadow: 0 0 10px 4px #eee;
        border-radius: 20px;
        padding: 20px;
        position: relative;

          .tickmark{
              position: absolute;
              top: 8px;
              right: 6px;

              img{
                max-width: 35px;
                }
                            
            }
            .priced-box{                       
              display: flex;
              align-items: center;

                img{
                  width: 100px;
                  max-width: 100px;
                  height: 100px;
                }
              h4{
                font-size: 1.2em;
                line-height: 1.25em;
                text-transform: capitalize;
                padding: 0 0 0 20px;
                margin: 0;
              }
            }
                  
         }
      }
      .price_list_btns{
        padding: 63px 0;
          .btn{
            height: 56px;
            font-size: 22px;
            box-shadow: 0 0 8px 6px rgba(0,0,0,.03);
            border-radius: 10px;
            display: block;
            width: 100%;
            border: none;
            outline: none;
            box-shadow: none;
            color: $white;
          }
          .get_started_btn .btn{
            background: $light-orange;
          }
          .get_started_btn .btn:hover{
            background: $med-orange;
          }
          .schedule_demo .btn{
            background: #000;
          }
      }

    }

.login-page_section{
  
 .container-fluid{
   padding-left: 0;
   padding-right: 0;

    .reg_row{
        margin: auto;
        align-items: center;

        .error{
          color: #DE1B1B;
          max-width: 400px;
        }

        .reg_flex_column{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
        }

        .reg_wrapper{
          justify-content: center;
        align-items: center;
        flex: 1 1 auto; 
        }
        .mdl-card{
          width: 100%;
        }

        .reg_wrapper.verify_email_info{
          padding: 70px 15px 50px;
          font-size: 16px;
          line-height: 27px;
          font-family: 'Heebo', sans-serif;
          color: $black;
          font-weight: 400;
        }

        #firebaseui-auth-container{
          margin: 20px 0;
          font-family: 'Heebo', sans-serif;
          color: #333333;
          max-width: 400px;  

          .firebaseui-card-header{
             .firebaseui-title{
               color: $black;
               font-family: 'Heebo', sans-serif;
              font-weight: 600;
              font-size: 26px;
              line-height: 30px;
              margin: 0 0 25px;
             }
          }

          .firebaseui-card-content{
            width: 100%;
           
             .firebaseui-list-item{

               button{
                width: 300px;
                margin: 20px auto;
                height: 62px;
                border-radius: 50px;
                text-align: left;
                justify-content: left;
                padding: 0 5px;

                  .firebaseui-idp-icon-wrapper{
                    width: 50px;
                    height: 50px;
                    border-radius: 50px;
                    position: relative;
                    left: 0;
                    margin: 0 20px 0 0;
                  }
                  .firebaseui-idp-text{
                    font-family: 'Heebo', sans-serif;
                    color: $black;
                    font-size: 18px;
                    font-weight: 400;
                  }
              }
              button.firebaseui-idp-google{
                border:1px solid #c4c4c4;

                  .firebaseui-idp-icon-wrapper{
                    background: $gray-hover;
                  }
              }
              button.firebaseui-idp-password{
                background: $light-orange !important;

                .firebaseui-idp-icon-wrapper{
                  background: $dark-orange;
                }
                .firebaseui-idp-text{
                  color: $white;
                }
              }
              
            }

            .firebaseui-new-password-component ul{
              margin: 15px 0 0 !important;
                
                li{
                  font-size: 13px;
                  line-height: 22px;
              }
           
            }
          
          .firebaseui-textfield{
            margin: 20px 0 0;

            label{
              color: #a3a3a3;
              font-family: 'Heebo', sans-serif;
              font-size: 15px;
              line-height: 20px;
              margin: 0 0 5px;
            }
            input{
              height: 50px;
              border: 1px solid #E6E6E6;
              background: $white;
              color: #4f4f4f;
              font-family: 'Heebo', sans-serif;
              font-size: 15px;
              line-height: 20px;
              padding: 6px 17px;
              border-radius: 0;
            }
          }

          }
        
        .firebaseui-form-actions{
          flex-direction: inherit;
          justify-content: flex-end;

          .firebaseui-button{
            height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0 16px;
            outline: none;
            border-radius: 50px;
            font-family: 'Heebo', sans-serif;
            color: #333333;
            margin: 8px 0;
            text-align: center;
            border: none;
            font-size: 17px;
            font-weight: 500;
            line-height: 20px;
            cursor: pointer;
            -webkit-user-select: none;
            user-select: none;
            position: relative;
            width: 135px;
          }

          .firebaseui-id-secondary-link{
            width: 135px;
            background: #f1f1f1;
            margin-right: 15px;
          }
            
           .firebaseui-id-submit{
             background: $light-orange;
             color: $white;        
            border: none;
           }
        }
        .firebaseui-form-links{
          margin: 0 0 20px;
        }

        }

          .col-lg-6{
            padding-left: 0;
            padding-right: 0;
          }
          .login_reg_right{
            order: 2;
            .reg_brand_col{
              border-radius: 120px 0 0 0;
              background: $black;
              max-width: 700px;
              position: relative;
              min-height: 100vh;
              display: flex;
              align-items: center;
              text-align: center;
                .reg_brand_logo{
                  width: 320px;
                  margin: auto;
                  z-index: 9999;
                  cursor: pointer;
                }
                .reg_text_white{
                  color: #fff;
                  flex-direction: row;
                  display: flex;
                }
                .brand_star{
                  max-width: 100%;
                  position: absolute;
                  bottom: 0;
                }
            }
          }
     }
 }
 
}

.app_feed_section{
min-height: 100vh;
width: 100%;
max-width: 100vw;
overflow-x: hidden;

    .app_feed_container{
      padding-left: 0;
      padding-right: 0;
      .app_feed_row{
        margin-left: auto;
        margin-right: auto;
        .feed_sidebar, .feed_main, .app_col{
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

  .feed_page_section{
  padding: 32px 20px 75px;
  background: $white;

    .feed_tabbar_title{
      padding: 0 0 28px;

      .app_feed_title h1{
        font-family: "Heebo",sans-serif;
        color: $black;
        font-size: 40px;
        font-weight: 700;
        line-height: 42px;
        margin: 0;
      }

      .app_feed_title{
	&.user_title_email{
	h1{
		font-family: "Heebo",sans-serif;
		color: $black;
		font-size: 30px;
		font-weight: 700;
		line-height: 42px;
		margin: 0;
	      }	
	}
      } 
    }
    .newpost-button{

    .button{
      margin: 0;
      height: 42px;
      padding: 4px;
      width: 160px;
      border-radius: 50px;
      text-align: left;
      border: none;
      line-height: 36px;
      display: block;
      outline: none;
      font-family: 'Heebo', sans-serif;
      font-size: 16px;
      font-weight: 500;

      .post_icon{
        width: 34px;
        height: 34px;
        border-radius: 50px;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        margin: 0 12px 0 0;
        line-height: 32px;
      }

    }
      .button.orange{
        background: $light-orange;
        .post_icon{
          background: $med-orange;
          svg{
            fill: #FFF;
          }
        }
      }
    }

  .feed_tabbar_desc{
    padding-top: 8px;
     
    .app_feed_title_desc{
      color: $light-gray;
      font-family: 'Heebo', sans-serif;
      font-size: 1em;
      line-height: 1.8em;
      width: auto;
      position: relative;

       .tooltip{
        display: inline-block;
        }

      }

  }

  
    
  }

.feed_post_list{
  background: #f7f7f7;
padding: 20px;
border-radius: 15px;
margin: 0 0 20px;
 
  .post_user_header{
    display: flex;
align-items: center;

    .user-image.large{
      height: 50px;
      width: 50px;
      border: 3px solid #fff;
      border-radius: 50px;
      margin: 0 16px 0 0;
      box-shadow: 0 0 10px #bfbfbf;
    }
    .post_user_title{
      h3{
        font-family: 'Heebo', sans-serif;
        color: $black;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        font-weight: 500;
    }
    p{
      color: $light-gray;
      font-family: 'Heebo', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

  
  }

  }
  .networks_icons
  {
    margin-left: auto;
    display: inline-flex;
    .network-icon{
      svg{
        border-radius: unset;
        height: 32px;
        width: 32px;
        margin-right: 16px;
      }
    }
  }
  
  .feed_post_content{
    padding: 16px 0 0;
     p{
      font-family: 'Heebo', sans-serif;
      color: $black;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 4px;
     }
     a{
      font-family: 'Heebo', sans-serif;
      color: $light-orange;
      font-size: 1em;
      line-height: 1.5;
      font-weight: 400;
     }
  }
  .feed_post_images{
    border:5px solid #C4C4C4;
    border-radius: 10px;
    margin-top: 16px;
    height: 310px;

  

      .feed_up_images{
        align-items: center;
      height: 100%;
      img{
        width: 100%;
        // margin-bottom: 10px;
        max-height: 300px;
        object-fit: cover;
        border-radius: 5px;
      }

        .feed_uploaded_image img{
          display: block;
          width: 100%;
          // margin-bottom: 15px;
        }

      }

  }
  .feed_post_footer{
    .kissing-images{
      display: none;
    }
  }
  .feed_post_share{
    text-align: right;
    padding: 22px 0 0;
     
      a{
        width: 90px;
        height: 32px;
        line-height: 30px;
        border: 1px solid #929292;
        border-radius: 50px;
        text-align: left;
        display: inline-block;
        padding: 0 5px 0 10px;
        font-size: 1em;
        color: #929292;
        font-family: 'Heebo', sans-serif;

        svg{
          -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
          margin-left: 3px;
          vertical-align: -6px;
          fill: $light-orange;
        }
      }
  }

}

}

// ------------new post page start------------
.new_post_page{

  .new_post_title{
  padding: 0 0 26px;

    h1{
      font-family: 'Heebo', sans-serif;
      color: $black;
      font-size: 40px;
      font-weight: 700;
      line-height: 42px;
      margin: 0;
    }
    p{
      color: #5E5E5E;
      font-family: 'Heebo', sans-serif;
      font-size: 16px;
      line-height: 24px;      
    }
  }

  ::-webkit-input-placeholder {
    @include text;
    font-size: 15px;
    color: #CECECE;
    opacity: 1;
  }
  :-moz-placeholder {
    @include text;
    font-size: 15px;
    color: #CECECE;
    opacity: 1;
  }
  ::-moz-placeholder {
    @include text;
    font-size: 15px;
    color: #CECECE;
    opacity: 1;
  }
  :-ms-input-placeholder {
    @include text;
    font-size: 15px;
    color: #CECECE;
    opacity: 1;
  }  

  .new_post_wrapper{

    .drafting_posts {

      .new_post_form_field{
       padding: 0 0 20px;

          input, textarea{
            height: 50px;
            border: 1px solid #E4E4E4;
            background: #FFFFFF;
            color: #333333;
            // color: #CECECE;
            font-family: 'Heebo', sans-serif;
            font-size: 15px;
            line-height: 20px;
            padding: 13px 17px;
            border-radius: 0;
            width: 100%;
          }
      }
      .char-count {
        position: absolute;
        padding: 0 8px;
        bottom: 12px;
        right: 0;
        border-radius: 64px;
        background: $light-gray;
        color: $gray;
        transition: .3s all;

        &.hidden {
          opacity: 0;
          display: none;
        }

        &.visible {
          opacity: .7;
        }
      }

      .image-drop-box {
        cursor: pointer;

        &.small {
          margin-right: 8px;
        }
      }

      input[type='file'] {
        display: none;
      }

      .images {
        min-width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
        overflow: hidden;
        flex: 1 1 auto;
      }

      .image {
        flex: 1 1 auto;
        min-width: 140px;
        height: 140px;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 4px;
        margin-right: 8px;
        margin-bottom: 8px;
        align-items: flex-end;
        position: relative;

        .mask {
          visibility: hidden;
          background: $black;
          border-radius: 4px;
          opacity: 0;
          height: 100%;
          width: 100%;
          position: absolute;
          transition: all .2s;
        }

        svg {
          visibility: hidden;
          opacity: 0;
          cursor: pointer;
          position: absolute;
          top: 16px;
          right: 16px;
          transition: all .2s;
        }

        &:hover {
          .mask {
            visibility: visible;
            opacity: .2;
          }

          svg {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      .share-to-row {
        margin: 32px 0 40px 0;

        button {
          width: 200px;
          margin: 0 0 0 0;
        }

      }
    }

  }

  .new_post_footer {
    min-width: 100%;
    padding: 30px 0 0;
    background-color: $white;
    border-top: 1px solid $gray;

    .schedule {
      cursor: pointer;
      user-select: none;

      svg {
        margin-left: 8px;
      }
    }

    .multi_button_wrapper {
      position: relative;
      user-select: none;
      margin: 0;
      width: auto;
      padding: 0;
  
      .multi_button {
        max-height: inherit;
        margin: 0;
        width: 182px;
        border-radius: 4px 0 0 4px;
        height: 50px;
        vertical-align: top;
        padding: 0 24px;
        line-height: 50px;
        font-family: 'Heebo', sans-serif;
      }
  
      svg {
        background-color: $med-orange;
        padding: 9px;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
        z-index: 2;
        height: 50px;
        width: 42px;
  
        &:hover { background-color: $dark-orange; }
      }
  
      .multi-button {
        border-radius: 4px 0 0 4px;
        z-index: 2;
  
        &.disabled {
          color: $dark-white; 
          background-color: $medium-gray;
          cursor: not-allowed;
        }
  
      }
  
      .options {
        display: none;
        position: absolute;
        right: 0px;
        width: 100%;
        bottom: 40px;
        z-index: 1;
  
        .option {
          border: 1px solid $gray;
          border-bottom: none;
          justify-content: center;
          height: 42px;
          width: 100%;
          cursor: pointer;
          background: $white;
        }
  
        .option:first-child {
          border-radius: 4px 4px 0 0;
        }
  
        .option:last-child {
        }
  
        &.open {
          display: block;
        }
      }
    }
  }

  .confirmation {
    align-items: flex-start;
    margin-bottom: 0;
    color: #726C6C;
font-size: 14px;
line-height: 20px;

    ul {
      font-weight: 700;
      margin: 10px 0 10px 15px;
      font-size: 16px;
      line-height: 29px;
      color: #000;
      list-style: disc;
    }
  }
  .post_preview{
  border-left: 1px solid #e6e6e6;
  padding-bottom: 18px;

    .preview_title{
      padding: 0 0 15px;
        h2{
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 0;
          color: $black;
        }
    }
    .preview_navigation{
      position: relative;
      
      .network_name{
        text-align: center;
        svg{
          height: 24px;
          width: 24px;
          margin-right: 8px;
        }
      }
      .nav_button{
        position: absolute;
        top: 50%;
        margin: -12px 0 0;
      }
      .nav_button.prev{
        left: 0;
	cursor: pointer;
      }
      .nav_button.next{
        right: 0;
	cursor: pointer;
      }
    }
    .preview_container{
      padding: 12px 0 0;
     
      .preview {
        width: 100%;
        max-width: 500px;
        align-items: stretch;
        min-width: inherit;
        border: 1px solid #e6e6e6;
        box-shadow: none;
        border-radius: 5px;
        padding-bottom: 8px;

        .flex-row {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
    
        &.post_images {
          font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro",メイリオ,Meiryo,"ＭＳ Ｐゴシック",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
          font-size: 14px;
          font-weight: 400;
          width: 100%;
          max-width: 508px;
    
            .uploaded-images {
              height: 500px;
              width: 100%;
              height: 300px;
              max-width: 100%;
              margin-top: -1px;
              overflow: hidden;
              position: relative;
              border-radius: 16px;
              border: 1px solid #c4cfd6;
              margin-top: 10px;
    
              .uploaded-image {
                object-fit: contain;
                max-width: unset;
                max-height: 100%;
                height: 100%;
                width: 100%;
                content: "";
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
              }
    
              .uploaded-image:nth-child(2) {
                margin-left: 2px;
              }
            }
    
        }
    
    
        &.pinterest {
          font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro",メイリオ,Meiryo,"ＭＳ Ｐゴシック",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
          font-size: 14px;
          font-weight: 400;
          width: 100%;
          max-width: 508px;
    
            .uploaded-images {
              height: 500px;
              width: 100%;
              height: 300px;
              max-width: 100%;
              margin-top: -1px;
              overflow: hidden;
              position: relative;
              border-radius: 16px;
              border: 1px solid #c4cfd6;
              margin-top: 10px;
    
              .uploaded-image {
                object-fit: contain;
                max-width: unset;
                max-height: 100%;
                height: 100%;
                width: 100%;
                content: "";
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
              }
    
              .uploaded-image:nth-child(2) {
                margin-left: 2px;
              }
            }
    
          .avatar {
            border-radius: 49px;
            height: 49px;
            width: 49px;
            margin: 12px 0 0 16px;
          }
    
          .bottom {
            padding: 32px;
            align-items: flex-start;
            overflow: hidden;
            min-height: fit-content;
    
            .title {
              font-size: 28px;
              font-weight: 600;
              line-height: 32px;
              margin-bottom: 10px;
              margin-top: 6px;
              max-width: 100%;
              overflow: hidden;
              overflow-wrap: break-word;
            }
    
            .description {
              max-width: 100%;
              overflow: hidden;
              overflow-wrap: break-word;
              min-height: fit-content;
            }
    
            .url {
              max-width: 100%;
              overflow: hidden;
              overflow-wrap: break-word;
            }
    
            .pinterest-profile {
              margin-top: 10px;
              font-size: 12px;
              max-width: 100%;
              overflow: hidden;
              overflow-wrap: break-word;
            }
    
            .bold {
              font-weight: bold;
              margin-left: 2px;
            }
          }
        }
    
        &.twitter {
          border: 1px solid #0001;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
          font-size: 14px;
    
          .avatar {
            border-radius: 49px;
            height: 49px;
            width: 49px;
            margin: 12px 0 0 16px;
          }
    
          .twitter-dot {
            font-size: 16px;
            font-weight: 900;
            margin: 0 6px;
          }
    
          .twitter-content {
            align-items: flex-start;
            padding: 12px 16px 0 0;
            position: relative;
            width: 100%;
            overflow: hidden;
    
            .twitter-dots {
              margin-left: auto;
              position: absolute;
              top: 8px;
              right: 14px;
              color: #696969;
            }
    
            .twitter-top {
    
              .twitter-header {
                align-items: center;
                margin-left: 10px;
    
                .twitter-heading {
                  font-size: 15px;
                  font-weight: 600;
                  line-height: 20px;
                }
    
                .twitter-subtext {
                  font-size: 15px;
                  line-height: 16px;
                  color: #5b7083;
                  align-items: flex-start;
                  margin-left: 5px;
    
                  .twitter-subprofile {
                    font-size: 15px;
                    font-weight: 900;
                    margin: 0 6px;
                  }
                }
    
              }
    
    
            }
    
            .twitter-text {
              margin: 0 12px;
              max-width: 100%;
              overflow: hidden;
              overflow-wrap: break-word;
            }
    
            .twitter-controls {
              justify-content: space-between;
              width: 85%;
              
              .twitter-control {
                padding: 8px 10px;
                font-size: 14px;
                line-height: 21px;
                font-weight: 900;
    
                svg {
                  margin-right: 4px;
                }
              }
            }
    
            .link-preview {
              background-color: #fff;
              border: 1px solid #ddd;
              border-radius: 16px;
              margin-top: 8px;
              max-width: 100%;
    
              &.small {
                margin-top: -1px;
                flex-direction: row !important;
                max-height: 156px;
                overflow: hidden;
                flex: 1 1 auto;
                align-items: center;
                height: 96px;
    
                .image-wrapper {
                  height: 72px;
                  max-height: 72px;
                  min-height: 72px;
                  width: 138px;
                  max-width: 138px;
                  min-width: 138px; 
    
                  img {
                    height: 72px;
                    max-height: 72px;
                    min-height: 72px;
                    width: 138px;
                    max-width: 138px;
                    min-width: 138px; 
                  }
                }
    
                .link-info {
                  padding: 14px 12px;
    
                  .link-description {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    min-height: fit-content;
                  }
                }
              }
    
              .image-wrapper {
                border-radius: 16px 16px 0 0;
                max-height: 268.42px;
                width: 100%;
                max-width: 100%;
                margin-top: -1px;
                overflow: hidden;
                position: relative;
    
                .link-preview-image {
                  object-fit: cover;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
    
              .link-info {
                align-items: flex-start;
                width: 100%;
                padding: 6px 12px;
    
                .link-url {
                  font-size: 15px;
                  color: #5b7083;
    
                  svg {
                    margin-right: 4px;
                    color: #5b7083;
                  }
                }
    
                .link-title {
                  font-size: 15px;
                }
    
                .link-description {
                  min-height: fit-content;
                  font-size: 15px;
                  color: #5b7083;
                }
              }
            }
    
            .uploaded-images {
              height: 500px;
              width: 100%;
              height: 300px;
              max-width: 100%;
              margin-top: -1px;
              overflow: hidden;
              position: relative;
              border-radius: 16px;
              border: 1px solid #c4cfd6;
              margin-top: 10px;
    
              .uploaded-image {
                object-fit: contain;
                max-width: unset;
                max-height: 100%;
                height: 100%;
                width: 100%;
                content: "";
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
              }
    
              .uploaded-image:nth-child(2) {
                margin-left: 2px;
              }
            }
          }
        }
    
        &.linkedin {
          border: 1px solid #00000026;
          border-radius: 10px;
          font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;
          font-size: 14px;
    
          .linkedin-dot {
            font-size: 16px;
            font-weight: 900;
            margin: 0 6px;
          }
    
          .linkedin-top {
            position: relative;
            padding: 12px 16px 0 16px;
    
            .avatar {
              border-radius: 48px;
            }
    
            .linkedin-header {
              align-items: flex-start;
              margin-left: 8px;
    
              .linkedin-heading {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
              }
    
              .linkedin-subtext {
                font-size: 12px;
                line-height: 16px;
                color: #0009;
                align-items: flex-start;
    
                .linkedin-subprofile {
                  font-size: 16px;
                  font-weight: 900;
                  margin: 0 6px;
                }
              }
    
            }
    
            .linkedin-dots {
              margin-left: auto;
              position: absolute;
              top: 8px;
              right: 14px;
              color: #696969;
            }
    
          }
    
          .linkedin-text {
            margin: 16px;
            max-width: 100%;
            overflow: hidden;
            overflow-wrap: break-word;
            min-height: fit-content;
          }
    
          .linkedin-controls {
            padding: 4px 8px;
            color: #696969;
            
            .linkedin-control {
              padding: 8px 10px;
              font-size: 14px;
              line-height: 21px;
              font-weight: 900;
    
              svg {
                margin-right: 4px;
              }
            }
          }
    
          .link-preview {
            background-color: #eef3f8;
    
            &.small {
              margin-top: -1px;
              flex-direction: row !important;
              max-height: 156px;
              overflow: hidden;
              flex: 1 1 auto;
              align-items: center;
              height: 96px;
    
              .image-wrapper {
                height: 72px;
                max-height: 72px;
                min-height: 72px;
                width: 138px;
                max-width: 138px;
                min-width: 138px; 
    
                img {
                  height: 72px;
                  max-height: 72px;
                  min-height: 72px;
                  width: 138px;
                  max-width: 138px;
                  min-width: 138px; 
                }
              }
    
              .link-info {
                padding: 14px 12px;
    
                .link-description {
                  min-height: fit-content;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
    
            .image-wrapper {
              max-height: 268.42px;
              width: 100%;
              max-width: 100%;
              margin-top: -1px;
              overflow: hidden;
              position: relative;
    
              .link-preview-image {
                object-fit: cover;
                max-width: 100%;
                max-height: 100%;
              }
            }
    
            .link-info {
              align-items: flex-start;
              width: 100%;
              padding: 6px 12px;
    
              .link-url {
                font-size: 12px;
                color: #0009;
              }
    
              .link-title {
                font-weight: bold;
              }
    
              .link-description {
                min-height: fit-content;
                font-size: 14px;
              }
            }
          }
    
          .uploaded-images {
            height: 500px;
            width: 100%;
            max-width: 100%;
            margin-top: -1px;
            overflow: hidden;
            position: relative;
    
            .uploaded-image {
              object-fit: contain;
              max-width: unset;
              max-height: 100%;
              height: 100%;
              width: 100%;
              content: "";
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
    
            .uploaded-image:nth-child(2) {
              margin-left: 2px;
            }
          }
        }
    
        &.facebook {
          background-color:$white;
    
          .facebook-top {
            justify-content: flex-start;
            padding: 12px 12px 6px 12px;
            position: relative;
          }
    
          .facebook-avatar {
            height: 40px;
            width: 40px;
            color: #0d4ea3;
            font-family: Helvetica, Arial, sans-serif;
            background-color: #63a3f2;
            border-radius: 40px;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            margin-right: 8px;
            border: 1px solid rgba(0, 0, 0, .1);
          }
    
          .facebook-header {
            align-items: flex-start;
          }
    
          .facebook-heading {
            color: #050505;
            font-family: Helvetica, Arial, sans-serif;
            font-size: 15px;
            font-weight: 600;
            line-height: 19.9995px;
          }
    
          .facebook-dots {
            margin-left: auto;
            content: '';
            width: 20px;
            position: absolute;
            top: 14px;
            right: 8px;
    
            .facebook-dot {
              content: '';
              background-color: gray;
              height: 4px;
              width: 4px;
              border-radius: 4px;
              margin: 1px;
            }
          }
    
          .facebook-text {
            color: #1d2129;
            font-family: 'Heebo', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.32px;
            margin: 0;
            padding-bottom: 12px;
            border-bottom: 1px solid #ddd;
            overflow: hidden;
            overflow-wrap: break-word;
    
            &.large {
              font-size: 24px;
              font-weight: 400;
              line-height:28.0008px;
            }
          }
    
          .fbqm {
            height: 12px;
            width: 12px;
          }
    
          .facebook-subtext {
            font-family: Helvetica, Arial, sans-serif;
            font-size: 13px;
            font-weight: 600;
            color: rgb(101, 103, 107);
            line-height: 16.0004px;
    
            svg {
              margin-left: 4px;
            }
          }
    
          .facebook-subprofile {
            font-family: Helvetica, Arial, sans-serif;
            font-size: 13px;
            font-weight: 400;
            color:rgb(101, 103, 107);
            line-height: 16.08px;
            margin: 0 4px 0 0;
          }
    
          .facebook-controls {
            justify-content: space-around;
            margin-top: 12px;
            align-items: center;
    
            .facebook-control {
              color: rgb(96, 103, 112);
              font-family: Helvetica, Arial, sans-serif;
              font-size:13px;
              font-weight: 600;
    
              svg {
                margin-right: 6px;
              }
            }
          }
    
          .link-preview {
            background-color:rgb(242, 243, 245);
    
            &.small {
              border-top: 1px solid #ddd;
              border-bottom: 1px solid #ddd;
              margin-top: -1px;
              flex-direction: row !important;
              max-height: 156px;
              overflow: hidden;
              flex: 1 1 auto;
              align-items: stretch;
    
              .image-wrapper {
                border-right: 1px solid #ddd;
                height: 158px;
                max-height: 158px;
                min-height: 158px;
                width: 158px;
                max-width: 158px;
                min-width: 158px; 
    
                img {
                  height: 158px;
                  max-height: 158px;
                  min-height: 158px;
                  width: 158px;
                  max-width: 158px;
                  min-width: 158px; 
                }
              }
    
              .link-info {
                padding: 14px 12px;
                border-bottom: unset;
    
                .link-description {
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
    
            .image-wrapper {
              border-bottom: 1px solid #ddd;
              max-height: 268.42px;
              width: 100%;
              max-width: 100%;
              border-top: 1px solid #ddd;
              margin-top: -1px;
              overflow: hidden;
              position: relative;
    
              .link-preview-image {
                object-fit: cover;
                max-width: 100%;
                max-height: 100%;
              }
            }
    
            .link-info {
              border-bottom: 1px solid #ddd;
              align-items: flex-start;
              width: 100%;
              padding: 6px 12px;
    
              .link-url {
                font-family: Helvetica, Arial, sans-serif;
                color: #777;
                font-size: 12px;
              }
    
              .link-title {
                font-family: Helvetica, Arial, sans-serif;
                font-weight: bold;   
		color: #333;
              }
    
              .link-description {
                font-family: Helvetica, Arial, sans-serif;
                color: #777;
                font-size: 14px;
              }
            }
          }
    
            .uploaded-images {
              height: 500px;
              width: 100%;
              height: 300px;
              max-width: 100%;
              margin-top: -1px;
              overflow: hidden;
              position: relative;
              border-radius: 16px;
              border: 1px solid #c4cfd6;
              margin-top: 10px;
    
              .uploaded-image {
                object-fit: contain;
                max-width: unset;
                max-height: 100%;
                height: 100%;
                width: 100%;
                content: "";
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
              }
    
              .uploaded-image:nth-child(2) {
                margin-left: 2px;
              }
            }
    
        }
      }

    }

  }
}
// ------------new post page end------------

.feed_page_section.drafts_page{

  .draft_post_footer{
    padding: 20px 0 0;

    .col_wh{
      width: auto;
      padding: 0 0 0 12px;

      .button{
        margin: 0;
        width: 86px;
        height: 34px;
        padding: 0;
        font-size: 15px;
        line-height: 32px;
        border: 1px solid;
        font-weight: 400;
        border-radius: 50px;
        text-align: center;
        color: #fff;
        .post_icon{
          display: none;
        }
      }
    }
    .delete-button .button{
      background: #BA1111;
      border-color: #910909;
    }
    .edit_button .button{
      background: #03A042;
      border-color: #088239;
    }
  }

}

// ------------new post popup start------------
.modal-background {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: $black;
  opacity: .5;
}

.modal-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  flex: 1 1 auto;
  justify-content: center;
}

.modal {
  box-sizing: border-box;
position: fixed;
background-color: $white;
z-index: 9999;
border: none;
border-radius: 10px;
max-height: 100vh;
min-width: inherit;
min-height: inherit;
width: 540px;
left: 0;
right: 0;
margin: auto;
display: block;
height: auto;
top: 30px;
}
.modal-header {
  /*padding: 0;*/
  flex: 1 1 auto;
  min-width: 100%;
  justify-content: center;
  line-height: normal;

  .close {
    position: absolute;
    right: 24px;
    margin-right: 0;
    cursor: pointer;
  }

  h1 {
    font-weight: 600 !important;
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }
}

.modal-spinner {
  width: 100%;
  height: 100%;
  margin: auto;
}

.modal-content {
  width: 100%;
  align-items: flex-start;
  padding: 24px;

  div {
    margin-bottom: 40px;
  }

  button {
    width: 200px !important;
  }
}
.new_post_modal{
  .share-to.modal{
    padding: 20px;
     .modal_header{
       .nav.nav-tabs{
         border-bottom:none;
         li{
          padding: 0;
          margin: 0 20px 0 0;
          border-width: 0 0 2px;
          border-style: solid;
          border-color: transparent;
           a{
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            font-family: 'Heebo', sans-serif;
            padding: 0 3px;
           }
         }
         li.active{
           border-color: $light-orange;
         }
         li:last-child{
           margin-right: 0;
         }       
       }
       .popup_close{
        position: absolute;
        right: 7px;
        top: 7px;
	cursor: pointer;
       }
     }
     .post_popup_content{
      padding: 25px 0 0;
     }
     .popup_list , .network {
      border: 1px solid #C4C4C4;
      border-radius: 10px;
      margin-bottom: 10px;
      user-select: none;
      background-color: $white;
      padding: 3px 15px;
  
      &.team {
        .network-icon {
          margin-left: 16px;
        }
      }
  
      .user-wrapper {
        height: 78px;
        border-bottom: 1px solid $gray;
        width: 100%;
  
        .user-card {
          margin-left: 16px;
        }
      }
      .error {
        padding: 10px 0;
        font-size: 14px;
        line-height: 21px;
      }
  
      .expanding_profile_list{
        width: 100%;
        padding: 15px 0;
        border-bottom: 1px solid #C4C4C4;

        .triple-checkbox svg {
          margin-right: 12px;
          width: 18px;
        }
  
        .network-icon {
          height: 32px;
          width: 32px;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          svg{
            margin-right: 0;
            width: 100%;
          }
        }
  
        .name-column {
          align-items: flex-start;
          h3{
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            margin: 0;
            color: #000;
          }
          p{
            color: #726C6C;
            font-size: 14px;
            line-height: 20px;
            font-family: 'Heebo', sans-serif;
          }
        }
  
        .chevron {
          margin-left: auto;
          cursor: pointer;
            svg{
              width: 20px;
            }
        }
      }
      .expanding_profile_list:last-child{
        border-bottom: none;
      }
  
      .profiles {
        padding: 0 24px 0 24px;
        width: 100%;
  
        div:last-child {
          border-bottom: none;
        }
  
        .profile-wrapper {
          height: 78px;
          border-bottom: 1px solid #f2f2f2;
          width: 100%;
          justify-content: space-between;
  
          .profile {
            margin-left: 18px;
          }
        }
      }
    }
  
    .network {
      .header {
        .network-icon {
          margin-left: 0;
        }
  
        .button {
          width: 140px;
          margin-right: 16px;
        }
      }
  
      .profiles {
        .profile-wrapper {
  
          .profile {
            margin-left: 0;
            cursor: pointer;
          }
  
          .delete {
            cursor: pointer;
          }
        }
      }
    }
    .share_footer{
      width: 100%;
      padding: 5px 0;

      .number-selected {
        margin: 0 10px 0 0;
        color: #FFFFFF;
        background-color: #F45F0C;
        padding: 0 18px;
        border-radius: 50px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-family: 'Heebo', sans-serif;
        display: inline-block;
      }

      .button {
        margin: 0 0 0 auto;
        width: 100px;
        height: 36px;
        line-height: 36px;
        padding: 0;
        text-align: center;
        font-size: 16px;
        display: inline-block;
        border-radius: 50px;
        font-family: 'Heebo', sans-serif;
        .post_icon{
          display: none;
        }
      }
    }
  }
}
.confirm_post{
  .share-to.modal{
    padding: 20px;

    .modal_header h1{
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #000000;
      font-family: 'Heebo', sans-serif;
      margin: 0;
    }
    .post_popup_content{
      padding: 5px 0 0;
    }
    .share_footer{
      padding-top: 15px;
    }
  } 
}
.new_post_modal{
  .share-to.modal{
    .popup_list.personal{
      .expanding_profile_list{
        .profiles{
        padding:0;
          .profile_wrapper{
           border-color: #C4C4C4;
          }
        }
    }
    }
    .post_popup_content{
      .popup_list{
        .error.share-to-error{
          .newpost-button{
            text-align: left;
            margin-top: 14px;
            .button{
              width: auto;
              padding: 0 28px;
              border-radius: 3px;
              border: 0.5px solid #F45F0C;
              .post_icon{
                display: none;
              }
            }
          }
        }
      }
    }
    .post_popup_content{
      .popup_list.teams{
        border:none;
        padding: 0;
        .expanding-profile-list.team{
          padding: 20px;
          margin: 0 0 20px;
          border: 1px solid #C4C4C4;
          border-radius: 10px;
          .profile_header{
            .team_header_l{
              .triple-checkbox{
                position: relative;
                padding: 0 0 0 28px;
                svg{
                  width: 18px;
                  height: 18px;
                  top: 50%;
                  margin: -9px 0 0;
                  position: absolute;
                  left: 0;
                  path{
                    fill:#C4C4C4;
                  }
                }
                .name-column{
                  h3{
                    color: #000000;
                    font-family: 'Heebo', sans-serif;
                    font-size: 18px;
                    line-height: 24px;
                    margin: 0;
                    font-weight: 500;
                  }
                  p{
                    font-family: 'Heebo', sans-serif;
                    font-size: 16px;
                    line-height: 22px;
                  }
                }
              }
            }
            .team_header_r{
              padding-left: 0;
              svg{
                width: 22px;
                path{
                  fill:#000;
                }
              }
            }
          }
          .profiles{
            padding: 10px 0 0;
            .user-wrapper{
              padding: 15px 0;
              height: auto;
              border-bottom: 1px solid #C4C4C4;
              .checkbox{
                position: relative;
                padding: 0 0 0 26px;
                svg{
                  width: 18px;
                  height: 18px;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  margin: -9px 0 0;
                  path{
                    fill:#C4C4C4;
                  }
                }
                .user_card_item{
                  margin-left: 0;
                  svg.user-image{
                    position: relative;
                    margin: 0 12px 0 0;
                    width: 40px;
                    height: 40px;
                    border: 2px solid #EBE7E7;
                    border-radius: 50px;
                    top: 0;
                    min-width: inherit;
                  }
                  .user-name{
                    display: inline-block;
                    color: #726C6C;
                  font-size: 16px;
                  line-height: 20px;
                  font-family: 'Heebo', sans-serif;
                  font-weight: 400;
                  }
                }
              }
            }
            .user-wrapper:last-child{
              border: none;
              padding-bottom: 0;
            }
          }
          
        }
      }
    }
  }
}

.new_post_modal{
  .share-to.modal{
    .post_popup_content{
      .popup_list.organization{
       border:none;
       padding:0;
        .tools{
          padding: 0 15px 10px;
       
          .popup_org_tool_row{
            .checkbox{
              color: #000000;
              font-family: 'Heebo', sans-serif;
              font-size: 16px;
              line-height: 20px;
              padding-top: 6px;
              svg{
                margin-right: 3px;
                width: 18px;
                height: 18px;
                vertical-align: -4px;
              }
            }
            .popup_org_tool_r{
              input[type="search"]{
                width: 100%;
                border: 1px solid rgba(196, 196, 196, 0.5);
                height: 36px;
                font-family: 'Heebo', sans-serif;
              font-size: 16px;
              line-height: 20px;
              border-radius: 0;
              }
            }
          }
        }
        .popup_org_users{    
          border: 1px solid #C4C4C4;
          padding: 5px 15px;
          border-radius: 10px;
          .popup_org_user{
            padding: 8px 0;
            .checkbox{
              position: relative;
              padding: 0 0 0 26px;
              svg{
                width: 18px;
                height: 18px;
                position: absolute;
                left: 0;
                top: 50%;
                margin: -9px 0 0;
                path{
                  /* fill:#C4C4C4; */
                }
              }
              p{
                color: #726C6C;
                font-size: 16px;
                line-height: 20px;
                font-family: 'Heebo', sans-serif;
                font-weight: 400;
                svg{
                  position: relative;
                  margin: 0 12px 0 0;
                  width: 40px;
                  height: 40px;
                  border: 2px solid #EBE7E7;
                  border-radius: 50px;
                }
		.user-image{
                  position: relative;
                  margin: 0 12px 0 0;
                  width: 40px;
                  height: 40px;
                  border: 2px solid #EBE7E7;
                  border-radius: 50px;		
		}
              }
            }
          }
        }
      }
    }
  }
}  
// ------------new post popup end------------

.feed_page_section{
  .no_posts_desc{
    font-size: 18px;
line-height: 30px;
font-family: 'Heebo', sans-serif;
color: #5E5E5E;
font-weight: 400;
padding: 32px 0 0;
  }
}

// ------------Que Post Start------------
.feed_page_section.queue_page{
  .quepage_content{
    padding-bottom: 30px;
    .nav.nav-tabs{
      border: none;
      li{
        padding: 0;
        margin: 0 40px 0 0;
        border-width: 0 0 2px;
        border-style: solid;
        border-color: transparent;
          a{
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            font-family: 'Heebo', sans-serif;
            padding: 0 3px;
            color: #B8B7B6;
          }
      }
      li.active{
        border-color: $light-orange;
        
        a{
          color: $light-orange;
        }
      }
      .tab-number {
        margin-left: 10px;
        border-radius: 50px;
        background: #F45F0C;
        color: #fff;
        font-size: 13px;
        height: 21px;
        line-height: 21px;
        width: 21px;
        display: inline-block;
        text-align: center;
        vertical-align: 1px;
      }
    }
  }
}
// ------------Que Post End------------


// ------------Settings Page Start------------
.feed_page_section.setting_page{
  
  .setting_tabs{
    padding: 0 0 30px;

    .nav.nav-tabs{
      border: none;
      li{
        padding: 0;
        margin: 0 40px 0 0;
        border-width: 0 0 2px;
        border-style: solid;
        border-color: transparent;
          a{
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            font-family: 'Heebo', sans-serif;
            padding: 0 3px;
            color: #B8B7B6;
          }
      }
      li.active{
        border-color: $light-orange;
        
        a{
          color: $light-orange;
        }
      }
    }
  }

.setting_tab_list{
  .detail_row{
    padding-bottom: 25px;

    .detail_column{
      font-family: 'Heebo', sans-serif;
      font-size: 16px;
      line-height: 24px;
      color: $black;

        .detail_title{    
          color: rgba(0, 0, 0, 0.4);
            
        }
        .trial{
          background: $light-orange;
          color: #fff;
          border-radius:50px;
          padding: 0 16px;
          display: inline-block;
        }
        .detail_title.empty{
         height: 24px;
        }
        input{
          border: 1px solid #E4E4E4;
          color: $black;
          font-family: 'Heebo', sans-serif;
          font-size: 15px;
          height: 40px;
          padding: 5px 12px;
          border-radius: 0;
          width: 100%;
          max-width: 250px;
        }
        .newpost-button{
          text-align: left;

          .button{
            border-radius: 0;
            width: 125px;
            height: 40px;
            line-height: 40px;
            padding: 0;
            display: block;
            text-align: center;

            .post_icon{
              display: none;
            }
          }
          .button.light-orange{
            background-color: #FDEEE6;
            color: #F45F0C;
          }
        }

    }
  }
  .detail_row:last-child{
    padding-bottom: 0;
  }
}
.team_roles_content{
  background: #F9F9F9;
border-radius: 10px;
padding: 30px;
 .team_role_header{
   .team_role_title{
     h4{
       color: $black;
       font-family: 'Heebo', sans-serif;
       font-size: 24px;
       line-height: 28px;
       margin: 0;
     }
   }
   .team_role_add{
     padding-top: 3px;
     a{
      color: $light-orange;
      font-size: 16px;
      font-family: 'Heebo', sans-serif;
      svg{
        width: 18px;
        height: 18px;
        vertical-align: -4px;
        display: inline-block;
      }
     }
   }
 }
 .team_roles_list{
   padding: 30px 0 0;
   .team_role_list_item{
     border-bottom: 1px solid rgba(0, 0, 0, 0.1);
     padding: 0 0 16px;
     margin: 0 0 16px;
     position: relative;
     .list_item_name{
       h5{
        color: #000;
        font-family: 'Heebo', sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        margin: 0 0 6px;
       }
     }
     .tools{

      margin-right: auto;
     }
     .list_item_desc{
      font-family: 'Heebo', sans-serif;
      font-size: 16px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 400;
     }
   }
   .team_role_list_item:last-child{
     border: none;
     padding: 0;
     margin: 0;
   }
 }
}

.tab_billing{
  background: #F8F8F8;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 40px;

  .billing_top{
    padding: 0 0 35px;
    h2{
      color: #333333;
      font-family: 'Heebo', sans-serif;
      font-size: 24px;
      line-height: 28px;
      margin: 0;
      font-weight: 500;
    }
    .frequency{
      h5{
        color: #797979;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        font-family: 'Heebo', sans-serif;
        margin: 0;
        span{
          margin: 0 6px;
          svg{
            width: 32px;
          }
        }
        small{
          color: #13ae13;
          font-size: 14px;
        }
      }
    }
  }
  .billing_bottom{
    .billing_detail_info{
      padding: 0 0 0 34px;
      position: relative;
      svg{
        width: 22px;
        position: absolute;
        left: 0;
        top: 8px;
        path{
          fill:#000;
        }
      }
      h5{
        margin: 0;
        color: #000;
        font-size: 16px;
        line-height: 22px;
        font-family: 'Heebo', sans-serif;
        font-weight: 500;
        span{
          display: block;
          color: #797979;
        }
      }
    }
  }
}
.subscription_sec{
  h1{
    color: #000000;
    font-family: 'Heebo', sans-serif;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    margin: 0 0 15px;
  }
  .subscription_desc{
    color: #797979;
    font-family: 'Heebo', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    padding: 0 0 30px;
  }
  form{
    .StripeElement{
      border: 1px solid #E4E4E4;
      height: 40px;
      padding: 10px 12px;
      font-family: 'Heebo', sans-serif;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.3);
    }
    .billing_btn_row{
      padding-top: 30px;
      .submit_btn{
        .button{
          width: 125px;
          height: 40px;
          font-family: 'Heebo', sans-serif;
          font-size: 16px;
          line-height: 22px;
          color: $white;
          background: $light-orange;
          border: none;
          padding: 0;
          text-align: center;
          border-radius: 50px;
          margin: 0;
          font-weight: 500;
        }
      }
    }
  }
}
  
}

.edit-role{
  .new_role_back{
    .role_back_link{
      color: #999;
      font-family: 'Heebo', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      padding: 0 0 15px;
      width: 60px;
      cursor: pointer;
      svg{
        width: 18px;
        margin-right: 2px;
        vertical-align: -7px;
      }
    }
  }

  .newpost-button{
	.button{
	   width: 150px !important;
	}
  } 

  .new_role_desc{
	font-family: 'Heebo', sans-serif;
	font-size: 16px;
	line-height: 22px;
	color: rgba(0, 0, 0, 0.5);
	font-weight: 400;	
  }

}
.feed_page_section.new_role_page{
  .new_role_back{
    .role_back_link{
      color: #999;
      font-family: 'Heebo', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      padding: 0 0 15px;
      width: 60px;
      cursor: pointer;
      svg{
        width: 18px;
        margin-right: 2px;
        vertical-align: -7px;
      }
    }
  }
  .role_search{
    h6{
      color: #000000;
      font-family: 'Heebo', sans-serif;
      font-size: 16px;
      line-height: 30px;
      margin: 0;
    }
    .role_search_form{
        input{
          border: 1px solid #E4E4E4;
          height: 40px;
          max-width: 380px;
          width: 100%;
          color: #000000;
          font-family: 'Heebo', sans-serif;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          border-radius: 0;
          padding: 4px 12px;
        }
        ::-webkit-input-placeholder {
          @include text;
          font-size: 15px;
          color: rgba(0, 0, 0, 0.3);
        }
        :-moz-placeholder {
          @include text;
          font-size: 15px;
          color: rgba(0, 0, 0, 0.3);
        }
        ::-moz-placeholder {
          @include text;
          font-size: 15px;
          color: rgba(0, 0, 0, 0.3);
        }
        :-ms-input-placeholder {
          @include text;
          font-size: 15px;
          color: rgba(0, 0, 0, 0.3);
        }
   }
  }
  .edit_permissions{
    background: #F9F9F9;
    border-radius: 10px;
    padding: 30px;
    margin-top: 30px;
      h3{
        color: #333333;
        font-family: 'Heebo', sans-serif;
        font-size: 24px;
        line-height: 28px;
        margin: 0 0 16px;
        padding: 0 0 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      .role_permissions_list{
        .permission{
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 0 0 16px;
            margin: 0 0 16px;
            .new_role_title{
              h5{
              color: #000;
              font-family: 'Heebo', sans-serif;
              font-size: 16px;
              line-height: 20px;
              font-weight: 500;
              margin: 0 0 6px;
              }
              .new_role_desc{
                font-family: 'Heebo', sans-serif;
                font-size: 16px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.5);
                font-weight: 400;
              }
            }
            .role_check{
              padding-top: 13px;
              svg{
                width: 20px;
                path{
                  // fill: #C4C4C4;
                }
              }
            }
          
        }
      }
      .role_create{
        .newpost-button{
          .button{
            text-align: center;
            width: 140px;
            .post_icon{
              display: none;
            }
          }
        }
      }
  }
}
// ------------Settings Page End------------


// -----------------ME Profile Start------------------------
.Sidebar_me {

  .feed_tabbar_title{
    .newpost-button{
      .button{
        text-align: center;
        color: #000000;
        .post_icon{
          display: none;   
        }
      }
    }
  }  

  .image-control{
    margin: 0 0 30px;
      display: flex;
      position: relative;
      align-items: center; 
      
     .image_control{
      .huge-user-image{
        width: 150px;
        height: 150px;
	cursor: pointer;
	content: "";
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 230px;
	margin-right: 24px;
	position: relative;
      }  
      
      .svg{
	cursor: pointer;
      }
    }

    .user_image_delete svg {
	  height: 52px !important;
	  cursor: pointer;
	}

    

  }

  .content{
    
    .nav.nav-tabs{
      border: none;
      li{
        padding: 0;
        margin: 0 40px 0 0;
        border-width: 0 0 2px;
        border-style: solid;
        border-color: transparent;
          a{
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            font-family: 'Heebo', sans-serif;
            padding: 0 3px;
            color: #B8B7B6;
          }
      }
      li.active{
        border-color: $light-orange;
        color: $light-orange;
        
        a{
          color: $light-orange;
        }
      }
    }

  }
  .profile_tab_sec{
    padding: 0 0 50px;
  }

  .info{
   padding: 0;

    .info_field_label{
      color: #000000;
      font-size: 16px;
      line-height: 22px;
      font-family: 'Heebo', sans-serif;
      font-weight: 400;
      padding: 0 0 4px;
    }
    .info_field_desc{
      color: rgba(0, 0, 0, 0.5);
      font-family: 'Heebo', sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      padding: 0 0 8px;
    }

    .input-row{
    padding: 0 0 25px;

      input{
        width: 100%;
        height: 42px;
        border: 1px solid #E4E4E4;
        padding: 5px 12px;
        max-width: 500px;
        font-size: 16px;
        font-family: 'Heebo', sans-serif;
        line-height: 20px;
        color: $black;
    
      }    
      .newpost-button{
        text-align: left;

        .button{
          text-align: center;
          border-radius: 4px;
          background: rgba(141, 141, 141, 0.3);

	  &.light-orange {
		background-color: #fdeee6;
		color: #f45f0c;
	  }

          color: #fff;
          .post_icon{
            display: none;
          }
        }
        .button.orange{
          background: $light-orange;

        }
      }
    }
    
    
  }

  .security{

    .password-change{

      h1{
        font-size: 24px;
        margin: 0 0 25px;
        line-height: 28px;
      }

      .info_field_label{
           color: #000000;
           font-size: 16px;
           line-height: 22px;
           font-family: 'Heebo', sans-serif;
           font-weight: 400;
           padding: 0 0 4px;
           border-radius: 0;
         }
     
         .security_field{
         padding: 0 0 25px;
     
           input{
             width: 100%;
             height: 42px;
             border: 1px solid #E4E4E4;
             padding: 5px 12px;
             max-width: 500px;
             font-size: 16px;
             font-family: 'Heebo', sans-serif;
             line-height: 20px;
             color: $black;
             border-radius: 0;        
           }
         }


      .firebaseui-new-password-component ul, .passwordRules ul{
        margin: 0 !important;  
        padding: 0px 15px;
        li{
          font-size: 16px;
          line-height: 22px;
          font-family: 'Heebo', sans-serif;
          color: $black;
          margin-bottom: 8px;
        }
      }
      .security_password_btn{
        padding: 25px 0 0;
        .newpost-button{
          text-align: left;
          .button{
            color:$light-orange;
            border-radius: 3px;
            width: 200px;
            text-align: center;
            .post_icon{
              display: none;
            }
          }
        }
      }
      
    } 

  } 

      .edit-notification-settings {
        background: #f9f9f9;
        border-radius: 10px;
        padding: 30px;

        h2 {
          font-weight: normal;
          font-size: 24px;
          line-height: 42px;
          margin: 0;
          font-family: 'Heebo', sans-serif;
        }
        .app_feed_btn{
          .newpost-button{
            .button{
              text-align: center;
              width: 150px;
              .post_icon{
                display: none;
              }
            }
          }
        }
        
        .notifications_list{
          padding: 35px 0 0;

        .notification-setting {
          padding: 0 0 30px;
          h5{
            color: #000000;
            font-family: 'Heebo', sans-serif;
            font-size: 18px;
            line-height: 22px;
            font-weight: 400;
            margin: 0 0 5px;
          }
          .notification_list_desc{
            color: rgba(0, 0, 0, 0.5);
            font-size: 16px;
            line-height: 22px;
            font-family: 'Heebo', sans-serif;
            font-weight: 400;
          }
          .checkbox {
            cursor: pointer;
          }
        }
        .notification-setting:last-child{
          padding-bottom: 0;
        }
      }

    }

}
// -----------------ME Profile End------------------------


// -----------------New Users Start------------------
.Sidebar_new-user{

    .role_back_link{
      color: #999;
      font-family: 'Heebo', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      padding: 0 0 15px;
      width: 60px;
      cursor: pointer;
      svg{
        width: 18px;
        margin-right: 2px;
        vertical-align: -7px;
      }
    }

    .invites {

      .invite_field_list{
        padding-bottom: 25px;
        input{
          height: 50px;
          border: 2px solid #ebebeb;
          background: #FFFFFF;
          color: #333;
          font-family: 'Heebo', sans-serif;
          font-size: 15px;
          line-height: 20px;
          padding: 6px 17px;
          border-radius: 0;
          width: 100%;
        }
        .invite_field_r svg{
          height: 100%;
          line-height: 100%;
	  cursor: pointer;
          path{
            fill: #000;
          }
        }
      }
    
   
  }
  .invite_footer{
    .add_another{
     color: $light-orange;
     font-family: 'Heebo', sans-serif;
     font-size: 16px;
     height: 100%;
     align-items: center;
     font-weight: 400;
     display: flex;
     cursor: pointer;
     svg{
       width: 18px;
       height: 18px;
       margin:0 0 0 5px;
     }
    }
    .invite_footer_r{
      .button{
        text-align: center;
        width: 135px;
        .post_icon{
          display: none;
        }
      }
    }
  }

}
// -----------------New Users End------------------


// -----------------Teams Page Start------------------
.Sidebar_teams{
      .teams-container {
        .team {
          height: auto;
          cursor: pointer;
          border: none;
          border-radius: 10px;
          background: #f9f9f9;
          margin: 0 0 24px;
          width: 100%;
          padding: 30px;
          min-height: 180px;
          .team_list_title{
            border-bottom: 1px solid #D7D7D7;
            padding: 0 0 15px;
            margin: 0 0 15px;
            h1{
              font-size: 24px;
              line-height: 28px;
              font-family: 'Heebo', sans-serif;
              color: rgba(0, 0, 0, 0.55);
              margin: 0;
            }
          }
          .team_list_info{
            .kissing_images_list{
                .kissing_image{
                display: inline-block;
                width: 48px;
                margin: 0 0 0 -10px;
                svg, img{
                  width: 100%;
                  height: 48px;
                  border: 2px solid #FFFFFF;
                  border-radius: 50px;
                }
              }
              .kissing_image:first-child{
                margin-left: 0;
              }
           }
          }
        }
 
      }

}
// -----------------Teams Page End------------------



// -------------------New Team Page Start---------------------
.Sidebar_new-team{

  .role_back_link{
    color: #999;
    font-family: 'Heebo', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding: 0 0 15px;
    width: 60px;
    cursor: pointer;
    svg{
      width: 18px;
      margin-right: 2px;
      vertical-align: -7px;
    }
  }

 .new_teams_page{
   .new_team_field{
     padding: 0 0 25px;

     .new_team_field_label{
      color: #000000;
      font-size: 16px;
      line-height: 22px;
      font-family: 'Heebo', sans-serif;
      font-weight: 400;
      padding: 0 0 4px;
     }
     .new_team_field_input{
       input{
        width: 100%;
        height: 42px;
        border: 1px solid #E4E4E4;
        padding: 5px 12px;
        max-width: 500px;
        font-size: 16px;
        font-family: 'Heebo', sans-serif;
        line-height: 20px;
        color: $black;
        border-radius: 0;
       }
       .user{
         padding: 0 0 25px;
         .close{
          svg{
            height: 100%;
            line-height: 100%;
	    cursor: pointer;
            path{
              fill: #000;
            }
          }
         }
       }
     }
   }
 }

 .invite_footer{
  .add_another{
   color: $light-orange;
   font-family: 'Heebo', sans-serif;
   font-size: 16px;
   height: 100%;
   align-items: center;
   font-weight: 400;
   display: flex;
   cursor: pointer;
   svg{
     width: 18px;
     height: 18px;
     margin:0 0 0 5px;
   }
  }
  .invite_footer_r{
    .button{
      text-align: center;
      width: 135px;
      .post_icon{
        display: none;
      }
    }
  }
}

}
// -------------------New Team Page End---------------------


// ---------------Team Details Start----------------
.team_details_page{

  .new_role_back{
    .role_back_link{
      color: #999;
      font-family: 'Heebo', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      padding: 0 0 15px;
      width: 60px;
      cursor: pointer;
      svg{
        width: 18px;
        margin-right: 2px;
        vertical-align: -7px;
      }
    }
  }

   .team_nav_tabs{
   padding: 0 0 30px;
      .nav.nav-tabs{
        border: none;
        li{
          padding: 0;
          margin: 0 40px 0 0;
          border-width: 0 0 2px;
          border-style: solid;
          border-color: transparent;
            a{
              font-weight: 400;
              font-size: 18px;
              line-height: 30px;
              font-family: 'Heebo', sans-serif;
              padding: 0 3px;
              color: #B8B7B6;
            }
        }
        li.active{
          border-color: $light-orange;
          
          a{
            color: $light-orange;
          }
        }
      }
  }
  
  } 
// ---------------Team Details End----------------


// -----------------Networks Page Styles Start-----------------
.profile_desc{
  color: #5E5E5E;
  font-family: 'Heebo', sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
margin: 0 0 22px;
}
.network_list_sec{

   .network_list{
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    padding: 25px;
    margin: 0 0 20px;

     .network_list_item{

      .network_icon_col{
        svg{
          width: 32px;
          height: 32px;
          padding-right: 0;
        }
      }

        .network_title_col{
          padding-left: 0;
          .title_col{
            h3{
              color: #000000;
              font-family: 'Heebo', sans-serif;
              font-size: 22px;
              line-height: 26px;
              font-weight: 500;
              margin: 0;
            }
            p{
              font-family: 'Heebo', sans-serif;
              font-size: 16px;
              line-height: 22px;
              color: #726C6C;
            }
          }
        }
        .network_btn_col{
          padding-top: 3px;
          .newpost-button{
            display: inline-block;
            .button{
              background: #FDEEE6;
              border: 0.5px solid $light-orange;
              border-radius: 3px;
              text-align: center;
              width: 120px;
              .post_icon{
                display: none;
              }
            }
          }
          .network_toggle_icon{
            display: inline-block;
            margin: 0 0 0 10px;
            svg{
	    	cursor: pointer;
              path{
                fill:#000;
              }
            }
          }
        }
     }
   } 
}
// -----------------Networks Page Styles End-----------------



// -----------------Notifications Page Start-----------------
.notifications_page{

  .notification_tabs_list{
    .nav.nav-tabs{
      border: none;
      li{
        padding: 0;
        margin: 0 40px 0 0;
        border-width: 0 0 2px;
        border-style: solid;
        border-color: transparent;
          a{
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            font-family: 'Heebo', sans-serif;
            padding: 0 3px;
            color: #B8B7B6;
          }
      }
      li.active{
        border-color: $light-orange;
        
        a{
          color: $light-orange;
        }
      }
      .tab-number {
        margin-left: 3px;
        border-radius: 50px;
        background: $light-orange;
        color: #fff;
        font-size: 13px;
        height: 21px;
        line-height: 21px;
        width: 21px;
        display: inline-block;
        text-align: center;
        vertical-align: 1px;
      }
    }
 
  }

  .notification_history_list{
    padding: 30px 0 0;
    .notification_list_item{
      border: 1px solid #F1F1F1;
      border-radius: 6px;
      padding: 30px;
      margin-bottom: 20px;
      .notification_item_title{
        color: #000000;
        font-family: 'Heebo', sans-serif;
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;

	&.fs-16{
          font-size: 16px;
          line-height: 18px;	
	}
      }
      .notification_item_desc{
        color: rgba(0, 0, 0, 0.4);
        font-family: 'Heebo', sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        padding: 3px 0 0;
      }
    }
  }

}
// -----------------Notifications Page End-----------------


// -----------------Profile Page Start-----------------
.profile_page{
  .profile_card{
    .network_icon_col{
    padding-right: 0;
    svg{
      width: 32px;
      height: 32px;
    }
    }
    .network_title_col{
      padding-left: 0;
      h1{
        font-size: 24px;
        line-height: 32px;
        color: #000;
        font-family: 'Heebo', sans-serif;
        margin: 0;
      }
    }
  }
  .profile_status{
    padding: 26px 0;

    .profile-status{
      font-size: 16px;
      line-height: 22px;
      color: #333;
    }
  }
  .profile_rename{
    padding: 0 0 30px;
    .profile_rename_col_l{
      .rename_profile{
        color: #000000;
        font-size: 16px;
        line-height: 22px;
        font-family: 'Heebo', sans-serif;
        font-weight: 400;
        padding: 0 0 4px;
      }
      input{
        width: 100%;
        height: 42px;
        border: 1px solid #E4E4E4;
        padding: 5px 12px;
        max-width: 500px;
        font-size: 16px;
        font-family: 'Heebo', sans-serif;
        line-height: 20px;
        color: #333333;
        border-radius: 0;
      }
    }
    .profile_rename_col_r{
      .newpost-button{
        text-align: left;
        padding-top: 26px;
        .button{
          text-align: center;
          border-radius: 0;
          width: 120px;
          .post_icon{
            display: none;
          }
        }
      }
    }
  }
  
}
.profiles-list{
  .profile{
    .network-icon{
      display: inline-block;
      margin-right: 10px;
      svg{
        width: 32px;
        height: 32px;
      }
    }
  }
}
.post_detail_page{
  .new_role_back{
    .role_back_link{
      color: #999;
      font-family: 'Heebo', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      padding: 0 0 15px;
      width: 60px;
      cursor: pointer;
      svg{
        width: 18px;
        margin-right: 2px;
        vertical-align: -7px;
      }
    }
  }
}
.profile_wrapper{
  padding-top: 20px;
  border-top: 1px solid #f2f2f2;
  margin-top: 20px;
  
  .network_profile_info{
    display: inline-block;
  }
  .checkbox{
    svg{
      width: 18px;
      height: 18px;
    }
    .network_profile_info{
      margin-left: 10px;
      svg{
        width: 23px;
        height: 23px;
      }
    }

  }
}
// -----------------Profile Page End-----------------


// ------------Table Styles Start------------
.table_responsive{
  background: #F9F9F9;
   border-radius: 12px;
   padding: 8px 15px;
    .table{
      margin: 0;
      border-collapse: collapse;

      tbody{
        border: none;
        .gray-text.none{
          padding-top: 20px;
        }
      }
        tr th, tr td{
          padding: 10px 12px;
          border: none;
          font-size: 15px;
          line-height: 22px;
          color: #333;
        }
        thead tr th{
          border-bottom: 1px solid #D3D3D3;
          color: #A0A0A0;
        }
    }
}
.user_card_item{
  .user-name{
    display: inline-block;
  }
}
// ------------Table Styles End------------


// ------------sidebar-destop-view------------
.app-interior_dashboard{
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  overflow-y: hidden;
  
  flex-direction: row;

  .col-lg-9 {
    padding-left: 0;
  }

}
.admin_main_page{
  margin-left: 0;

  &.onboarding {
    margin-left: 0;
  }
}

.sidebar_menu{
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: hidden;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $white;
  border-right: 1px solid $gray;
  width: 25%;

  .Sidebar_menu_closing{
    .menu_closing_icon{
      display: none;

    }
}

.menu_scroll_view{
overflow-y: auto;
width: 100%;
overflow-x: hidden;
height: 76vh;
  }

  .accordian {
    max-height: 64px;
    overflow: hidden;
    user-select: none;
    align-items: flex-start;


    &.active {
      max-height: 600px;
    }

    .accordian-links {
      align-items: flex-start;
      padding-top: 20px;
      padding-left: 96px;
      width: 100%;

      a:last-child {
        padding-bottom: 12px;
      }

      .queue-link {
        justify-content: space-between;
        margin-right: 40px;

        .queue-number {
          background: $light-orange;
          color: $white;
          padding: 0 10px;
          border-radius: 64px;
        }
      }
    }

    a {
      padding-bottom: 32px;
      width: 100%;
  
    }

    .accordian-button {
      width: 320px;
      min-height: 64px;
      justify-content: space-between;
      padding: 0 40px;
      cursor: pointer;
      position: relative;

      .end {
        position: absolute;
        left: 0;
        top: 0;
      }

      &.active {
        background-color: $super-light-orange;
        color: $light-orange;
        height: unset;
      }

      .left {
        font-size: 16px;
        z-index: 2;

        svg {
          margin-right: 18px;
        }
      }
    }
  }

  .top-logo {
    align-items: flex-start;

    .logo-image {
      width: 100%;
      padding-bottom: 30px;
      padding-top: 30px;
      padding-left: 40px;
    }
  }

  .menu_bottom {
    align-items: flex-start;
    padding: 20px 40px 40px 40px;

    & > * {
      padding-bottom: 40px;
     
      svg, img {
        margin-right: 16px;
      }
    }
  }
}


.notification-banner {
  background: $light-orange;
  height: auto;
border-radius: 0 0 64px 0;
color: #FFFFFF;
font-size: 24px;
padding: 40px;

       .dots {
          padding-left: 10%;
      }
      .button {
        outline: none;
        display: inline-block;
        border-radius: 4px;
        padding: 12px 24px;
        margin: 8px;
        text-align: center;
        border: none;
        font-size: 16px;
        cursor: pointer;
      }
      .white {
        background-color: $white;
        color: $light-orange;
    }
}

.background-overlay{
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  background: #000;
  opacity: 0.5;
  display: none;
}

// ------------Main Sidebar Start------------
.app_feed_nav.navbar{
  display: block;
  width: 100%;
  padding: 0;
}
.notification-banner{
  .notification_banner_l{
    span{
      line-height: 26px;
    }
  }
  .newpost-button{
    .button{
      text-align: center;
      color: #000000 !important;
      margin: 0;
      padding: 0;
      width: 145px;
      height: 40px;
      line-height: 40px;
      border-radius: 50px;
      .post_icon{
        display: none;
      }
    }
  }
}
@media only screen and (min-width: 992px) {
.app_feed_nav.navbar{
  #main_sidebar_area.navbar-collapse{
    display: block !important;
  }
}
}


@media only screen and (max-width: 991px) {
  .sidebar-section{
    z-index: 1;
    position: fixed;

    .background-overlay{
      display: block;
    }

    .sidebar_menu{
      background: #000;
      width: 280px;
      border: none;
    }
    
        .Sidebar_menu_closing{

                .menu_closing_icon{
                  padding: 0;
                  float: right;
                  background:$light-orange;
                  color: white;
                  font-size: 19px;
                  width: 25px;
                  height: 25px;
                  margin: 2px;
                  text-align: center;
                  line-height: 25px;
                  display: block;
                } 
        }
  
    .menu_scroll_view{
      height: 90vh;
      padding: 55px 12px 0;
        }
      
        .accordian {
          max-height: inherit;
          display: block;
          padding: 0 0 28px;

          .accordian-button{
            padding: 0;
            background: none !important;
            width: 100%;
            min-height: inherit;
            .end{
              display: none;
            }
            svg{
                width: 18px;
                height: 18px;
              path{
                fill:$white;
              }
            }
            .left{
              font-size: 16px;
              line-height: 20px;
              color: $white;
              svg{
                margin-right: 12px !important;
              }
            }
          }
          .accordian-links {
            width: 100%;
            display: none;
            padding: 15px 44px 0;
            a{
              font-size: 15px;
              color: $white;
              margin: 0;
              display: block;
              padding: 10px 0;
              line-height: 20px;
            }
          }
         
        }
        .accordian.active{
          max-height: inherit;
          .accordian-links{
            display: block;
          }
        }

        .top-logo{
          display: none;
        }
        .menu_bottom{
          padding: 0;
           a{
             display: block;
             padding: 0 0 28px;
             font-size: 16px;
              line-height: 20px;
              color: $white;
              svg{
                margin-right: 12px;
                width: 18px;
                height: 18px;
                path{
                  fill:$white;
                }
              }
              .me-card{
                color: $white;
                svg{
                  path{
                    fill:$black;
                  }
                }
              }
           }
        }
      
  }
.admin_main_page{
 margin-left: 0px;
}

.toggle-menu{
	position: relative;

   &.before_sidebar{
	.navbar-toggler{
	  display: none;
	}
   }	

  .navbar-toggler{
    position: absolute;
    width: 32px;
    height: 32px;
    left: 244px;
    background: $light-orange;
    z-index: 9;
    padding: 0;
    border-radius: 0;
    border: none;
    top: 5px;
    outline: none;
box-shadow: none;

      .close{
        display: block;
        margin: auto;
      }
      .open{
        display: none;
      }
    }
    .navbar-toggler.collapsed{
      left: 10px;
      padding: 0;
      top: 36px;
      margin: -12px 0 0;
      background: none;
      width: 27px;
      height: 21px;
      z-index: inherit;
      display: block;
      .close{
        display: none;
      }
      .open{
        display: block;
      }
    }
}

.notification-banner{
  border-radius: 0 0 40px 0;
  padding: 20px 10px 20px 50px;
  font-size: 18px;
  position: relative;


    .notification_banner_r{
      padding-left: 0;
      .newpost-button{
        .button{
          font-size: 14px;
          width: 115px;
          padding: 0;
          height: 32px;
          line-height: 32px;
        }
      }
    }
}
}

// ------------Main Sidebar End------------


// ------------Onboarding Page Styles Start------------
.app_feed_section.onboarding{
  .feed_main{
    width: 100%;
    .onboarding_step{
      max-width: 370px;
      .onboarding_sec_title{
        padding: 0 0 20px;
        h1{
          font-weight: 500;
          font-size: 25px;
          line-height: 30px;
          color: #3F3F3F;
          font-family: 'Heebo', sans-serif;
          margin:0;
        }
        .subheading{
          font-family: 'Heebo', sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #333333;
          margin:8px 0 0;
        }
      }
      .onboarding_sec_form{
        .onboarding_sec_field{
          padding: 0 0 20px;
          input{
            width: 100%;
        height: 50px;
        border: 1px solid #E4E4E4;
        padding: 5px 12px;
        max-width: 100%;
        font-size: 16px;
        font-family: 'Heebo', sans-serif;
        line-height: 20px;
        color: #333333;
        border-radius: 0;
          }
        }
        .add_user{
          font-family: 'Heebo', sans-serif;
          font-size: 16px;
          line-height: 20px;
          color: $light-orange;
          padding: 0 0 22px;
          svg{
            width: 18px;
          }
        }
        .onabord_form_btn{
          
          .newpost-button{
            display: inline-block;
            .button{
              text-align: center;
              width: 140px;
              height: 50px;
              padding: 0;
              line-height: 50px;
              border-radius: 50px;
              border: none;
              margin: 0 0 0 10px;
              font-size: 16px;
             .post_icon{
               display: none;
             }
            }
            .button.disabled{
              background: #F1F1F1;
              color: #000000;
            }
          }
        }
        .onboarding_field_r{
          padding-left: 0;
          padding-top: 11px;
          svg{
	    cursor: pointer;
            path{
              fill:#000;
            }
          }
        }
      }
      .new_teams_page{
        .new_team_field{
          padding: 0 0 20px;
            .new_team_field_label{
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: #969696;
              font-family: 'Heebo', sans-serif;
              margin: 0 0 4px;
            }
            .new_team_field_input{
              input{
                width: 100%;
                height: 50px;
                border: 1px solid #E4E4E4;
                padding: 5px 12px;
                max-width: 100%;
                font-size: 16px;
                font-family: 'Heebo', sans-serif;
                line-height: 20px;
                color: #333333;
                border-radius: 0;
              }
              .user{
                padding: 0 0 20px;
                .select{
                  font-size: 14px;
                }
                .close{
                  padding-top: 12px;
                   svg{
                     path{
                       fill:#000;
                     }
                   }
                }
              }
            }
            .invite_footer{
              margin: auto;
              .invite_footer_l, .invite_footer_r{
                width: 100%;
              }
              .add_another{
                font-family: 'Heebo', sans-serif;
                font-size: 16px;
                line-height: 20px;
                color: $light-orange;
                padding: 0 0 22px;
                    svg{
                      width: 18px;
                      margin-left: 5px;
                    }
              }
              .newpost-button{
                .button{
                  text-align: center;
              width: 140px;
              height: 50px;
              padding: 0;
              line-height: 50px;
              border-radius: 50px;
              border: none;
              margin: 0 0 0 10px;
              font-size: 16px;
              
                  .post_icon{
                    display: none;
                  }
                }
              }
            }
      }
      }
    }
    .explainer{
      display: none;
    }
  
    .onboarding_step.onboarding_networks{
     max-width: 560px;
      .network_list{
       border: 1px solid #C4C4C4;
       border-radius: 10px;
       padding: 18px;
       margin: 0 0 15px;
   
        .network_list_item{
   
         .network_icon_col{
           padding-right: 0;
           padding-top: 6px;
           svg{
             width: 32px;
             height: 32px;
           }
         }
   
           .network_title_col{
             .title_col{
               h3{
                 color: #000000;
                 font-family: 'Heebo', sans-serif;
                 font-size: 22px;
                 line-height: 26px;
                 font-weight: 500;
                 margin: 0;
               }
               p{
                 font-family: 'Heebo', sans-serif;
                 font-size: 16px;
                 line-height: 22px;
                 color: #726C6C;
               }
             }
           }
           .network_btn_col{
             padding-top: 3px;
             padding-left: 0;
             .newpost-button{
               display: inline-block;
               .button{
                 background: #FDEEE6;
                 border: 0.5px solid $light-orange;
                 border-radius: 3px;
                 text-align: center;
                 width: 105px;
                 margin: 0;
                 padding: 0;
                 height: 40px;
                 line-height: 38px;
                 .post_icon{
                   display: none;
                 }
               }
             }
             .network_toggle_icon{
               display: none;
             }
           }
        }
      }
      .onboarding_sec_form{
        .onabord_form_btn{
          .newpost-button{
            display: block;
            text-align: left;
            .button{
              width: 100%;
              margin: 0;
              border-radius: 3px;
              border: 0.5px solid #F45F0C;
            }
          }
        }
      }
   }
   .onboarding_step.three{
     max-width: 500px;
   }

  }
}
// ------------Onboarding Page Styles End------------

.profiles_content_list{
  margin-bottom: 20px;
  .table_responsive{
    table{
      tr{
        .status{
          padding: 8px 0;
          border-radius: 4px;
          text-align: center;
          width: 160px;
        }
        .status.success {
          background: $light-green;
        }

        .status.bounced {
          background: $dark-red;
          color: $red;
        }

        .status.scheduled {
          background: $light-blue;
          color: $dark-blue;
        }
      }
    }
  }
  

}
.feed_page_section.user_page_section{
  .pull-right{
    .delete-button{
      .newpost-button{
        .button{
          text-align: center;
          .post_icon{
            display: none;
          }
        }
      }
    }
  }
}


.team_details_page.feed_page_section{
  .team_edit_user{
      .table_responsive{
         padding: 25px 0 0;
      }
    }
  }

  .feed_page_section.users_page_section{
      .edit-notification-settings.team_edit_user{
          .notification_title{
            display: none;
          }
      }
  }

  .invite_footer.team_edit_footer{
        padding-top: 20px;
  }
  
  .new_team_field_input .select{
          width: 100%;
  }

//----------------- Date-picker--------------
.feed_page_section{
  .new_post_footer{
    .date-time-picker{
      .date_picker_buttons.schedule_buttons{
        .newpost-button{
          .button{
            text-align: center;
            width: 120px;
            .post_icon{
              display: none;
            }
          }
        }
      }
    }
  } 
}
.team_details_page.feed_page_section.team_edit_page{
  .feed_tabbar_title{
    .app_feed_btn{
      .delete-button{
        .new_post_modal.confirm_post{
          .post_popup_content{
            .share_footer {
              .newpost-button{
                .button.orange{
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}
.tools.team_tools_icons{
  position: absolute;
  right: 0;
  top: 0;
  .tool_edit_link, .delete-icon{
    display: inline-block;
    margin: 0 0 0 10px;
  }
}
.date-time-picker {
  position: absolute;
  background: $white;
  // width: 376px;
  margin-bottom: 75px;

  .modal-header {
    padding-bottom: 16px;
    font-weight: 100;
    font-size: 24px;
    line-height: 32px;
    flex: unset;
  }

  .content {
    flex-grow: 2;

    .calendar {
      flex-grow: 2;

      button {
        background: none;
        border: none;
        cursor: pointer;
      }

      .react-calendar__navigation {
        height: 64px;
        background-color: #f2f2f2;
        padding: 0 32px 0 32px;

        .react-calendar__navigation__label {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          outline: none;
          color: $black;
        }

        .react-calendar__navigation__arrow {
          font-size: 32px;
          outline: none;
        }

        .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
          display: none;
        }
      }

      .react-calendar__month-view__weekdays {
        padding: 8px 24px 0 24px;

        .react-calendar__month-view__weekdays__weekday {
          padding: 0;
          max-width: unset;
          flex-basis: unset;
          display: flex;
          height: 40px;
          width: 40px;
          align-items: center;
          justify-content: center;
          border-radius: 4px;

          abbr {
            text-decoration: unset;
          }
        }
      }

      .react-calendar__month-view__days {
        padding: 8px 24px 16px 24px;

        .react-calendar__month-view__days__day {
          padding: 0;
          max-width: 40px;
          flex-basis: unset;
          display: flex;
          height: 40px;
          width: 40px;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          font-weight: 100;
          font-size: 16px;
          line-height: 24px;
          outline: none;
        }

        .react-calendar__tile--active {
          background-color: $very-light-orange;
          color: $light-orange;
        }
      }
      .react-calendar__month-view__days__day--neighboringMonth {
        color: $light-gray;
      }
    }

    .time-picker {
      width: 100%;
      padding: 0 24px 24px 24px;
      justify-content: space-between;

      .select {
        width: 88px;
        height: 48px;
      }

      .colon {
        margin: 0 10px;
      }

      .select:last-child {
        margin-left: 24px;
      }
    }

    .date_picker_buttons {
      width: 100%;
      padding: 0 24px 24px 24px;
      justify-content: space-between;

      button {
        margin: 0;
        width: 152px;
      }
    }
  }
}


  
@media only screen and (min-width: 768px) and (max-width: 1139px) {

.topbar-section{
  .navbar{
    padding: 14px 0;
  }
  .topbar_row{
    height: auto;
    .mobile_toggle{
      text-align: right;
        .navbar-toggler{
          padding: 0;
          border: none;
          border-radius: 0;
          box-shadow: none;
          outline: none;
     }
    }
    #basic-navbar-nav.header-right{
      display: none;
      background: #fff;
padding-top: 170px;
padding-bottom: 170px;
text-align: center;
      .topbar_btn{
        font-size: 16px;
      }
      .signup_btn{
        margin: 60px auto auto;
        width: 100%;
      }
    }
    #basic-navbar-nav.header-right.show{
      display: block;
    }
  }
}
.hero-banner-section{
  background-size: 100% 95%;
padding: 5% 0;
  .hero-banner-heading h2{
    font-size: 5vw;
   line-height: 6vw;
   padding-top: 20px;
  }
  .hero-banner-para{
    line-height: 26px;
   padding-right: 30px;
  }
  .hero-banner-right-side img{
    padding: 20px 0 24px;
  }
}
.hero-benefits-section{
  padding: 20px 0;

    .hero-benefits-heading h2{
      font-size: 30px;
  font-weight: 500;
  line-height: normal;
    }
    .hero-benifits-icons {
      padding: 0 16px;
      min-height: 300px;
      background: url('./public/images/benefits_bg_mobile.png') no-repeat;
      background-size: 100%;
      
      .benifits-col{
        border: 1px solid #c8c8c8;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: auto;

        .benefits-image{
          margin: 0;
          height: 130px;
        }
      }
      
    }
}

.hero-fetures-section{
  padding: 20px 0;
   
   .hero-fetures-heading h2{
     font-size: 30px;
     font-weight: 500;
     line-height: normal;
   }
   .feature-page-details{
      .features-col{
        padding: 10px;
        height: 170px;
        .hero-feature-icon{
          width: 55px;
          height: 55px;
          margin-bottom: 12px;
        }
        .feature-heading{
          font-size: 13px;
        }
        .feature-para{
          font-size: 12px;
          line-height: 17px;
        }
      }
      .card-col.assign_role .features-col{
        margin-top: 140px;
      }
      .card-col.workflows .features-col{
        margin-top: -115px;
      }
      .card-col.schedule .features-col{
        margin-top: -125px;
      }
   }
   .feature_img{
     padding: 115px 0 0;
   }

}

.hero-allsocials-section{
  background-size: cover;
  padding: 20px 0 50px;
  margin: 40px 0 20px;

    .hero-allsocials-heading h2{
      font-size: 30px;
      font-weight: 500;
      line-height: normal;
    }
    .social_manage_row{
        .allsocial-para{
          margin: 30px 0 0;
            p{
            font-size: 18px;
            line-height: 26px;
              br{
                display: none;
              }
            }
        }
        .social_submit_btn{
          padding: 60px 0 0;
          text-align: center;
        }
        .social_left_col{
          order:0;
        }
  }

}

.hero-ensure-section{
  margin-top: -40px;
  padding: 100px 0 40px;
  background-size: 100% 100%;

  .ensure_row{
    .hero-ensure-left-side{
        h2{
        font-size: 30px;
        font-weight: 500;
        line-height: normal;
        margin: 0;
        text-align: center;
      }
      p{
        font-size: 18px;
        line-height: 26px;
        padding: 30px 0 0;
      }
      .hero-ensure-border{
        width: 270px;
        margin: auto;
      }
    }
    .ensure-image{
      padding: 38px 0 0;
    }
  }
}
.hero-brandvoice-section{
  padding: 60px 0 20px;
    .hero-brandvoice-heading h2{
      font-size: 30px;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    .hero-brandvoice-para{
      font-size: 18px;
      line-height: 26px;
      margin: 30px 0 40px;
    }
    .brandvoice-image img{
      margin: auto;
    }
}

.hero-protect-section{
  background: url('./public/images/protect-brand-mobile_bg.png');
  background-size: 100% 100%;
  padding: 0 0 50px;
  margin-top: 40px;
  .protect_row{
      .protect_left{
          order:0;
          .protect_strength_info h2{
              margin: 100px 0 0;
              font-size: 30px;
              font-weight: 500;
              line-height: normal;
              text-align: center;
          }
          .hero-protect-border{
            width: 270px;
            margin: auto;
          }
        .protect_strength_info p{
             font-size: 18px!important;
             line-height: 26px!important;
             padding: 30px 0 20px;
            }
          }
  
        }
  }

.hero-priced-section {
  padding: 50px 0 0;

  .price_scale_heading h2{
     font-size: 30px;
     font-weight: 500;
     line-height: normal;
     }
    .price_scale_heading h6{
        font-size: 18px;
        line-height: 26px;
    }
    .price_col_list{
      max-width: 410px;

        .price_info_box{
           border: 1px solid #c8c8c8;
          margin-bottom: 40px;
             .priced-box h4{
                  font-size: 1.2em;
                  line-height: 1.25em;
                  text-transform: capitalize;
                  }
              }
          }
}


.login-page_section{

  .container-fluid{
 
     .reg_row{

      .reg_wrapper{
        width: 100%;
        form{
          padding: 30px 0;
        }
      }

      #firebaseui-auth-container{
        width: 100%;
      }

      .login_reg_right{
       order:0;
       .reg_brand_col{
         height: 390px;
         max-width: 100%;
         min-height: inherit;
         border-radius: 0 0 70px 0;
         overflow: hidden;

          .reg_brand_logo{
            max-width: 360px;
            padding: 0 20px;
            width: 100%;
              svg{
                width: 100%;
              }
          }
          .brand_star{
            max-width: inherit;
            bottom: -212px;
            right: -35px;
            width: 300px;
          }
       }

      }

    }
  }
}

// ------------new post page start------------
.new_post_page{

  .new_post_title{
    padding: 0 0 18px;

      h1{
        color: #000;
        font-size: 32px;
        font-weight: 500;
        line-height: 32px;
      }
      p{
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
      }
  }
  .new_post_wrapper{
    .drafting_posts {
      .new_post_form_field{
        padding: 0 0 15px;
      }
    }
    .post_preview{
      border-left: none;
      padding-bottom: 0;

       h2{
        font-size: 28px;
        line-height: 32px;
        font-weight: 500;
        color: #000;
       }
       .preview_container{
        .preview.linkedin .link-preview.small{
         height: auto;
        }
        .preview.linkedin .linkedin-controls .linkedin-control{
          padding: 3px 7px;
          font-size: 12px;
          line-height: 21px;
          font-weight: 500;
          font-family: 'Heebo', sans-serif;
          svg{
            margin-right: 3px;
           width: 16px;
          }
        }
       }
       .preview_navigation{
         .network_name svg{
          vertical-align: -5px;
         }
       }
       
    }
  }
  .new_post_footer{
    border: none;
    padding: 20px 0 0;
    .multi_button_wrapper{
      .multi_button{
        width: 130px;
        border-radius: 50px 0 0 50px;
        height: 50px;
        padding: 0 10px;
        font-size: 16px;
      }
      svg{
        border-radius: 0 50px 50px 0;
      }
      .options{
        bottom: 50px;
        border-bottom: 1px solid #E6E6E6;
        .option:first-child {
          border-radius: 0
        }
      }
    }
  }

}
// ------------new post page end------------

// ------------Onboarding Page Styles Start------------
.app_feed_section.onboarding{
  .feed_main{
    .onboarding_step{
      padding: 60px 20px;
      .new_teams_page{
        .new_team_field{
          padding: 0 0 20px;
            .new_team_field_input{
              input{
                font-size: 14px;
              }
              .user{
                padding: 0 0 15px;
                .col-lg-5.col-md-4{
                  padding-left: 0;
                }
                .select{
                  font-size: 12px;
                }
                .close{
                  padding-left: 0;
                }
              }
            }
            .invite_footer{
              margin: auto;
              .invite_footer_l, .invite_footer_r{
                width: 100%;
              }
              .add_another{
                font-size: 14px;
                padding: 0 0 20px;
              }
              .newpost-button{
                .button{
              width: 95px;
              height: 40px;
              line-height: 40px;
                }
              }
            }
      }
      }
    }

  }
}
// ------------Onboarding Page Styles End------------

.feed_page_section.setting_page{
  .tab_billing {
    .billing_bottom{
      .billing_detail_info{
        padding-bottom: 20px;
      }
    } 
  }
} 


}



@media only screen and (max-width: 767px) {

 .post-auth.page{
	.heading h1 {
	  font-size: 20px;
	}
}

.bottom_space{
	margin-bottom: 3rem !important;
}


  .sidebar-section{
    position: static;
  }
.footer-sec{
	.flex-row {
	  flex-direction: column !important;
	}
}

.topbar-mobile-view{
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: $white;
}

  .topbar-section{
    .navbar{
      padding: 19px 0;
    }
    .topbar_row{
      height: auto;
      width: 100%;
      margin: 0;

       .header-left{
        padding-left: 0;
        width: 66%;
       }
      .mobile_toggle{
        text-align: right;
        width: 34%;
        padding-right: 0;

          .navbar-toggler{
            padding: 0;
            border: none;
            border-radius: 0;
            box-shadow: none;
            outline: none;
       }
      }
      #basic-navbar-nav.header-right{
        display: none;
        background: #fff;
  padding-top: 120px;
  padding-bottom: 180px;
  text-align: center;
        .topbar_btn{
          font-size: 16px;
        }
        .signup_btn{
          margin: 50px auto auto;
          width: 100%;
        }
      }
      #basic-navbar-nav.header-right.show{
        display: block;
      }
    }
  }


  .hero-banner-section{
    background-size: 100% 95%;
    padding: 5% 0 0;
          .hero-banner-heading h2{
            font-size: 7vw;
            line-height: 8vw;
            padding-top: 20px;
          }
          .hero-banner-para{
            line-height: 26px;
            padding-right: 25px;
          }
          .hero-banner-right-side img{
            padding: 40px 0 24px;
          }
   }
   .hero-benefits-section{
     padding: 20px 0;
     
        .hero-benefits-heading h2{
          font-size: 30px;
    font-weight: 500;
    line-height: normal;
        }
        .hero-benifits-icons {
          padding: 0 16px;
          min-height: 300px;
          background: url('./public/images/benefits_bg_mobile.png') no-repeat;
          background-size: 100%;
          
          .benifits-col{
            border: 1px solid #c8c8c8;

            .benefits-img{
              margin: 0 auto;
              display: block;
              height: auto;
            }
          }
          
        }
   }
   .hero-fetures-section{
     padding: 20px 0;
      
      .hero-fetures-heading h2{
        font-size: 30px;
        font-weight: 500;
        line-height: normal;
      }
      .feature-page-details{
        .features-col{
          height: auto;
          margin-top: 0;
        }
        .card-col.assign_role .features-col{
          margin-top: 30px;
        }
        .card-col.workflows .features-col, .card-col.schedule .features-col{
          margin-top: 0;
        }
        .card-col.assign_role .features-col, .card-col.workflows .features-col{
          border: 1px solid #c8c8c8;
        }
      }
      .feature_img {
        padding: 0;
      }
     
   }

  .hero-allsocials-section{
    background-size: cover;
    padding: 20px 0 50px;
    margin: 60px 0 20px;

      .hero-allsocials-heading h2{
        font-size: 30px;
        font-weight: 500;
        line-height: normal;
        text-align: left;
      }
      .social_manage_row{
          .allsocial-para{
            margin: 30px 0 0;
              p{
              font-size: 18px;
              line-height: 26px;
                br{
                  display: none;
                }
              }
          }
          .social_submit_btn{
            padding: 60px 0 0;
          }
          .social_left_col{
            order:0;
          }
    }

  }
  .hero-ensure-section{
    margin-top: -40px;
    padding: 60px 0 20px;
    background-size: 100% 100%;
    .ensure_row{
      .hero-ensure-left-side{
          h2{
          font-size: 30px;
          font-weight: 500;
          line-height: normal;
          margin: 0;
        }
        p{
          font-size: 18px;
          line-height: 26px;
          padding: 30px 0 0;
        }
      }
      .ensure-image{
        padding: 38px 0 0;
      }
    }
  }
  .hero-brandvoice-section{
    padding: 60px 0 20px;
      .hero-brandvoice-heading h2{
        font-size: 30px;
        font-weight: 500;
        line-height: normal;
        margin: 0;
        text-align: left;
      }
      .hero-brandvoice-para{
        font-size: 18px;
        line-height: 26px;
        margin: 30px 0 40px;
      }
  }

  .hero-protect-section{
    background: url('./public/images/protect-brand-mobile_bg.png');
    background-size: 100% 100%;
    padding: 0 0 50px;
    margin-top: 40px;
    .protect_row{
        .protect_left{
            order:0;
            .protect_strength_info h2{
                margin: 100px 0 0;
                font-size: 30px;
                font-weight: 500;
                line-height: normal;
            }
          .protect_strength_info p{
               font-size: 18px!important;
               line-height: 26px!important;
               padding: 30px 0 20px;
              }
            }
    
          }
    }


  .hero-priced-section {
    .price_scale_heading h2{
       font-size: 30px;
       font-weight: 500;
       line-height: normal;
       }
      .price_scale_heading h6{
          font-size: 18px;
          line-height: 26px;
      }
      .price_col_list{
          .price_info_box{
             border: 1px solid #c8c8c8;
            margin-bottom: 40px;
               .priced-box h4{
                    font-size: 1.2em;
                    line-height: 1.25em;
                    text-transform: capitalize;
                    }
                }
            }

      .schedule_demo .btn{
         margin-top: 30px;
        }

  }

.login-page_section{

    .container-fluid{
   
       .reg_row{

        .reg_wrapper{
          width: 100%;
          form{
            padding: 30px 15px;
          }
        }

        #firebaseui-auth-container{
          width: 100%;
        }

        .login_reg_right{
         order:0;
         .reg_brand_col{
           height: 390px;
           min-height: inherit;
           border-radius: 0 0 70px 0;
           overflow: hidden;

            .reg_brand_logo{
              max-width: 360px;
              padding: 0 20px;
              width: 100%;
                svg{
                  width: 100%;
                }
            }
            .brand_star{
              max-width: inherit;
              bottom: -212px;
              right: -35px;
              width: 300px;
            }
         }

        }

      }
    }
}

.app_feed_section{
 
  .feed_page_section{
    padding: 15px;

    .feed_tabbar_title{
      padding: 0 0 22px;

       .app_feed_title{
        width: 51%;
         h1{
          color: #000;
          font-size: 23px;
          font-weight: 500;
          line-height: 26px;
         }
       }
       .app_feed_btn{
         width: 49%;
       }
       .newpost-button .button{
        width: 105px;
        font-size: 13px;
        font-weight: 400;
        height: 30px;
        line-height: 20px;

          .post_icon{
            width: 22px;
            height: 22px;
            margin: 0 6px 0 0;
            line-height: 20px;
            vertical-align: 1px;
            svg{
              width: 16px;
            }
          }
       }
    }
    .feed_tabbar_desc{
      .app_feed_title_desc{
        font-size: 15px;
        line-height: 24px;
        color: #5E5E5E;
      }
    }
    
  }

  .feed_post_list{
    padding: 15px;
    .post_user_header{
      .user-image.large{
        height: 45px;
        width: 45px;
        margin: 0 12px 0 0;
      }
      .post_user_title{
        h3{
          font-size: 14px;
          line-height: 18px;
          margin: 0 0 3px;
          color: #000;
        }
        p{
          font-size: 14px;
          line-height: 16px;
          color: #969494;
        }
      }
      
    }
    .feed_post_images{
      height: auto;
      .feed_up_images .feed_image_col{
        padding-bottom: 10px;
      }
      .feed_up_images .feed_image_col:last-child{
        padding-bottom: 0;
      }
    }
    .feed_post_content{
      padding: 12px 0 0;

      p{
        font-size: 14px;
        line-height: 20px;
        margin: 0;
        color: #000;
      }
      a{
        font-size: 11px;
        line-height: 15px;
        font-weight: 300;
      }
    }
    .feed_post_share{
      padding: 20px 0 0;

        a{
          width: 70px;
          height: 26px;
          line-height: 25px;
          padding: 0 5px 0 8px;
        font-size: 12px;
        color: #929292;
          svg{
            margin-left: 2px;
            fill: #F45F0C;
            width: 17px;
            vertical-align: -8px;
          }
        }
    }
    
    
  }

}

.feed_page_section.drafts_page{

  .draft_post_footer{
    padding: 18px 0 0;

    .col_wh .button{
      width: 67px;
      height: 24px;
      font-size: 12px;
      line-height: 24px;
    }
  }

}

.justify-content-md-end {
  justify-content: flex-end !important;
}
.response-box{
  padding: 10px 15px;
  max-width: 98%;
right: 0;
margin-left: auto;
margin-right: auto;
left: 0;
width: 100%;
}

// ------------new post page start------------
.new_post_page{

  .new_post_title{
    padding: 0 0 18px;

      h1{
        color: #000;
        font-size: 23px;
        font-weight: 500;
        line-height: 30px;
      }
      p{
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
      }
  }
  .new_post_wrapper{
    .drafting_posts {
      .new_post_form_field{
        padding: 0 0 15px;
      }
      .new_post_form_field:last-child{
        padding-bottom: 0;
      }
      .image-drop-box{
        width: 100%;
        height: auto;
      }
    }
    .post_preview{
      border-left: none;
      padding-bottom: 0;

      .preview_wrapper{
        padding-top: 20px;
      }
       h2{
        font-size: 23px;
        line-height: 30px;
        font-weight: 500;
        color: #000;
       }
       .preview_container{
        .preview.linkedin .link-preview.small{
         height: auto;
        }
        .preview.linkedin .linkedin-controls .linkedin-control{
          padding: 3px 7px;
          font-size: 12px;
          line-height: 21px;
          font-weight: 500;
          font-family: 'Heebo', sans-serif;
          svg{
            margin-right: 3px;
           width: 16px;
          }
        }
       }
       .preview_navigation{
         .network_name svg{
          vertical-align: -5px;
         }
       }
       
    }
  }
  .new_post_footer{
    border: none;
    padding: 20px 0 0;
    .multi_button_wrapper{
      .multi_button{
        width: 130px;
        border-radius: 50px 0 0 50px;
        height: 50px;
        padding: 0 10px;
        font-size: 16px;
      }
      svg{
        border-radius: 0 50px 50px 0;
      }
      .options{
        bottom: 50px;
        border-bottom: 1px solid #E6E6E6;
        .option:first-child {
          border-radius: 0
        }
      }
    }
  }

}
// ------------new post page end------------

// ------------new post popup start------------
.modal {
  width: 96%;
  max-width: 400px;
}
.new_post_modal{
  .share-to.modal{
    padding: 20px;

     .modal_header{
       .nav.nav-tabs{

         li{
           a{
            font-size: 15px;
           }
         }     
       }
     }
     .post_popup_content{
      padding: 22px 0 0;
     }
     .popup_list , .network {
      .expanding_profile_list{

        .network-icon {
          height: 32px;
          width: 32px;
        }
  
        .name-column {
          h3{
            font-size: 16px;
          }
        }
      }
    }
  
    .share_footer{

      .number-selected {
      height: 29px;
      line-height: 29px;
      font-size: 13px;
      }

      .button {
        width: 80px;
        height: 29px;
        line-height: 29px;
        font-size: 15px;
      }
    }
  }
}
.confirm_post{
  .share-to.modal{
    padding: 18px;
    .post_popup_content{
      padding: 5px 0 0;
    }
    .share_footer{
      .button{
        width: 93px;
      }
    }
  } 
}

.new_post_modal{
  .share-to.modal{
    .popup_list.personal{
      .expanding_profile_list{
        .header{
          .triple-checkbox{
            .network-icon{
              width: 32px;
            }
          }
        }
    }
    }
    .post_popup_content{
      .popup_list{
        .error.share-to-error{
          .newpost-button{
            text-align: left;
            margin-top: 14px;
            .button{
              padding: 0 14px;
              font-size: 14px;
              height: 34px;
              line-height: 32px;
            }
          }
        }
      }
    }
    .post_popup_content{
      .popup_list.teams{
        border:none;
        padding: 0;
        .expanding-profile-list.team{
          padding: 10px;
          margin: 0 0 10px;
          .profile_header{
            .team_header_l{
              .triple-checkbox{
                padding: 0 0 0 24px;
                svg{
                  width: 14px;
                  height: 14px;
                  margin: -7px 0 0;
                }
                .name-column{
                  h3{
                    font-size: 16px;
                    line-height: 20px;
                  }
                  p{
                    font-size: 15px;
                  }
                }
              }
            }
            .team_header_r{
              svg{
                width: 17px;
              }
            }
          }
          .profiles{
            padding: 5px 0 0;
            .user-wrapper{
              padding: 10px 0;
              .checkbox{
                padding: 0 0 0 22px;
                svg{
                  width: 14px;
                  height: 14px;
                  margin: -7px 0 0;
                }
                .user_card_item{
                  min-width: inherit;
                  svg.user-image{
                    margin: 0 10px 0 0;
                    width: 38px;
                    height: 38px;
                  }
                  .user-name{
                  font-size: 14px;
                  }
                }
              }
            }
          }
          
        }
      }
    }
  }
}

.new_post_modal{
  .share-to.modal{
    .post_popup_content{
      .popup_list.organization{
        .tools{
          padding: 0 5px 6px;
          .popup_org_tool_row{
            .checkbox{
              font-size: 14px;
              padding-top: 4px;
              svg{
                margin-right: 3px;
                width: 15px;
                height: 15px;
                vertical-align: -3px;
              }
            }
            .popup_org_tool_r{
              input[type="search"]{
                height: 30px;
              font-size: 14px;
              }
            }
          }
        }
        .popup_org_users{    
          padding: 5px 12px;
          .popup_org_user{
            padding: 6px 0;
            .checkbox{
              position: relative;
              padding: 0 0 0 26px;
              svg{
                width: 16px;
                height: 16px;
                margin: -8px 0 0;
              }
              p{
                font-size: 15px;
                svg{
                  margin: 0 10px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}  
// ------------new post popup end------------

.feed_page_section{
  .no_posts_desc{
    font-size: 14px;
    padding: 24px 0 0;
  }
}
// ------------Que Post Start------------
.feed_page_section.queue_page{
  .quepage_content{
    padding-bottom: 20px;
    .nav.nav-tabs{
      li{
        margin: 0 20px 0 0;
          a{
            font-size: 15px;
          }
      }
    }
  }
}
// ------------Que Post End------------


// ------------Table Styles Start------------
.table_responsive{
  padding: 8px 10px;
    .table{
        tr th, tr td{
          padding: 6px 8px;
        }
        tr td{
         font-size: 13px;
         color: #000000;
         min-width: 100px;
        }
        thead tr th{
          font-size: 12px;
          color: #A0A0A0;
        }
        thead tr th.team_role{
          min-width: 120px;
        }
        tr td .invited, tr td .active{
          width: 90px;
        }
    }
}
.user-card.user_card_item{
  min-width: 120px;
  .user-image{
    margin-right: 9px;
    height: 40px;
    width: 40px;
    min-width: 40px;
    display: inline-block;
  }
}
// ------------Table Styles End------------


.app_feed_section{
 
  .feed_page_section{

    .feed_tabbar_desc{
      padding-top: 4px;
      .app_feed_title_desc{
        line-height: 22px;
      }
    }
    
  }

}

// ------------Settings Page Start------------
.feed_page_section.setting_page{
  
  .setting_tabs{
    padding: 0 0 20px;

    .nav.nav-tabs{
      li{
        margin: 0 14px 0 0;
          a{
            font-size: 15px;
            padding: 0;
          }
      }
      li:last-child{
        margin-right: 0;
      }
    }
  }

.setting_tab_list{
  .detail_row{
    padding-bottom: 15px;

    .detail_column{
      font-size: 14px;
      line-height: 20px;
        .detail_title.empty{
         height: 20px;
        }
        input{
          width: 95%;
          max-width: 280px;
        }
        .newpost-button{
          .button{
            width: 110px;
            font-size: 14px;
          }
        }

    }
  }
}
.team_roles_content{
padding: 10px;

 .team_role_header{
   .team_role_title{
     h4{
      font-size: 16px;
      line-height: 20px;
     }
   }
   .team_role_add{
     padding-top: 0;
     a{
      font-size: 14px;
      svg{
        width: 14px;
        height: 14px;
        vertical-align: -3px;
      }
     }
   }
 }
 .team_roles_list{
   padding: 20px 0 0;

   .team_role_list_item{
     padding: 0 0 14px;
     margin: 0 0 14px;
     .list_item_name{
       h5{
        font-size: 14px;
        margin: 0 0 5px;
       }
     }
     .list_item_desc{
      font-size: 14px;
      line-height: 19px;
     }
   }
 }
}

.tab_billing{
  padding: 10px;
margin-bottom: 20px;

  .billing_top{
    padding: 0 0 15px;
    h2{
      font-size: 15px;
      line-height: 17px;
    }
    .team_plan_disc{
      padding-left: 0;
    }
    .frequency{
      h5{
        font-size: 13px;
        line-height: 17px;
        span{
          margin: 0 3px;
          svg{
            width: 25px;
          }
        }
        small{
          font-size: 9px;
        }
      }
    }
  }
  .billing_bottom{
    .billing_detail{
      .col-lg-3{
        padding-bottom: 10px;
      }
    }
    .billing_detail_info{
      padding: 0 0 0 26px;
      svg{
        width: 18px;
       top: 4px;
      }
      h5{
        font-size: 13px;
        line-height: 17px;
      }
    }
  }
}
.subscription_sec{
  h1{
    font-size: 15px;
    line-height: 20px;
    margin: 0 0 10px;
  }
  .subscription_desc{
    font-size: 13px;
    line-height: 17px;
    padding: 0 0 18px;
  }
  form{
    .billing_btn_row{
      padding-top: 20px;
    }
  }
}
  
}
.feed_page_section.new_role_page{
  .new_role_back{
    .role_back_link{
      font-size: 12px;
      padding: 0 0 12px;
      width: 60px;
      cursor: pointer;
      svg{
        width: 14px;
      margin-right: 3px;
      vertical-align: -3px;
      height: 14px;
      }
    }
  }
  .role_search{
    h6{
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 4px;
    }
    .role_search_form{
        input{
          font-size: 14px;
        }
        ::-webkit-input-placeholder {
          font-size: 14px;
        }
        :-moz-placeholder {
          font-size: 14px;
        }
        ::-moz-placeholder {
          font-size: 14px;
        }
        :-ms-input-placeholder {
          font-size: 14px;
        }
   }
  }
  .edit_permissions{
    padding: 10px;
margin-top: 20px;
      h3{
        font-size: 15px;
line-height: 22px;
margin: 0 0 12px;
padding: 0 0 12px;
      }
      .role_permissions_list{
        .permission{
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 0 0 12px;
            margin: 0 0 12px;
            .new_role_title{
              h5{
                font-size: 14px;
                line-height: 22px;
                margin: 0 0 3px;
              }
              .new_role_desc{
                font-size: 13px;
                line-height: 18px;
              }
            }
            .role_check{
              padding-top: 5px;
              svg{
                width: 15px;
              }
            }
          
        }
      }
      .role_create{
        .newpost-button{
          .button{
            text-align: center;
            width: 140px;
            .post_icon{
              display: none;
            }
          }
        }
      }
  }
}
// ------------Settings Page End------------


// -----------------ME Profile Start------------------------
.Sidebar_me { 

  .image-control{
    margin: 0 0 20px;
    .image_control{
      svg{
        width: 115px;
        height: 115px;
      }
    }
  }

  .content{
    
    .nav.nav-tabs{
      border: none;
      flex-wrap: inherit;
      li{
        margin: 0 20px 10px 0;
        display: inline;
      
          a{
            font-size: 15px;
          }
      }
      li:last-child{
        margin-right: 0;
      }
      li.active{
        border-color: $light-orange;
        color: $light-orange;
        
        a{
          color: $light-orange;
        }
      }
    }

  }
  .profile_tab_sec{
    padding: 0 0 20px;
  }

  .info{
   padding: 0;

    .info_field_label{
      font-size: 14px;
      padding: 0 0 3px;
    }
    .info_field_desc{
      font-size: 13px;
      line-height: 20px;
      padding: 0 0 6px;
    }

    .input-row{
    padding: 0 0 20px;

      .info_col_r{
        padding-left: 0;
      }
      .newpost-button{
        .button{
          font-size: 13px;
          width: 100%;
          max-width: 110px;
        }
      }
    }
    
    
  }



  .security{

    .password-change{

      h1{
        font-size: 20px;
        margin: 0 0 20px;
        line-height: 26px;
      }

      .info_field_label{
           font-size: 14px;
           padding: 0 0 3px;
         }
     
         .security_field{
         padding: 0 0 20px;
         }


      .firebaseui-new-password-component ul, .passwordRules ul{
        li{
          font-size: 14px;
          margin-bottom: 6px;
        }
      }
      .security_password_btn{
        padding: 18px 0 0;
        .newpost-button{
          .button{
            width: 175px;
          }
        }
      }
      
    } 

  } 

      .edit-notification-settings {
        padding: 10px;

        h2 {
          font-size: 14px;
          line-height: 32px;
        }
        .app_feed_btn{
          .newpost-button{
            .button{
              text-align: center;
              width: 95px;
              font-size: 13px;
              height: 32px;
              line-height: 32px;
              padding: 0;
            }
          }
        }
        
        .notifications_list{
          padding: 20px 0 0;

        .notification-setting {
          padding: 0 0 18px;
          h5{
            font-size: 14px;
            line-height: 20px;
            margin: 0;
          }
          .notification_list_desc{
            font-size: 13px;
            line-height: 17px;
          }
          .checkbox {
            svg{
              width: 16px;
              height: 16px;
            }
          }
        }
      }

    }

}
// -----------------ME Profile End------------------------


// -----------------New Users Start------------------
.Sidebar_new-user{

  .role_back_link{
    font-size: 12px;
    padding: 0 0 12px;
    width: 60px;
    cursor: pointer;
    svg{
      width: 14px;
      margin-right: 3px;
      vertical-align: -3px;
      height: 14px;
    }
  }

  .invites {

    .invite_field_list{
      padding-bottom: 18px;
      input{
        font-size: 14px;
        
      }
      .invite_field_r svg{
        height: 100%;
        line-height: 100%;
        path{
          fill: #000;
        }
      }
    }
  
}
.invite_footer{
  .add_another{
   font-size: 15px;
   svg{
     width: 15px;
     height: 15px;
     margin:0 0 0 3px;
   }
  }
  .invite_footer_r{
    .button{
      width: 105px;
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      padding: 0;
    }
  }
}

}
// -----------------New Users End------------------

// -----------------Teams Page Start------------------
.Sidebar_teams{
  .teams-container {
    .team {
      height: auto;
      margin: 0 0 15px;
      width: 100%;
      padding: 12px 12px 25px;
      min-height: inherit;

      .team_list_title{
        padding: 0 0 10px;
        margin: 0 0 10px;
        h1{
          font-size: 15px;
          line-height: 20px;
        }
      }
      .team_list_info{
        .kissing_images_list{
            .kissing_image{
            width: 43px;
            svg, img{
              height: 43px;
            }
          }
       }
      }
    }

  }
}
// -----------------Teams Page End------------------


// -------------------New Team Page Start---------------------
.Sidebar_new-team{

  .role_back_link{
    font-size: 12px;
    padding: 0 0 12px;
    width: 60px;
    cursor: pointer;
    svg{
      width: 14px;
      margin-right: 3px;
      vertical-align: -3px;
      height: 14px;
    }
  }

 .new_teams_page{
   .new_team_field{
     padding: 0 0 15px;

     .new_team_field_label{
      font-size: 14px;
      padding: 0 0 2px;
     }
     .new_team_field_input{
       input{
        font-size: 14px;
       }
       .select{
         font-size: 14px;
       }
       .user{
         padding: 0 0 15px;
         .close{
           svg{
             width: 15px;
	     cursor: pointer;
           }
         }
       }
     }
     .col-md-4.col-5, .col-md-2.col-1{
       padding-left: 0;
     }
   }
 }

 .invite_footer{
  .add_another{
   font-size: 15px;
   svg{
     width: 15px;
     height: 15px;
     margin:0 0 0 3px;
   }
  }
  .invite_footer_r{
    .button{
      width: 105px;
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      padding: 0;
    }
  }
}

}
// -------------------New Team Page End---------------------


// -----------------Notifications Page Start-----------------
.notifications_page{

  .notification_tabs_list{
    .nav.nav-tabs{
      li{
        padding: 0;
        margin: 0 20px 0 0;
          a{
            font-size: 15px;
          }
      }
      .tab-number{
        margin-left: 0;
        font-size: 10px;
        height: 14px;
        line-height: 14px;
        width: 14px;
        vertical-align: middle;
      }
    }
 
  }

  .notification_history_list{
    padding: 20px 0 0;
    .notification_list_item{
      padding: 12px;
      margin-bottom: 15px;
      .notification_item_title{
        font-size: 13px;
        line-height: 20px;
      }
      .notification_item_desc{
        font-size: 13px;
        line-height: 20px;
        padding: 2px 0 0;
      }
    }
  }

}
// -----------------Notifications Page End-----------------


// ---------------Team Details Start----------------
.team_details_page{

  .new_role_back{
    .role_back_link{
      font-size: 12px;
      padding: 0 0 12px;
      width: 60px;
      cursor: pointer;
      svg{
        width: 14px;
        margin-right: 3px;
        vertical-align: -3px;
        height: 14px;
      }
    }
  }
  .info.tab-content{
    font-size: 14px;
    line-height: 22px;
  }

   .team_nav_tabs{
   padding: 0 0 10px;
      .nav.nav-tabs{
        flex-wrap: inherit;
        li{
          margin: 0 20px 10px 0;
          display: inline;
            a{
              font-size: 15px;
              line-height: 22px;
            }
        }
      }
  }
  
  } 
// ---------------Team Details End----------------


// -----------------Networks Page Styles Start-----------------
.profile_desc{
  font-size: 14px;
margin: 0 0 20px;
}
.network_list_sec{

   .network_list{
    padding: 10px;
    margin: 0 0 15px;

     .network_list_item{

      .network_icon_col{
        padding-right: 0;
        .network-icon{
          svg{
            width: 32px;
           height: 32px;
          }
        }
      }

        .network_title_col{
          padding-left: 8px;
          padding-right: 0;
          .title_col{
            h3{
              font-size: 16px;
              line-height: 20px;
            }
            p{
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
        .network_btn_col{
          padding-top: 3px;
          .newpost-button{
            .button{
              width: 80px;
              font-size: 14px;
              height: 32px;
              line-height: 30px;
              padding: 0;
            }
          }
          .network_toggle_icon{
            margin: 0 0 0 4px;
            svg{
              width: 18px;
              height: 18px;
            }
          }
        }
     }
   } 
}
// -----------------Networks Page Styles End-----------------


// -----------------Profile Page Start-----------------
.profile_page{
  .profile_card{
    .network_icon_col{
    svg{
      width: 32px;
      height: 32px;
    }
    }
    .network_title_col{
      h1{
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
  .profile_status{
    padding: 20px 0;

    .profile-status{
      font-size: 14px;
    }
  }
  .profile_rename{
    padding: 0 0 20px;
    .profile_rename_col_l{
      .rename_profile{
        font-size: 14px;
        padding: 0 0 3px;
      }
      input{
        font-size: 14px;
      }
    }
    .profile_rename_col_r{
      padding-left: 0;
      .newpost-button{
        padding-top: 25px;
        .button{
          width: 92px;
          font-size: 14px;
        }
      }
    }
  }
  
}

.profiles-list{
  .profile{
    .network-icon{
      display: inline-block;
      margin-right: 5px;
      svg{
        width: 32px;
        height: 32px;
      }
    }
  }
}
.post_detail_page{
  .new_role_back{
    .role_back_link{
      font-size: 12px;
      padding: 0 0 12px;
      width: 60px;
      cursor: pointer;
      svg{
        width: 14px;
        margin-right: 3px;
        vertical-align: -3px;
        height: 14px;
      }
    }
  }
}
// -----------------Profile Page End-----------------

.reg_wrapper.verify_email_info{
  font-size: 18px;
  line-height: 28px;
}

// ------------Onboarding Page Styles Start------------
.app_feed_section.onboarding{
  .feed_main{
    .onboarding_step{
      padding: 80px 20px;
      .onboarding_sec_form{
        .onboarding_sec_field{
          padding: 0 0 15px;
          input{
        font-size: 14px;
          }
        }
        .add_user{
          font-size: 14px;
          padding: 0 0 20px;
        }
      }
      .new_teams_page{
        .new_team_field{
          padding: 0 0 20px;
            .new_team_field_input{
              input{
                font-size: 14px;
              }
              .user{
                padding: 0 0 15px;
                .col-lg-5.col-md-4{
                  padding-left: 0;
                }
                .select{
                  font-size: 12px;
                }
                .close{
                  padding-left: 0;
                }
              }
            }
            .invite_footer{
              margin: auto;
              .invite_footer_l, .invite_footer_r{
                width: 100%;
              }
              .add_another{
                font-size: 14px;
                padding: 0 0 20px;
              }
              .newpost-button{
                .button{
              width: 95px;
              height: 40px;
              line-height: 40px;
                }
              }
            }
      }
      }
    }

    .onboarding_step.onboarding_networks{
      max-width: 500px;
       .network_list{
        padding: 12px;
        margin: 0 0 15px;
    
         .network_list_item{
    
          .network_icon_col{
            padding-right: 0;
            padding-top: 3px;
            svg{
              width: 30px;
              height: 30px;
            }
          }
    
            .network_title_col{
              padding-right: 0;
              .title_col{
                h3{
                  font-size: 16px;
                  line-height: 20px;
                }
                p{
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }
            .network_btn_col{
              padding-top: 3px;
              padding-left: 0;
              .newpost-button{
                display: inline-block;
                .button{
                  width: 81px;
                  height: 30px;
                  line-height: 28px;
                  font-size: 14px;
                }
              }
            }
         }
       }
       .onboarding_sec_form{
         .onabord_form_btn{
          padding-top: 8px;
           .newpost-button{
             .button{
               font-size: 16px;
               height: 34px;
               line-height: 32px;
             }
           }
         }
       }
    }

  }
}
// ------------Onboarding Page Styles End------------


.profiles_content_list{
  margin-bottom: 20px;
  .table_responsive{
    table{
      tr{
        .status {
          padding: 8px 0;
          width: 90px;
        }
      }
    }
  }
  

}

.team_details_page.feed_page_section{
  .team_edit_user{
      .table_responsive{
         padding: 5px 0 0;
      }
    }
  }


}

@media only screen and (min-width: 375px) and (max-width: 430px) {

  .Sidebar_me{
    .content{
      .nav.nav-tabs li{
        margin: 0 5px 10px 0;
      }
    }
  }

}


@media only screen and (min-width: 320px) and (max-width: 375px) {
  .notification-banner{
    .notification_banner_l{
      span{
        line-height: 20px;
      }
    }
  }
}




}